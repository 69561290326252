import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Typography
} from "@mui/material";
import { useStyles } from "./styles";
import "react-simple-keyboard/build/css/index.css";

interface TipDialogProps {
	open: boolean;
	handleClose: () => void;
	handleSubmit: (value: number) => void;
}

const TipDialog: React.FC<TipDialogProps> = ({
	handleClose,
	open,
	handleSubmit
}) => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
	const colors = menuState?.colors;
	const [tipValue, setTipValue] = React.useState<number>(
		shoopingCart.tip || 20
	);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const classes = useStyles({ colors });

	const handleSubmitComment = (value: number) => {
		handleSubmit(value);
	};

	const handleChangeTip = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string
	) => {
		setTipValue(Number(value));
	};

	return (
        <div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				fullWidth
				style={{ marginBottom: showKeyboard ? "30vh" : 0 }}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					<Grid container justifyContent="center">
						<Grid className={classes.titleTextStyle}>
							{t("order.select_tip")}
						</Grid>
						<IconButton
							size="small"
							onClick={handleClose}
							className={classes.cancelButton}
						>
							<CancelIcon className={classes.cancelColor} />
						</IconButton>
					</Grid>
				</DialogTitle>
				<DialogContent className={classes.dialogContentStyle}>
					<FormControl fullWidth>
						<RadioGroup
							defaultValue={"20"}
							name="radio-buttons-group"
							className={classes.radioGroupeStyle}
							value={tipValue}
							onChange={handleChangeTip}
						>
							<FormControlLabel
								value={0}
								control={<Radio />}
								label="0%"
							/>
							<FormControlLabel
								value={5}
								control={<Radio />}
								label="5%"
							/>
							<FormControlLabel
								value={10}
								control={<Radio />}
								label="10%"
							/>
							<FormControlLabel
								value={15}
								control={<Radio />}
								label="15%"
							/>
							<FormControlLabel
								value={20}
								control={<Radio />}
								label="20%"
							/>
						</RadioGroup>
					</FormControl>
				</DialogContent>
				<DialogActions className={classes.dialogActionStyle}>
					<Button
						fullWidth
						className={classes.btnStyle}
						onClick={() => handleSubmitComment(tipValue)}
						color="primary"
					>
						<Typography style={{ fontSize: 18 }}>
							{t("common.submit").toUpperCase()}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    );
};
export default React.memo(TipDialog);
