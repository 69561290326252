export const payment = {
	total: "Total Due",
	insert_card:
		"Insert your credit card into the swipe or if your card supports it,\n just tap your card on the swipe",
	connecting: "Connecting to reader...",
	remove_card: "Please remove your card",
	error: "There was an error while processing the payment, please try again later",
	card_rejected: "Card rejected",
	canceled: "Square payment operation is canceled",
	pay_with_strip: " Pay with your card",
	pay_with_popID: " PAY WITH POPPAY",
	choose_mode_payment_text: "Choose your mode of payment",
	try_again: "Try again",
	cancel: "Cancel",
	ready: "I'm ready",
	scan_face: "Let's scan your face",
	remove_mask_sunglasses_hat:
		"Please remove mask, sunglasses or hat if you are wearing them",
	one_moment: "One moment...",
	processing_face_scan: " We are processing your face scan",
	fail_pay:
		"We were enable to process your PoP ID transaction at the moment, please pay with your card or cancel your order.",
	confirm_enter_number: "Confirm",
	enter_last_four_phoneNumber_msg:
		" Please enter the last 4 digits of your phone number "
};