import { ThunkDispatch } from "redux-thunk";
import { kioskChoiceActionTypes } from "./models";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import {
  GET_MODES_ERROR,
  GET_MODES_SUCCESS,
  CHANGE_KIOSK_MODE_STATUS_ATTEMPT,
  CHANGE_KIOSK_MODE_STATUS_SUCCESS,
  CHANGE_KIOSK_MODE_STATUS_ERROR,
  GET_MODES_ATTEMPT,
} from "./types";
import {
  KIOSK_MODE_AVAILABILITY,
  KIOSK_MODE_STATUS,
  LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
  LOCAL_STORAGE_SELECTED_MODE,
  PAIR,
  SQUARE_UP_STATUS,
} from "../../utils/constants";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { getErrorMessage } from "../../utils/helpers";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { RootState } from "../rootReducer";

export const changeStatus = (code: string, modeId: number) => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      kioskChoiceActionTypes | SnackBarActionTypes
    >
  ) => {
    dispatch({
      type: CHANGE_KIOSK_MODE_STATUS_ATTEMPT,
    });
    let data = new FormData();
    data.append("code", code);
    data.append("mode_id", modeId.toString());
    methodsServices
      .post(ENDPOINTS.KIOSK_CHOICE, data, true)
      .then((res: any) => {
        // console.log("nouuuuur", res);
        localStorage.setItem(
          LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
          res?.data?.message?.id
        );
        localStorage.setItem(LOCAL_STORAGE_SELECTED_MODE, modeId.toString());
        history.replace(PATHS.Home);
        dispatch({
          type: CHANGE_KIOSK_MODE_STATUS_SUCCESS,
        });
      })
      .catch((error: any) => {
        // console.log("nouuuur", error);
        dispatch({
          type: CHANGE_KIOSK_MODE_STATUS_ERROR,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: "error",
          },
        });
      });
  };
};
