import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography, AppBar } from "@mui/material";
import logo from "../../assets/img/tyme.png";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useEffect, useState } from "react";
import {
	LOCAL_STORAGE_MODE,
	LOCAL_STORAGE_TOKEN,
	NUMBER_OF_CLICKS_TO_FORCE_LOGOUT,
	NUMBER_OF_CLICKS_TO_RELOAD_PAGE
} from "../../utils/constants";
import LogoutDialog from "../LogoutDialog";
import { useDialog } from "../../hooks/useDialog";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";

interface FooterProps {}

export const Footer = (props: FooterProps) => {
  const { t } = useTranslation();
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors });
  const [trigger, setTrigger] = useState(0);
  const [numberOfClick, setNumberOfClick] = useState(1);
  const dialogLogout = useDialog();

  const handleClick = () => {
    if (history.location.pathname === PATHS.Home) {
      if (numberOfClick >= NUMBER_OF_CLICKS_TO_FORCE_LOGOUT) {
        setNumberOfClick(1);
        dialogLogout.setOpen(true);
      } else {
        setNumberOfClick(numberOfClick + 1);
      }
    } else if (history.location.pathname === PATHS.KIOSK_CHOICE_PATH) {
      if (numberOfClick >= NUMBER_OF_CLICKS_TO_FORCE_LOGOUT) {
        setNumberOfClick(1);
        localStorage.clear();
        history.push(PATHS.LOGIN);
      } else {
        setNumberOfClick(numberOfClick + 1);
      }
    } else if (history.location.pathname === PATHS.CARD_READER) {
      if (numberOfClick >= NUMBER_OF_CLICKS_TO_FORCE_LOGOUT) {
        setNumberOfClick(1);
        dialogLogout.setOpen(true);
      } else {
        setNumberOfClick(numberOfClick + 1);
      }
    }
  };

  const handleClickToReloadPage = () => {
		if (history.location.pathname === PATHS.Home) {
			if (numberOfClick >= NUMBER_OF_CLICKS_TO_RELOAD_PAGE) {
				setNumberOfClick(1);
				window.location.reload();
			} else {
				setNumberOfClick(numberOfClick + 1);
			}
		}
  };

  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;

    if (bottom) {
      setTrigger(0);
    } else {
      setTrigger(4);
    }
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
		<AppBar
			position="sticky"
			color="default"
			className={classes.container}
			elevation={trigger}
		>
			<LogoutDialog
				open={dialogLogout.open}
				handleClose={() => {
					dialogLogout.setOpen(false);
				}}
				onBackdropClick={() => {
					dialogLogout.setOpen(true);
				}}
			/>

			<Grid container justifyContent="center" alignItems="center">
				<Grid item>
					<Grid container justifyContent="center" alignItems="center">
						<Grid item>
							<Typography
								className={classes.typography}
								onClick={handleClickToReloadPage}
							>
								{t("common.powered")}
							</Typography>
						</Grid>
						<Grid item>
							<img
								onClick={handleClick}
								src={logo}
								alt="logo"
								className={classes.logo}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</AppBar>
  );
};
export default Footer;
