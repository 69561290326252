import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { Button, Input, Spinner } from "../../components";
import { ChangeEvent } from "react";
import { isEmail } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/authentification/actions";
import { MIN_PASSWORD_LENGTH } from "../../utils/constants";
import BackgroundLayout from "../../layouts/BackgroundLayout";
import "../../assets/sass/common.scss";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { RootState } from "../../store/rootReducer";

interface LoginProps {}

interface InputProps<T> {
  value: T;
  error: string | null;
}

export const Login = (props: LoginProps) => {
  const loginRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [layout, setLayout] = useState("default");
  const keyboard = useRef<typeof Keyboard | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const wishInput = useRef<number>(-1);
  const [email, setEmail] = React.useState<InputProps<string>>({
    value: "",
    error: null,
  });
  const [password, setPassword] = React.useState<InputProps<string>>({
    value: "",
    error: null,
  });
  const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail({
      ...email,
      value: e.target.value,
      error: null,
    });
  };
  const handleOnPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword({
      ...password,
      value: e.target.value,
      error: null,
    });
  };
  const onChange = (input: any) => {
    switch (wishInput.current) {
      case 0:
        setEmail({
          ...email,
          value: input,
          error: null,
        });
        break;
      case 1:
        setPassword({
          ...password,
          value: input,
          error: null,
        });
        break;
      default:
        break;
    }
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  const onKeyPress = (button: any) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const onClick = () => {
    if (email.value === "")
      setEmail({ ...email, error: t("validations.required") });
    if (email.value !== "" && !isEmail(email.value))
      setEmail({ ...email, error: t("validations.email") });
    if (password.value === "")
      setPassword({ ...password, error: t("validations.required") });
    if (password.value !== "" && password.value.length < MIN_PASSWORD_LENGTH)
      setPassword({
        ...password,
        error: t("validations.min_length"),
      });
    if (
      isEmail(email.value) &&
      email.value !== "" &&
      password.value !== "" &&
      password.value.length >= MIN_PASSWORD_LENGTH
    ) {
      dispatch(
        login({
          email: email.value,
          password: password.value,
        })
      );
    }
  };

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick();
  };

  const loginState = useSelector((state: RootState) => state.auth.login);
  const { loader, error } = loginState;

  const goToForgotPassword = () => {
    history.push(PATHS.RESET_PASSWORD);
  };
  const onFocusEmail = (e: any) => {
    e.target.blur();
    setEmail({
      ...email,
    });
    if (keyboard.current !== null) {
      //@ts-ignore
      keyboard.current.setInput(email.value);
    }
    wishInput.current = 0;
    setShowKeyboard(true);
  };
  const onFocusPassword = (e: any) => {
    e.target.blur();
    setPassword({
      ...password,
    });
    if (keyboard.current !== null) {
      //@ts-ignore
      keyboard.current.setInput(password.value);
    }
    wishInput.current = 1;
    setShowKeyboard(true);
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      //@ts-ignore
      if (
        ref.current &&
        event.target.tagName.toUpperCase() !== "INPUT" &&
        !ref.current.contains(event.target as Node)
      ) {
        setShowKeyboard(false);
        wishInput.current = -1;
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div>
      <div style={{ marginBottom: showKeyboard ? 230 : 0 }}>
        <BackgroundLayout>
          <form
            noValidate
            onSubmit={(e: React.SyntheticEvent) => {
              submitForm(e);
            }}
          >
            <div className="form-group ">
              <Input
                isFocus={wishInput.current === 0}
                onFocus={(e: any) => onFocusEmail(e)}
                ref={loginRef}
                type="email"
                placeholder={t("login.email")}
                value={email.value}
                onChange={handleOnEmailChange}
              />
              <span className="error">{email.error}</span>
            </div>
            <div className="form-group ">
              <Input
                isFocus={wishInput.current === 1}
                ref={passwordRef}
                onFocus={(e: any) => onFocusPassword(e)}
                type="password"
                placeholder={t("login.password")}
                value={password.value}
                onChange={handleOnPasswordChange}
              />
              <span className="error">{password.error}</span>
            </div>
            <span className="error">{t(error)}</span>
            <div className="form-group ">
              <Button title={t("login.sign_in")} onClick={onClick} />
            </div>

            <a className="link" onClick={goToForgotPassword}>
              {t("login.forgot_password")}
            </a>
          </form>
          <Spinner loading={loader} />
        </BackgroundLayout>
      </div>
      {showKeyboard && (
        <div ref={ref} className={"keyboardContainer"}>
          <Keyboard
            keyboardRef={(r: any) => (keyboard.current = r)}
            layoutName={layout}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </div>
      )}
    </div>
  );
};
export default Login;
