import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";

interface DeleteDialogrops {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

const DeleteDialog: React.FC<DeleteDialogrops> = ({
  handleClose,
  open,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors });
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitleStyle}
          id='form-dialog-title'
        >
          {t("common.delete_title")}
        </DialogTitle>
        <div
         className={classes.containerStyle}
        >
          <div>
            <Button
              onClick={handleSubmit}
              fullWidth
              className={classes.firstBtnStyle}
              size='large'
            >
              <Typography className={classes.fontSizeStyle}>
                {t("common.yes")}
              </Typography>
            </Button>
          </div>
          <div>
            <Button
              onClick={handleClose}
              size='large'
              fullWidth
              className={classes.secondBtnStyle}
            >
              <Typography className={classes.fontSizeStyle}>
                {t("common.cancel")}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default React.memo(DeleteDialog);
