export const GENERATE_TOKEN = "token/generate";
export const INIT_TERMINAL = "terminal/init";
export const CONNECT_READER = "reader/connect";
export const GENERATE_TOKEN_ERROR = "token/error";
export const START_LOADING = "loading/start";
export const DISCONNECT_READER = "reader/disconnect";

//reducer interface
export interface CardReaderState {
    token: string,
    terminal: any,
    reader: any,
    error: string,
    loading: boolean
}

// fetch menu action
export interface GenerateTokenAction {
    type: typeof GENERATE_TOKEN | typeof INIT_TERMINAL | typeof CONNECT_READER;
    payload: {
        token: string,
        terminal: any,
        reader: any,
        error: string,
        loading: boolean
    };
}

interface GenerateTokenErrorAction {
    type: typeof GENERATE_TOKEN_ERROR;
    payload: {
        error: string
    };
}
interface GenerateTokenLodingAction {
    type: typeof START_LOADING;
}

interface DisconnectReaderAction {
    type: typeof DISCONNECT_READER;
}

export type CardReaderTypes = GenerateTokenAction;
export type CardReaderErrorTypes = GenerateTokenErrorAction;
export type CardReaderLoqdingTypes = GenerateTokenLodingAction;
export type DisconnectReaderTypes = DisconnectReaderAction;
