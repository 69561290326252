import * as React from "react";
import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import background from "../../assets/img/bg_order_success.png";
import {
  LOCAL_STORAGE_ORDER_ID,
  LOCAL_STORAGE_SQUARE_WAITING_TIME,
  LOCAL_STORAGE_WAITING_TIME,
  NUMBER_OF_SECONDS_TO_REDIRECT_TO_HOME,
  SQUARE_UP_STATUS,
} from "../../utils/constants";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";

interface OrderSubmittedProps {}

export const OrderSubmitted = (props: OrderSubmittedProps) => {
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors: colors });
  const { t } = useTranslation();
  const squareStatus = parseInt(
    localStorage.getItem(SQUARE_UP_STATUS) as string
  );
  const waitingTime = localStorage.getItem(LOCAL_STORAGE_WAITING_TIME) || "";
  const waitingSquareTime =
    localStorage.getItem(LOCAL_STORAGE_SQUARE_WAITING_TIME) || "";
  const orderId = localStorage.getItem(LOCAL_STORAGE_ORDER_ID) || "";
  React.useEffect(() => {
    console.log("waitingSquareTime", waitingSquareTime);
    const timerId = setTimeout(() => {
      localStorage.removeItem(LOCAL_STORAGE_WAITING_TIME);
      localStorage.removeItem(LOCAL_STORAGE_SQUARE_WAITING_TIME);
      localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
      if (!squareStatus) {
        history.push(PATHS.CARD_READER);
      } else {
        history.push(PATHS.Home);
      }
    }, NUMBER_OF_SECONDS_TO_REDIRECT_TO_HOME);

    const handleGoBack = (event: any) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleGoBack);
    return () => {
      window.removeEventListener("popstate", handleGoBack);
      clearTimeout(timerId);
    };
  }, []);

  return (
    <div
      className={classes.background}
      style={{ backgroundImage: `url(${background})` }}
    >
      <Grid
        container
        justifyContent="center"
        direction={"column"}
        alignItems="center"
        className={classes.container}
      >
        <Grid>
          <h1 className={classes.title}>
            {t("orderSubmitted.thank_you_for_ordering")}
          </h1>
          <h6 className={classes.text}>{t("orderSubmitted.waiting_time")}</h6>
        </Grid>
        <Grid className={classes.middleContainer}>
          <h1 className={classes.waitingTime}>
            {!squareStatus ? waitingTime : waitingSquareTime}
          </h1>
        </Grid>
        <Grid>
          <h3 className={classes.minutes}>{t("orderSubmitted.minutes")}</h3>
          <h4 className={classes.orderNumber}>
            {t("orderSubmitted.order_number").replace("%order_number", orderId)}
          </h4>
        </Grid>
      </Grid>
    </div>
  );
};
export default OrderSubmitted;
