import React, { ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { PATHS } from "./paths";
import { isLoggedIn, isConfigured } from "../utils/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../store/rootReducer";

interface IProps extends RouteProps {
	component: ComponentType<any>;
	status?: number;
	path: string;
}

function PrivateRoute(props: IProps): React.ReactElement {
	const { component: Component, ...rest } = props;
	const cardReaderState = useSelector((state: RootState) => state.cardreader);

	const render = (props: any) => {
		if (!isLoggedIn()) {
			return <Redirect to={PATHS.LOGIN} />;
		} else if (isConfigured() && cardReaderState.reader) {
			return <Redirect to={PATHS.Home} />;
		}
		return <Component {...props} />;
	};

	return <Route {...rest} render={render} />;
}

export default PrivateRoute;
