export const PATHS = {
    LOGIN: "/login",
    Home: "/",
    KIOSK_CHOICE_PATH: "/kiosk-choice",
    PRODUCT: "/product-details",
    CARD_READER: "/reader",
    PAYMENT: "/payment",
    CHOICE_MODE_PAYMENT: "/payment-mode",
	POP_ID_MODE_PAYMENT: "/payment-mode-popId",
    CONFIRM: "/confirm-or-cancel",
    ORDER_SUBMITTED: "/order-submitted",
    CHECKOUT: "/checkout",
    SLIDER: "/slider",
    RESET_PASSWORD: "/reset-password"
};
