import { ThunkDispatch } from "redux-thunk";
import { CHECKOUT_SUCCESS, CheckoutActions } from "./types";
import { getErrorMessage, removeMaskElements } from "../../utils/helpers";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { CHECKOUT_ERROR, CHECKOUT } from "./types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import { LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED } from "../../utils/constants";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { RootState } from "../rootReducer";

export const checkout = (
	fname: string,
	lname: string,
	phone: string,
	vipCustomer: boolean
) => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			CheckoutActions | SnackBarActionTypes
		>,
		getState: any
	) => {
		const modeId = localStorage.getItem(
			LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED
		);
		const orderId = getState().order.order;
		dispatch({
			type: CHECKOUT
		});
		const data = {
			first_name: fname,
			last_name: lname,
			phone_number: removeMaskElements(phone),
			order_id: orderId,
			mode_id: modeId,
			vip_customer: vipCustomer
		};
		methodsServices
			.post(ENDPOINTS.CHECKOUT, data, true)
			.then(res => {
				dispatch({
					type: CHECKOUT_SUCCESS
				});
				console.log(res);
				history.push(PATHS.CONFIRM);
			})
			.catch(error => {
				dispatch({
					type: CHECKOUT_ERROR
				});
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
			});
	};
};
