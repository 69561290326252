import React from "react";
import { Footer } from "../../components";
import { Grid } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";

interface CheckOutLayoutProps {
	children: React.ReactNode;
}

export const CheckOutLayout = (props: CheckOutLayoutProps) => {
	const { children } = props;
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });

	return (
		<Grid className={classes.container}>
			{children}
			<Grid container className={classes.footer}>
				<Footer />
			</Grid>
		</Grid>
	);
};
export default CheckOutLayout;
