export const ORDER_SQUARE_READER = "orderSquare/reader";
export const EMPTY_ORDER_SQUARE = "orderSquare/empty";
export const ORDER_SQUARE_READER_SUCCESS = "orderSquare/success";
export const ORDER_SQUARE_READER_FAIL = "orderSquare/fail";
export const SAVE_PAYMENT_INTENT = "payment/intent";

//reducer interfaces

export interface OrderSquareState {
  order: number | null;
  loading: boolean;
  paymentIntent: any;
}
export interface EmptyOrderSquareState {}

interface OrderSquareAction {
  type: typeof ORDER_SQUARE_READER_SUCCESS;
  payload: {
    order: number | null;
    loading: boolean;
    paymentIntent: any;
  };
}
interface OrderSquareResponseAction {
  type: typeof ORDER_SQUARE_READER_FAIL | typeof ORDER_SQUARE_READER;
}
interface PaymentIntentAction {
  type: typeof SAVE_PAYMENT_INTENT;
  payload: {
    paymentIntent: any;
  };
}
interface PaymentIntentAction {
  type: typeof SAVE_PAYMENT_INTENT;
  payload: {
    paymentIntent: any;
  };
}

interface EmptyOrderSquareAction {
  type: typeof EMPTY_ORDER_SQUARE;
}
export interface OrderSquareProduct {
  id: string;
  quantity: string;
  modifiers: Array<string>;
}

export type OrderSquareTypes = OrderSquareAction | OrderSquareResponseAction;
export type EmptyOrderSquareTypes = EmptyOrderSquareAction;
export type PaymentIntentTypes = PaymentIntentAction;
