export const ORDER_READER = "order/reader";
export const EMPTY_ORDER = "order/empty";
export const ORDER_READER_SUCCESS = "order/success";
export const ORDER_READER_FAIL = "order/fail";
export const SAVE_PAYMENT_INTENT = "payment/intent";
export const STRIPE_OR_POPID_ORDER = "order/status";
//reducer interfaces
export interface OrderState {
	clientSecret: string;
	order: number | null;
	loading: boolean;
	paymentIntent: any;
	popid: number;
}
export interface StripeOrPopIdOrderAction {
	type: typeof STRIPE_OR_POPID_ORDER;
	popid: number;
}

interface OrderAction {
	type: typeof ORDER_READER_SUCCESS;
	payload: {
		clientSecret: string;
		order: number | null;
		loading: boolean;
		paymentIntent: any;
	};
}
interface OrderResponseAction {
	type: typeof ORDER_READER_FAIL | typeof ORDER_READER;
}
interface PaymentIntentAction {
	type: typeof SAVE_PAYMENT_INTENT;
	payload: {
		paymentIntent: any;
	};
}
interface PaymentIntentAction {
	type: typeof SAVE_PAYMENT_INTENT;
	payload: {
		paymentIntent: any;
	};
}

interface EmptyOrderAction {
	type: typeof EMPTY_ORDER;
}
export interface OrderProduct {
	id: string;
	quantity: string;
	modifiers: Array<string>;
}

export type OrderTypes = OrderAction | OrderResponseAction;
export type EmptyOrderTypes = EmptyOrderAction;
export type PaymentIntentTypes = PaymentIntentAction;
export type PaymentTypes = StripeOrPopIdOrderAction;
