/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, Button, IconButton } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { CartIcon } from "../ui/svg/Cart";
import CreateIcon from "@mui/icons-material/Create";
import ShoopingCartItem from "../ShoopingCartItem";
import {
	LOCAL_STORAGE_SHOOPING_CARTS,
	MINIMUM_ORDER_TO_PAY,
	SQUARE_UP_STATUS,
	POPID_STATUS
} from "../../utils/constants";
import CommentDialog from "../CommentDialog";
import {
  AddCommentToShoopingCart,
  AddTipToShoopingCart,
  RemoveProductFromShoopingCart,
} from "../../store/shoopingCart/actions";
import DeleteDialog from "../DeleteDialog";
import { PATHS } from "../../routes/paths";
import { useDialog } from "../../hooks/useDialog";
import { getPriceFormat } from "../../utils/helpers";
import { passOrder, stripeOrPopId } from "../../store/order/actions";
import TipDialog from "../TipDialog";
import { history } from "../../routes/history";
import { SHOW_SNACK_BAR } from "../../store/snackBar/types";

export const Cart = () => {
   function precisionRound(num:number, precision:number){return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision);} ;
  const squareStatus = parseInt(
    localStorage.getItem(SQUARE_UP_STATUS) as string
  );
  const { t } = useTranslation();
  const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
  const menuState = useSelector((state: RootState) => state.menu);
  const orderState = useSelector((state: RootState) => state.order);
  const colors = menuState?.colors;
  const classes = useStyles({ colors });
  const dialogComment = useDialog();
  const dialogTip = useDialog();
  const dialogDelete = useDialog();
  const [indexToRemove, setIndexToRemove] = React.useState<number>(-1);
  const dispatch = useDispatch();
  const popIdStatus = menuState.merchant?.popid_status;
  //Change the data saved in the localStorage
  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_SHOOPING_CARTS,
      JSON.stringify(shoopingCart)
    );
    console.log("price to pay", shoopingCart.priceToPay);
  }, [shoopingCart]);

  //Get the total Value of all product
  const totalValue = () => {
    return shoopingCart.shoopingProduct.reduce(
      (somme, current) => somme + current.price,
      0
    );
  };

  //When the user want to save the comment
  const handleSubmitFormDialog = React.useCallback((text: string) => {
    dispatch(AddCommentToShoopingCart(text));
    dialogComment.closeDialog();
  }, []);
  //When the user want to save the tip
  const handleSubmitTipDialog = React.useCallback((value: number) => {
    dispatch(AddTipToShoopingCart(value));
    dialogTip.closeDialog();
  }, []);
  //function for open the dialog when the user want to delete a product
  const handleOpenDeleteDialog = React.useCallback(
    (index: number, _open: boolean) => {
      dialogDelete.setOpen(_open);
      setIndexToRemove(index);
    },
    []
  );
  //function called when the user want to remove product
  const handleRemoveProduct = React.useCallback(() => {
    dispatch(RemoveProductFromShoopingCart(indexToRemove));
    dialogDelete.closeDialog();
  }, [indexToRemove]);

  //Component that will be returned for each section
  const SectionComponent = (props: any): React.ReactElement => {
    return typeof props.title === "string" ? (
      <Grid item xs={props.xs}>
        <Typography className={props.classes}>{props.title}</Typography>
      </Grid>
    ) : (
      <Grid item xs={props.xs} className={props.classes}>
        {props.title}
      </Grid>
    );
  };

  const passPayment = async () => {
    if (squareStatus) {
      if (shoopingCart.priceToPay < MINIMUM_ORDER_TO_PAY) {
			dispatch({
				type: SHOW_SNACK_BAR,
				payload: {
					message:t('errors.invalid_order'),
					severity: "error"
				}
			});
		} else {
			history.push(PATHS.CHECKOUT, shoopingCart);
		}
    } else {
			if (popIdStatus === POPID_STATUS.PAY_WITH_POPID) {
				history.push(PATHS.CHOICE_MODE_PAYMENT);
			} else {
				dispatch(stripeOrPopId(popIdStatus));
				dispatch(passOrder(shoopingCart, popIdStatus));
				history.push(PATHS.PAYMENT);
			}
    }
  };


	//List of all sections
	const SECTION_COMPONENT_LIST = [
		{
			xs: 6,
			title: t("order.additional_instruction"),
			classes: classes.addtionalStyle
		},
		{
			xs: 6,
			title: (
				<IconButton onClick={dialogComment.openDialog} size={"small"}>
					<CreateIcon className={classes.createIconStyle} />
				</IconButton>
			),
			classes: classes.gridStyle
		},
		{
			xs: 12,
			title: shoopingCart.comment,
			classes: classes.addtionalCommentStyle
		},
		{
			xs: 6,
			title: t("order.sub_total"),
			classes: classes.addtionalStyle
		},
		{
			xs: 6,
			title: `$${getPriceFormat(totalValue())}`,
			classes: classes.addtionalPriceStyle
		},
		{
			xs: 6,
			title: t("order.tax"),
			classes: classes.addtionalStyle
		},
		{
			xs: 6,
			title: `$${shoopingCart.totalTax.toFixed(2) ||0}`,
			classes: classes.addtionalPriceStyle
		},
		{
			xs: 6,
			title: (
				!squareStatus &&(
				<Grid container alignItems="center">
					<Grid className={classes.tipTextStyle}>
						{`${t("order.tip")} (${shoopingCart.tip}%)`}
					</Grid>
					<IconButton
						className={classes.editButtonStyle}
						onClick={dialogTip.openDialog}
						size={"small"}
					>
						<CreateIcon className={classes.createIconStyle} />
					</IconButton>
				</Grid>
				)
			),
			classes: classes.tipStyle
		},
		{
			xs: 6,
			title:!squareStatus &&( `$${getPriceFormat(shoopingCart.tipToPay || 0)}`),
			classes: classes.addtionalPriceStyle
		}
	];
	return (
        <Fragment>
			<DeleteDialog
				handleSubmit={handleRemoveProduct}
				handleClose={dialogDelete.closeDialog}
				open={dialogDelete.open}
			/>
			<CommentDialog
				handleSubmit={handleSubmitFormDialog}
				handleClose={dialogComment.closeDialog}
				open={dialogComment.open}
			/>
			<TipDialog
				handleSubmit={handleSubmitTipDialog}
				handleClose={dialogTip.closeDialog}
				open={dialogTip.open}
			/>
			<Grid>
				<Grid
					container
					justifyContent="space-between"
					direction="column"
					alignItems="center"
					className={classes.cartContainer}
				>
					<Grid>
						<Typography gutterBottom className={classes.title}>
							{t("order.title")}
						</Typography>
						{shoopingCart.shoopingProduct.length === 0 ? (
							<Grid
								container
								justifyContent="center"
								direction="column"
								alignItems="center"
								alignContent="center"
								className={classes.gridContainerStyle}
							>
								<Fragment>
									<div className={classes.cartImage}>
										<CartIcon />
									</div>
									<Typography
										gutterBottom
										className={classes.description}
									>
										{t("order.description")}
									</Typography>
								</Fragment>
							</Grid>
						) : (
							<Fragment>
								<div className={classes.itemContainer}>
									{shoopingCart.shoopingProduct.map(
										(product, index) => {
											return (
												<ShoopingCartItem
													key={
														product.product.id +
														index.toString()
													}
													product={product}
													index={index}
													setOpenDeleteDialog={
														handleOpenDeleteDialog
													}
												/>
											);
										}
									)}
								</div>
								<Grid container>
									{SECTION_COMPONENT_LIST.map(
										(section, index) => {
											return (
												<SectionComponent
													key={
														section.title +
														index.toString()
													}
													xs={section.xs}
													title={section.title}
													classes={section.classes}
												/>
											);
										}
									)}
								</Grid>
							</Fragment>
						)}
					</Grid>

					<Button
						className={classes.button}
						disabled={
							shoopingCart.shoopingProduct.length === 0 ||
							orderState.loading
						}
						onClick={() => passPayment()}
					>
						{t("order.button")} <br />
						{squareStatus && shoopingCart.priceToPay > 0
							? `($${
								(totalValue()+shoopingCart.totalTax).toFixed(2) ||0
							  })`
							: !squareStatus && shoopingCart.priceToPay > 0 ?`($${
								(totalValue()+shoopingCart.totalTax+ shoopingCart.tipToPay).toFixed(2) ||0
							  })`:null}
					</Button>
				</Grid>
			</Grid>
		</Fragment>
    );

};
export default Cart;
