import makeStyles from '@mui/styles/makeStyles';
import {ColorProps} from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
    background: (props: ColorProps) => ({
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: props.colors[1],
        paddingTop: 100,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }),
    title: (props: ColorProps) => ({
        color: props.colors[0],
        fontSize: 40,
        lineHeight: 1.5
    }),
    container: {
        width: "80%",

    },
    text: (props: ColorProps) => ({
        color: props.colors[0],
        fontSize: 23,
        fontWeight: 200,
        letterSpacing: 3,
    }),
    waitingTime: (props: ColorProps) => ({
        color: props.colors[0],
        fontSize: 100,
    }),
    orderNumber: (props: ColorProps) => ({
        color: props.colors[0],
        fontfamily: "Roboto",
        fontWeight: 400,
        letterSpacing: 7,
        textTransform: "uppercase",
        lineHeight: 2
    }),
    minutes: (props: ColorProps) => ({
        color: props.colors[0],
        fontfamily: "Roboto",
        fontWeight: 400,
        letterSpacing: 7,
        textTransform: "uppercase",
        lineHeight: 2
    }),
    middleContainer: {
        marginTop: '5%',
    },
});
