import produce from "immer";
import {
	DEFAULT_PRIMARY_COLOR,
	DEFAULT_SECONDARY_COLOR,
	SUGGESTION_TAB_VALUE
} from "../../utils/constants";
import {
	FETCH_MENU,
	RESET_MENU,
	MenuActionTypes,
	MerchantState,
	MerchantInterface,
	FETCH_MENU_ERROR,
	ResetMenuTypes,
	FETCH_SUGGESTION_MENU,
	FETCH_SUGGESTION_MENU_ERROR,
	SuggestionsTypes,
	FETCH_SUGGESTION_MENU_SUCCESS,
	REMOVE_SUGGESTION_MENU,
	FETCH_MENU_LOADING,
	GET_CATEGORY_VALUE,
	
} from "./types";

const INITIAL_STATE: MerchantState = {
	merchant: {} as MerchantInterface,
	promotions: [],
	colors: [DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR],
	loading: true,
	suggestions: [],
	loadingSuggestions: true,
	valueCategory: 0
};

export const menuReducer = (
	state = INITIAL_STATE,
	action: MenuActionTypes | ResetMenuTypes | SuggestionsTypes
): MerchantState =>
	produce(state, draft => {
		switch (action.type) {
			case FETCH_MENU_LOADING:
				return {
					...state,
					loading: true
				};
			case GET_CATEGORY_VALUE:
				draft.valueCategory = action.valueCategory;
				break;
			case FETCH_MENU:
				const filtredMenu = action.payload.merchant.menu.filter(
					value => {
						return (
							value.products?.length > 0 ||
							(value.sub_categories?.length > 0 &&
								value.sub_categories?.some(
									code => code.products?.length > 0
								))
						);
					}
				);
				const filtredMerchant = { ...action.payload.merchant };
				filtredMerchant.menu = [...filtredMenu];
				draft.merchant = { ...filtredMerchant };
				draft.promotions = action.payload.promotions;
				draft.colors = action.payload.colors;
				draft.loading = false;
				break;
			case FETCH_MENU_ERROR:
				draft.loading = false;
				break;
			case RESET_MENU:
				draft.merchant = {} as MerchantInterface;
				draft.promotions = [];
				draft.colors = [DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR];
				draft.loading = true;
				draft.suggestions = [];
				break;
			case FETCH_SUGGESTION_MENU:
				draft.loadingSuggestions = true;
				break;
			case FETCH_SUGGESTION_MENU_SUCCESS:
				let newMarchent = { ...state.merchant };
				if (
					state.merchant &&
					state.merchant.menu &&
					state.merchant.menu[0] &&
					state.merchant.menu[0].id === SUGGESTION_TAB_VALUE
				) {
					const [, ...rest] = newMarchent.menu;
					newMarchent.menu = [...rest];
				}
				if (
					action.payload.suggestions &&
					action.payload.suggestions?.length > 0
				) {
					newMarchent.menu = [
						{
							products: [...action.payload.suggestions],
							name: "suggestions",
							sub_categories: [],
							id: SUGGESTION_TAB_VALUE
						},
						...newMarchent.menu
					];
					draft.merchant = { ...newMarchent };
				}
				draft.loadingSuggestions = false;
				break;
			case FETCH_SUGGESTION_MENU_ERROR:
				draft.suggestions = [];
				draft.loadingSuggestions = false;
				break;
			case REMOVE_SUGGESTION_MENU:
				if (
					state.merchant &&
					state.merchant.menu &&
					state.merchant.menu[0] &&
					state.merchant.menu[0].id === SUGGESTION_TAB_VALUE
				) {
					let newMarchent = { ...state.merchant };
					const [, ...rest] = newMarchent.menu;
					newMarchent.menu = [...rest];
					draft.merchant = { ...newMarchent };
				}

				break;
		}
	});
