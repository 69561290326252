export const kioskChoice = {
	title: "Welcome to kiosk mode ",
	sub_title:
		"Are you setting up a general kiosk or one that is tied into a table ?",
	placeholder: "Enter your activation code",
	activate_kiosk: "Activate kiosk",
	general_kiosk: "General kiosk",
	table_kiosk: "Table kiosk",
	sight_corp: "You can edit your sightcorp URL from here ! ",
	pop_id: "You can edit your popId URL from here ! "
};