import makeStyles from '@mui/styles/makeStyles';


export const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
    },
    btn: {
        backgroundColor: 'red',
        borderRadius: 10,
        color: 'white',
        display: 'flex',
        justifyContent:'center',
        alignItems: "center"
    },
    span: {
        fontSize: 18,
        width: 50,
        fontWeight: 'bold',
    },
    quantityContainer:{
        textAlign:'center',
        display: "flex",
        alignItems: "center",
        width:40
    }
});
