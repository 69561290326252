import { ThunkDispatch } from "redux-thunk";
import {
  CONFIRM_OR_CANCEL_SQUARE,
  CONFIRM_OR_CANCEL_ERROR_SQUARE,
  CONFIRM_OR_CANCEL_SUCCESS_SQUARE,
  ConfirmSquareCancelActions,
} from "./types";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import { getErrorMessage } from "../../utils/helpers";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import {
  LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
  LOCAL_STORAGE_ORDER_ID,
  LOCAL_STORAGE_PRODUCT,
  LOCAL_STORAGE_SHOOPING_CARTS,
} from "../../utils/constants";
import { ShoopingCartActions } from "../shoopingCart/models";
import { REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART } from "../shoopingCart/types";
import { RootState } from "../rootReducer";
import {
  EmptyOrderSquareTypes,
  EMPTY_ORDER_SQUARE,
} from "../squareOrder/types";

export const cancelSquare = () => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      | ConfirmSquareCancelActions
      | SnackBarActionTypes
      | EmptyOrderSquareTypes
      | ShoopingCartActions
    >
  ) => {
    dispatch({
      type: CONFIRM_OR_CANCEL_SQUARE,
    });
    const orderId = localStorage.getItem("orderId") || "";

    const modeId =
      localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || "";
    const data = {
      mode_id: parseInt(modeId),
      order_id: parseInt(orderId),
    };
    methodsServices
      .post(ENDPOINTS.CANCELSQUARE, data, true)
      .then(async (res) => {
        dispatch({
          type: CONFIRM_OR_CANCEL_SUCCESS_SQUARE,
        });
        dispatch({
          type: EMPTY_ORDER_SQUARE,
        });
        dispatch({
          type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
        });
        localStorage.removeItem(LOCAL_STORAGE_SHOOPING_CARTS);
        localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
        localStorage.removeItem(LOCAL_STORAGE_PRODUCT);
        history.push(PATHS.Home);
      })
      .catch((error) => {
        dispatch({
          type: CONFIRM_OR_CANCEL_ERROR_SQUARE,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: "error",
          },
        });
      });
  };
};

export const confirmSquare = () => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      ConfirmSquareCancelActions | SnackBarActionTypes | ShoopingCartActions
    >
  ) => {
    dispatch({
      type: CONFIRM_OR_CANCEL_SQUARE,
    });
    const orderId = localStorage.getItem("orderId") || "";

    const modeId =
      localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || "";
    const data = {
      order_id: parseInt(orderId),
      mode_id: parseInt(modeId),
    };
    methodsServices
      .post(ENDPOINTS.SQUARE_CONFIRM, data, true)
      .then(async (res) => {
        var squareChekoutId = res?.data.data.squareCheckout_id;
        dispatch({
          type: CONFIRM_OR_CANCEL_SUCCESS_SQUARE,
        });
        localStorage.removeItem(LOCAL_STORAGE_SHOOPING_CARTS);
        history.push(PATHS.PAYMENT, squareChekoutId);
      })
      .catch((error) => {
        dispatch({
          type: CONFIRM_OR_CANCEL_ERROR_SQUARE,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: "error",
          },
        });
      });
  };
};
