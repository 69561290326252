import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography, CircularProgress } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { emptyOrder, savePaymentIntent } from "../../store/order/actions";
import { PATHS } from "../../routes/paths";
import { SHOW_SNACK_BAR } from "../../store/snackBar/types";
import { history } from "../../routes/history";
import {
  LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
  LOCAL_STORAGE_ORDER_ID,
  LOCAL_STORAGE_SQUARE_WAITING_TIME,
  LOCAL_STORAGE_WAITING_TIME,
  NO_READER_STATUS,
  SQUARE_UP_STATUS,
} from "../../utils/constants";
import { ENDPOINTS } from "../../utils/endpoints";
import { methodsServices } from "../../store/services/methodsService";
import { REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART } from "../../store/shoopingCart/types";
import { useLocation } from "react-router";
import { emptyOrderSquare } from "../../store/squareOrder/actions";

interface PaymentProps {}

export const PaymentPage = (props: PaymentProps) => {

  //function precisionRound(num:number, precision:number){return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision);} ;
  const location = useLocation();
  const id = location.state as string;
  const squareStatus = parseInt(
    localStorage.getItem(SQUARE_UP_STATUS) as string
  );
  const { t } = useTranslation();
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors });
  const [loading, setLoading] = useState(true);
  const [canCancel, setCanCancel] = useState(true);
  const cardReaderState = useSelector((state: RootState) => state.cardreader);
  const ShoppingCartState = useSelector(
    (state: RootState) => state.shoopingCart
  );
  const orderState = useSelector((state: RootState) => state.order);
  const dispatch = useDispatch();
  const totalValue = () => {
    return ShoppingCartState.shoopingProduct.reduce(
      (somme, current) => somme + current.price,
      0
    );
  };
  const collectCardPayment = async () => {
    console.log("collect payment in progress...", cardReaderState);
    // Read a card from the customer
    /** 	cardReaderState.terminal.setSimulatorConfiguration({
		testCardNumber: "4000002760003184",
		});*/
    cardReaderState.terminal
      .collectPaymentMethod(orderState.clientSecret)
      .then((result: any) => {
        if (result.error) {
          if (result.error.code !== "canceled") {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: "payment.error",
                severity: "error",
              },
            });
            cancelPendingPayment();
            history.push(PATHS.Home);
          }
          console.log(
            "Collect payment method failed:",
            result,
            result.error.message
          );
        } else {
          console.log("Payment result: ", result);
          setCanCancel(false);
          setLoading(false);

          cardReaderState.terminal
            .processPayment(result.paymentIntent)
            .then((confirmResult: any) => {
              if (confirmResult.error) {
                console.log(
                  `Confirm payment failed: ${confirmResult.error.message}`
                );
                cancelPendingPayment();
                dispatch({
                  type: SHOW_SNACK_BAR,
                  payload: {
                    message: "payment.card_rejected",
                    severity: "error",
                  },
                });
                history.push(PATHS.Home);
              } else if (confirmResult.paymentIntent) {
                if (confirmResult.paymentIntent.status !== "succeeded") {
                  console.log("Payment Successful!", confirmResult);
                } else {
                  console.log(
                    "Single-message payment successful!",
                    confirmResult
                  );
                }
                dispatch(savePaymentIntent(confirmResult.paymentIntent));
                history.push(PATHS.CHECKOUT);
              }
            });
        }
      })
      .catch((error: any) => {
        console.log("Error while paying=>", error.error);
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: "reader.connect_error",
            severity: "error",
          },
        });
        if (error.error?.code === NO_READER_STATUS) {
          disconnectReader();
        } else {
          cancelPaymentMethod();
        }
      });
  };
  const disconnectReader = () => {
    console.log(
      "callback => Error: reader unexpectedly disconnected while paying"
    );

    dispatch({
      type: SHOW_SNACK_BAR,
      payload: { message: "reader.disconnected", severity: "error" },
    });
    cancelPaymentMethod();
  };

  const cancelPaymentMethod = async () => {
    cardReaderState.terminal.cancelCollectPaymentMethod();
    history.push(PATHS.Home);
  };

  const cancelPendingPayment = async () => {
    dispatch(emptyOrder());
    if (canCancel) {
      await cancelPaymentMethod();
    }
  };
  const cancelPendingPaymentForSquare = async () => {
    dispatch(emptyOrderSquare());
    if (canCancel) {
      const modeId =
        localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || "";
      const data = {
        mode_id: parseInt(modeId),
        checkout_id: parseInt(id),
      };
      methodsServices
        .post(ENDPOINTS.CANCEL_SQUARE_CHECKOUT_ID, data, true)
        .then(async (res) => {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: "payment.canceled",
              severity: "warning",
            },
          });
          dispatch({
            type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
          });
          localStorage.removeItem(LOCAL_STORAGE_SQUARE_WAITING_TIME);
          localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
        })
        .catch((error: any) => {
          dispatch({
            type: SHOW_SNACK_BAR,
            payload: {
              message: "payment.error",
              severity: "error",
            },
          });
          dispatch({
            type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
          });
          localStorage.removeItem(LOCAL_STORAGE_SQUARE_WAITING_TIME);
          localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
        });
    }
  };
  const goBack = () => {
    if (canCancel && !squareStatus) {
      cancelPendingPayment();
      history.push(PATHS.Home);
    } else if (canCancel && squareStatus) {
      cancelPendingPaymentForSquare();
      history.push(PATHS.Home);
    }
  };
  React.useEffect(() => {
    if (squareStatus) {
      var intervalId = setInterval(() => {
        methodsServices
          .get(ENDPOINTS.PAYEMENT + `${id}`, true)
          .then((res: any) => {
            localStorage.setItem(
              LOCAL_STORAGE_SQUARE_WAITING_TIME,
              res?.data.data.waiting_time
            );
            if (res?.data.data.Checkout_Status === "COMPLETED") {
              history.push(PATHS.ORDER_SUBMITTED);
            } else if (res?.data.data.Checkout_Status === "CANCELED") {
              dispatch(emptyOrderSquare());
              dispatch({
                type: SHOW_SNACK_BAR,
                payload: {
                  message: "payment.canceled",
                  severity: "warning",
                },
              });
              dispatch({
                type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
              });
              localStorage.removeItem(LOCAL_STORAGE_SQUARE_WAITING_TIME);
              localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
              history.push(PATHS.Home);
            }
          })
          .catch((error: any) => {
            dispatch({
              type: SHOW_SNACK_BAR,
              payload: {
                message: "payment.error",
                severity: "error",
              },
            });
            dispatch({
              type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
            });
            localStorage.removeItem(LOCAL_STORAGE_SQUARE_WAITING_TIME);
            localStorage.removeItem(LOCAL_STORAGE_ORDER_ID);
          });
      }, 2000);
    }
    return () => {
      clearInterval(intervalId);
      // dispatch({
      //   type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
      // });
    };
  }, [location]);

  useEffect(() => {
    console.log("ShoppingCartState", ShoppingCartState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orderState.clientSecret && !squareStatus) {
      console.log("done fetch", orderState);
      collectCardPayment();
    }
  }, [orderState.clientSecret]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container justifyContent="flex-start">
        <Button
          className={classes.button}
          onClick={() => goBack()}
          disabled={!canCancel}
        >
          {t("common.back")}
        </Button>
      </Grid>
      <Grid container direction="column" className={classes.content}>
        <Typography className={classes.loaderText}>
          {t("payment.total")}
        </Typography>
        <Typography variant="h3" className={classes.price}>
          {!squareStatus
            ? `$${
               
                (totalValue()+ShoppingCartState.totalTax + ShoppingCartState.tipToPay).toFixed(2) || 0

              }`
            : null}
          {squareStatus
            ? `$${
               
              (totalValue()+ShoppingCartState.totalTax).toFixed(2) ||0

              }`
            : null}
        </Typography>
        <Typography className={classes.description}>
          {t("payment.insert_card")}
        </Typography>
        <Grid alignItems="center" container justifyContent="center">
          <CircularProgress size={35} className={classes.loader} />
          <Typography className={classes.loaderText}>
            {loading ? t("payment.connecting") : t("payment.remove_card")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );

};
export default PaymentPage;
