import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import {
	Grid,
	IconButton,
	Input,
	Typography,
	CircularProgress
} from "@mui/material";
import { useStyles } from "./style";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import {
	ORDER_IN_Values,
	LOCAL_STORAGE_SELECTED_ORDER_TYPE
} from "../../utils/constants";
import "./styles.scss";

interface LogoutDialogProps {
	open: boolean;
	handleClose: () => void;
	onBackdropClick: () => void;
	handleSubmit: () => void;
}

interface InputProps<T> {
	value: T;
	error: string | null;
}

const TakeInOutDialog: React.FC<LogoutDialogProps> = ({
	handleClose,
	open,
	onBackdropClick,
	handleSubmit
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const logoutState = useSelector((state: RootState) => state.auth.logout);
	const loader = false;
	const [orderType, setOrderType] = React.useState<string>("0");

	const clearInputs = () => {
		setOrderType("0");
		handleClose();
	};

	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOrderType((event.target as HTMLInputElement).value);
	};

	const setOrderTypeAndSubmit = () => {
		localStorage.setItem(LOCAL_STORAGE_SELECTED_ORDER_TYPE, orderType);
		console.log("LOCAL_STORAGE_SELECTED_ORDER_TYPE ", orderType);
		handleSubmit();
	};

	return (
        <div>
			<Dialog
				open={open}
				onClose={(event, reason) => {
					if (reason !== "backdropClick") {
						handleClose();
					}
				}}
				aria-labelledby="form-dialog-title"
				fullWidth
				onBackdropClick={onBackdropClick}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					<Grid container justifyContent="center">
						<Grid item xs={11}>
							<Grid container justifyContent="center">
								<Typography
									className={classes.cancelColor}
									style={{ fontSize: 16 }}
								>
									{t("common.order_type_title")}
								</Typography>
							</Grid>
						</Grid>
						<Grid item xs={1}>
							<Grid container justifyContent="flex-end">
								<IconButton size="small" onClick={clearInputs}>
									<CancelIcon
										className={classes.closePopup}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent className={classes.dialogTitleStyle}>
					<RadioGroup value={orderType} onChange={handleRadioChange}>
						<FormControlLabel
							classes={{ root: classes.formControlLabelRoot }}
							value={ORDER_IN_Values.IN}
							control={
								<Radio
									classes={{
										root: classes.radio,
										checked: classes.checked
									}}
								/>
							}
							label={
								<span
									style={{ fontWeight: "bold", fontSize: 16 }}
								>
									{t("common.eat_in")}
								</span>
							}
						/>
						<FormControlLabel
							classes={{ root: classes.formControlLabelRoot }}
							value={ORDER_IN_Values.OUT}
							control={
								<Radio
									classes={{
										root: classes.radio,
										checked: classes.checked
									}}
								/>
							}
							label={
								<span
									style={{ fontWeight: "bold", fontSize: 16 }}
								>
									{t("common.take_out")}
								</span>
							}
						/>
					</RadioGroup>
				</DialogContent>
				<DialogActions className={classes.dialogActionStyle}>
					<Button
						fullWidth
						className={classes.btnStyle}
						onClick={setOrderTypeAndSubmit}
						color="primary"
					>
						<Typography style={{ fontSize: 16 }}>
							{t("common.let_s_go")}
						</Typography>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
    );
};
export default React.memo(TakeInOutDialog);
