import {
    ConfirmSquareCancelState,
    ConfirmSquareCancelActions,
    CONFIRM_OR_CANCEL_SQUARE,
    CONFIRM_OR_CANCEL_ERROR_SQUARE,
    CONFIRM_OR_CANCEL_SUCCESS_SQUARE
} from "./types";

const INITIAL_STATE: ConfirmSquareCancelState = {
    loading: false
};

export const confirmSquareReducer = (
    state = INITIAL_STATE,
    action: ConfirmSquareCancelActions
): ConfirmSquareCancelState => {
    switch (action.type) {
        case CONFIRM_OR_CANCEL_SQUARE:
            return {...state, loading: true};
        case CONFIRM_OR_CANCEL_SUCCESS_SQUARE:
            return {...state, loading: false};
        case CONFIRM_OR_CANCEL_ERROR_SQUARE:
            return {...state, loading: false};
        default:
            return state;
    }
};