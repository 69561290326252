import produce from "immer";
import {
	ORDER_SQUARE_READER,
	OrderSquareTypes,
	EMPTY_ORDER_SQUARE,
	EmptyOrderSquareTypes,
	PaymentIntentTypes,
	SAVE_PAYMENT_INTENT,
	ORDER_SQUARE_READER_SUCCESS,
	ORDER_SQUARE_READER_FAIL,
	OrderSquareState
} from "./types";

const INITIAL_STATE: OrderSquareState = {

	order: null,
	loading: false,
	paymentIntent: null
};

export const orderSquareReducer = (
	state = INITIAL_STATE,
	action: OrderSquareTypes | EmptyOrderSquareTypes | PaymentIntentTypes
): OrderSquareState =>
	produce(state, draft => {
		switch (action.type) {
			case ORDER_SQUARE_READER:
				draft.loading = true;
				break;
			case ORDER_SQUARE_READER_SUCCESS:
				draft.order = action.payload.order;
				draft.loading = false;
				break;
			case ORDER_SQUARE_READER_FAIL:
				draft.loading = false;
				break;
			case EMPTY_ORDER_SQUARE:
				draft.order = null;
				draft.loading = false;
				draft.paymentIntent = null;
				break;
			case SAVE_PAYMENT_INTENT:
				draft.paymentIntent = action.payload.paymentIntent;
				break;
		}
	});
