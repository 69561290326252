import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/sass/common.scss";
import { useStyles } from "./styles";
import { RootState } from "../../store/rootReducer";
import { Input, Grid, Button, Typography } from "@mui/material";
import { checkout } from "../../store/checkout/actions";
import { ChangeEvent, useRef, useState } from "react";
import InputMask from "react-input-mask";
import {
  IS_FOCUSED_COLOR,
  NUMBER_OF_SECONDS_TO_SHOW_SLIDER_IN_CHECKOUT_PAGE,
  PHONE_MASK,
  PHONE_MAX_CHARACTERS,
  POP_ID,
  SQUARE_UP_STATUS,
  WEBSOCKET_CONNECTION_PORT,
} from "../../utils/constants";
import { PATHS } from "../../routes/paths";
import {
  createTheme,
  adaptV4Theme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import { emptyOrder, passOrder, stripeOrPopId } from "../../store/order/actions";
import { history } from "../../routes/history";
import { SimpleSlider, Spinner } from "../../components";
import IdleTimer from "react-idle-timer";
import { RemoveAllProductsFromShoopingCart } from "../../store/shoopingCart/actions";
import Checkbox from "@mui/material/Checkbox";
import { removeMaskElements } from "../../utils/helpers";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { config } from "../../config/config";
import { useLocation } from "react-router-dom";
import { ShoopingCartModel } from "../../store/shoopingCart/models";
import {
  passOrderSquare,
  ShoopingCartModelSquare,
} from "../../store/squareOrder/actions";
import useWebSocket from "react-use-websocket";
import { successPayment } from "../../store/popID/action";
import PopIdDialog from "../../components/popIdDialog";
declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface CheckoutProps {}

interface InputProps<T> {
  value: T;
  error: string | null;
}
export const Checkout = (props: CheckoutProps) => {
	const fnameInputRef = useRef<HTMLInputElement | null>(null);
	const lNameInputRef = useRef<HTMLInputElement | null>(null);
	const phoneInputRef = useRef<HTMLInputElement | null>(null);
	const [socket, setSocket] = useState(false);
	const wishInput = useRef<number>(-1);
	const squareStatus = parseInt(
		localStorage.getItem(SQUARE_UP_STATUS) as string
	);
	const popIdUrl = localStorage.getItem(POP_ID);
	const StripeOrPopIdStatus = useSelector(
		(state: RootState) => state?.order?.popid
	);
	const [orderSquare, setOrderSquare] = useState<ShoopingCartModel>();
	const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
	const location = useLocation();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const menuState = useSelector((state: RootState) => state.menu);
	const popIdState = useSelector((state: RootState) => state.popId);
	const colors = menuState?.colors;
	const classes = useStyles({ colors: colors });
  const [openDialog, setOpenDialog] = useState(false);
  
	//Get the total Value of all product
	const totalValue = () => {
		return shoopingCart.shoopingProduct.reduce(
			(somme, current) => somme + current.price,
			0
		);
	};
	const amountToPay = (
		totalValue() +
		shoopingCart.totalTax +
		shoopingCart.tipToPay
	).toFixed(2);
	const { sendMessage } = useWebSocket(
		popIdUrl + WEBSOCKET_CONNECTION_PORT,
		{
			onOpen: () => {
				sendMessage(
					'{"type": "pay","person_id":"faceId", "amount":"price", "tip":"0.00"}'
						.replace("faceId", popIdState?.person[0]?.faceId)
						.replace("price", amountToPay)
				);
			},
			onClose: () => {},
			shouldReconnect: closeEvent => true,
			onMessage: (event: WebSocketEventMap["message"]) => {
				processMessages(event);
			}
		},
		socket
	);
	const processMessages = (event: { data: string }) => {
		const response = JSON.parse(event.data);

		if (Object.keys(response).length == 0) {
			setOpenDialog(true);
		} else {
			dispatch(successPayment(response.transactionId));
			dispatch(checkout(fname.value, lname.value, phone.value, vip));
		}
	};
	const outerTheme = createTheme(
		adaptV4Theme({
			palette: {
				secondary: {
					main: colors[0]
				}
			}
		})
	);

const [fname, setFname] = React.useState<InputProps<string>>({
	value:
		popIdState?.person[0] && Object.keys(popIdState?.person[0])
			? popIdState?.person[0]?.firstName
			: "",
	error: null
});
const [lname, setLname] = React.useState<InputProps<string>>({
	value:
		popIdState?.person[0] && Object.keys(popIdState?.person[0])
			? popIdState?.person[0]?.lastName
			: "",
	error: null
});
const [phone, setPhone] = React.useState<InputProps<string>>({
	value:
		popIdState?.person[0] && Object.keys(popIdState?.person[0])
			? popIdState?.person[0]?.phone
			: "",
	error: null
});

	const [layout, setLayout] = useState("default");
	const keyboard = useRef<typeof Keyboard | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);

	const [showKeyboard, setShowKeyboard] = useState(false);
	const [idleTimer, setIdleTimer] = useState<IdleTimer | null>(null);
	const [isTimedOut, setIsTimedOut] = useState(false);
	const [showSlider, setShowSlider] = useState(false);
	const [vip, setVip] = useState(true);
	const handleOnAction = (event: any) => {
		setIsTimedOut(false);
	};

	const handleOnActive = (event: any) => {
		setIsTimedOut(false);
	};

	const hideSlider = () => {
		cancelPendingPayment();
		history.push(PATHS.Home);
		setShowSlider(false);
	};
	const closeDialog = () => {
		setOpenDialog(false);
		history.push(PATHS.Home);
  };
  	const handleRedirectStrip = () => {
		const popIdStatus: any = 0;
		dispatch(stripeOrPopId(popIdStatus));
		dispatch(passOrder(shoopingCart, popIdStatus));
		history.push(PATHS.PAYMENT);
	};
	const handleOnIdle = (event: any) => {
		if (isTimedOut) {
		} else {
			setIsTimedOut(true);
			setShowSlider(true);
			dispatch(RemoveAllProductsFromShoopingCart());
			console.log("idle mode");
		}
	};

	const cardReaderState = useSelector((state: RootState) => state.cardreader);

	const handleOnFnameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFname({
			...fname,
			value: e.target.value,
			error: null
		});
	};

	const handleOnLnameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setLname({
			...lname,
			value: e.target.value,
			error: null
		});
	};

	const handleOnPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhone({
			...phone,
			value: e.target.value,
			error: null
		});
	};
	const handleChangeFName = (input: any) => {
		//@ts-ignore
		setFname({ ...fname, value: input });
	};
	const handleChangeLName = (input: any) => {
		//@ts-ignore
		setLname({ ...lname, value: input });
	};

	const onChange = (input: any) => {
		console.log("input", input);
		switch (wishInput.current) {
			case 0:
				handleChangeFName(input);
				break;
			case 1:
				handleChangeLName(input);
				break;
			case 2:
				let phoneNumbersOnly = input.replace(/\D/g, "");
				phoneNumbersOnly.length <= PHONE_MAX_CHARACTERS &&
					setPhone({ ...phone, value: phoneNumbersOnly });
				break;
			default:
				break;
		}
		console.log(wishInput.current);
		//@ts-ignore
	};
	const handleShift = () => {
		const newLayoutName = layout === "default" ? "shift" : "default";
		setLayout(newLayoutName);
	};
	const onKeyPress = (button: any) => {
		if (button === "{bksp}") {
			switch (wishInput.current) {
				case 0:
					setFname({ ...fname, value: fname.value.slice(0, -1) });
					break;
				case 1:
					setLname({ ...lname, value: lname.value.slice(0, -1) });
					break;
				case 2:
					setPhone({ ...phone, value: phone.value.slice(0, -1) });
					break;
				default:
					break;
			}
		}
		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") handleShift();
	};
	const checkoutState = useSelector((state: RootState) => state.checkout);
	const squareCheckoutState = useSelector((state: RootState) => state.square);

	const { loading: normalLoading } = checkoutState;
	const { loading: squareLoading } = squareCheckoutState;

	const onClick = () => {
		if (fname.value === "")
			setFname({ ...fname, error: t("validations.required") });
		if (lname.value === "")
			setLname({ ...lname, error: t("validations.required") });
		if (config.SMS_ACTIVE) {
			if (phone.value === "")
				setPhone({ ...phone, error: t("validations.required") });
			if (
				phone.value !== "" &&
				removeMaskElements(phone.value).length < 10 &&
				config.SMS_ACTIVE
			) {
				setPhone({ ...phone, error: t("validations.phone") });
			}
		}
		if (
			fname.value !== "" &&
			lname.value !== "" &&
			(phone.value !== "" || !config.SMS_ACTIVE)
		) {
			const secondItem = {
				firstName: fname.value,
				lastName: lname.value,
				phoneNumber: phone.value
			};
			if (squareStatus) {
				const newItem = { ...orderSquare, ...secondItem };
				dispatch(passOrderSquare(newItem as ShoopingCartModelSquare));
			} else {
				if (
					menuState?.merchant.popid_status === 0 &&
					StripeOrPopIdStatus === 0
				) {
					dispatch(
						checkout(fname.value, lname.value, phone.value, vip)
					);
				} else if (menuState?.merchant.popid_status === 1) {
					if (StripeOrPopIdStatus === 0) {
						dispatch(
							checkout(fname.value, lname.value, phone.value, vip)
						);
					} else {
						setSocket(true);
					}
				}
			}
		}
	};
	const StyledButton = withStyles({
		root: {
			"&:hover": {
				backgroundColor: colors[1],
				color: colors[0]
			}
		}
	})(Button);

	const cancelPendingPayment = async () => {
		console.log("cancel collect payment...");
		dispatch(emptyOrder());
		//await cardReaderState.terminal.clearReaderDisplay();
	};

	const goBack = () => {
		cancelPendingPayment();
		history.push(PATHS.Home);
	};
	const onFocusFirstName = (e: any) => {
		e.target.blur();
		setFname({ ...fname });

		//@ts-ignore
		if (keyboard.current !== null) {
			//@ts-ignore

			keyboard.current.setInput(fname.value);
		}
		wishInput.current = 0;
		console.log("wishinputFirst", wishInput.current);
		setShowKeyboard(true);
	};
	const onFocusLastName = (e: any) => {
		e.target.blur();
		setLname({
			...lname
		});

		//@ts-ignore
		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(lname.value);
		}
		wishInput.current = 1;
		console.log("wishinputlast", wishInput.current);
		setShowKeyboard(true);
	};
	const onFocusPhone = (e: any) => {
		e.target.blur();
		//@ts-ignore
		setPhone({
			...phone
		});

		//@ts-ignore
		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(phone.value);
		}
		wishInput.current = 2;
		console.log("wishinputphone", wishInput.current);

		setShowKeyboard(true);
	};
	React.useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: MouseEvent) {
			//@ts-ignore
			if (
				ref.current &&
				event.target.tagName.toUpperCase() !== "INPUT" &&
				event.target.tagName.toUpperCase() !== "SPAN" &&
				!ref.current.contains(event.target as Node)
			) {
				//@ts-ignore
				console.log("Event target", event.target.tagName.toUpperCase());
				wishInput.current = -1;
				setShowKeyboard(false);
				console.log("wishinputout", wishInput.current);
			}
		}

		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
	React.useEffect(() => {
		const order = location.state;
		setOrderSquare(order as ShoopingCartModel);
		return () => {};
	}, [location]);
	console.log("mon ordre", orderSquare);
	React.useEffect(() => {
		if (!menuState?.merchant || !menuState?.merchant?.id) {
			history.push(PATHS.Home);
		}
	}, [menuState.merchant]);

	return (
		<div style={{ marginBottom: showKeyboard ? "30vh" : 0 }}>
			<IdleTimer
				ref={(ref: any) => {
					setIdleTimer(ref);
				}}
				timeout={
					NUMBER_OF_SECONDS_TO_SHOW_SLIDER_IN_CHECKOUT_PAGE * 1000
				}
				onActive={handleOnActive}
				onIdle={handleOnIdle}
				onAction={handleOnAction}
				debounce={
					NUMBER_OF_SECONDS_TO_SHOW_SLIDER_IN_CHECKOUT_PAGE * 1000
				}
			/>
			{showSlider ? (
				<SimpleSlider hideSlider={hideSlider} />
			) : (
				<Grid
					container
					direction="column"
					className={classes.container}
				>
					<Grid container justifyContent="flex-start">
						<Button
							className={classes.button}
							onClick={() => goBack()}
						>
							{t("common.back")}
						</Button>
					</Grid>
					<Grid
						container
						direction="column"
						className={classes.content}
					>
						<Typography className={classes.title}>
							{t("checkout.title")}
						</Typography>
						<Typography className={classes.title}>
							{t("checkout.complete_your_informations")}
						</Typography>
						<Grid
							alignItems="center"
							className={classes.formContainer}
							container
							justifyContent="center"
						>
							<Grid
								spacing={3}
								container
								className={classes.formLigne}
							>
								<Grid
									item
									xs={5}
									className={classes.labelContainer}
								>
									<div className={classes.label}>
										{t("checkout.first_name")}
									</div>
								</Grid>
								<Grid
									item
									xs={7}
									className={classes.inputContainer}
								>
									<Input
										style={{
											border:
												wishInput.current === 0
													? `3px solid ${IS_FOCUSED_COLOR}`
													: undefined
										}}
										ref={fnameInputRef}
										onFocus={onFocusFirstName}
										onChange={handleOnFnameChange}
										className={classes.input}
										disableUnderline={true}
										value={fname.value}
									/>
								</Grid>
							</Grid>
							<Grid
								spacing={3}
								container
								className={classes.formLigne}
							>
								<Grid
									item
									xs={5}
									className={classes.labelContainer}
								></Grid>
								<Grid
									item
									xs={7}
									className={classes.inputContainer}
								>
									<Typography className={classes.error}>
										{fname.error}
									</Typography>
								</Grid>
							</Grid>
							<Grid
								spacing={3}
								container
								className={classes.formLigne}
							>
								<Grid
									item
									xs={5}
									className={classes.labelContainer}
								>
									<div className={classes.label}>
										{t("checkout.last_name")}
									</div>
								</Grid>
								<Grid
									item
									xs={7}
									className={classes.inputContainer}
								>
									<Input
										style={{
											border:
												wishInput.current === 1
													? `3px solid ${IS_FOCUSED_COLOR}`
													: undefined
										}}
										ref={lNameInputRef}
										onFocus={onFocusLastName}
										onChange={handleOnLnameChange}
										className={classes.input}
										disableUnderline={true}
										value={lname.value}
									/>
								</Grid>
							</Grid>
							<Grid
								spacing={3}
								container
								className={classes.formLigne}
							>
								<Grid
									item
									xs={5}
									className={classes.labelContainer}
								></Grid>
								<Grid
									item
									xs={7}
									className={classes.inputContainer}
								>
									<Typography className={classes.error}>
										{lname.error}
									</Typography>
								</Grid>
							</Grid>
							{config.SMS_ACTIVE && (
								<>
									<Grid
										spacing={3}
										container
										className={classes.formLigne}
									>
										<Grid
											item
											xs={5}
											className={classes.labelContainer}
										>
											<div className={classes.label}>
												{t("checkout.phone_number")}
											</div>
										</Grid>
										<Grid
											item
											xs={7}
											className={classes.inputContainer}
										>
											<InputMask
												onFocus={onFocusPhone}
												mask={PHONE_MASK}
												value={phone.value}
												onChange={handleOnPhoneChange}
											>
												{() => (
													<Input
														ref={phoneInputRef}
														style={{
															border:
																wishInput.current ===
																2
																	? `3px solid ${IS_FOCUSED_COLOR}`
																	: undefined
														}}
														onChange={
															handleOnPhoneChange
														}
														className={
															classes.input
														}
														disableUnderline={true}
													/>
												)}
											</InputMask>
										</Grid>
									</Grid>
									<Grid
										spacing={3}
										container
										className={classes.formLigne}
									>
										<Grid
											item
											xs={5}
											className={classes.labelContainer}
										></Grid>
										<Grid
											item
											xs={7}
											className={classes.inputContainer}
										>
											<Typography
												className={classes.error}
											>
												{phone.error}
											</Typography>
										</Grid>
									</Grid>
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: 700
										}}
									>
										<div
											style={{
												display: "flex",
												alignItems: "flex-start",
												justifyContent: "flex-start"
											}}
										>
											<StyledEngineProvider injectFirst>
												<ThemeProvider
													theme={outerTheme}
												>
													<Checkbox
														classes={{
															root: classes.root
														}}
														checked={vip}
														value={vip}
														onChange={event => {
															setVip(!vip);
														}}
													/>
												</ThemeProvider>
											</StyledEngineProvider>
										</div>
										<div
											style={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center"
											}}
										>
											<Typography
												className={classes.vipText}
											>
												{t("checkout.sign_me_as_vip")}
											</Typography>
										</div>
									</div>
								</>
							)}
							<Grid
								container
								justifyContent="center"
								className={classes.formLigne}
							>
								<Grid
									item
									xs={5}
									className={classes.labelContainer}
								></Grid>
								<Grid
									item
									xs={7}
									className={classes.inputContainer}
								>
									<Grid container>
										<StyledButton
											onClick={onClick}
											variant="contained"
											className={classes.submitBtn}
										>
											<Typography
												style={{
													fontSize: 15,
													padding: 2
												}}
											>
												{t(
													"checkout.send_order_to_kitchen"
												)}
											</Typography>
										</StyledButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{showKeyboard && (
						<div ref={ref} className={"keyboardContainer"}>
							<Keyboard
								keyboardRef={(r: any) => (keyboard.current = r)}
								layoutName={layout}
								onChange={onChange}
								onKeyPress={onKeyPress}
							/>
						</div>
					)}
					{
						<PopIdDialog
							open={openDialog}
							pay={true}
							handleClose={closeDialog}
							handleRedirectStrip={handleRedirectStrip}
							message={t("payment.fail_pay")}
						></PopIdDialog>
					}
					{squareStatus && <Spinner loading={squareLoading} />}
					{!squareStatus && <Spinner loading={normalLoading} />}
				</Grid>
			)}
		</div>
	);
};
export default Checkout;
