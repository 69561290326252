import React from "react";
import Checkbox from "@mui/material/Checkbox";
import {useStyles} from "./styes";
import {useSelector} from "react-redux";
import {RootState} from "../../store/rootReducer";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


interface ModifierCheckBoxProps {
    value: number;
    onChange?: (event: any) => void;
    checked: boolean;
}

export const ModifierCheckBox = (props: ModifierCheckBoxProps) => {
    const {value, onChange, checked} = props;
    const menuState = useSelector((state: RootState) => state.menu);
    const colors = menuState?.colors;
    const classes = useStyles({colors});
    const outerTheme = createTheme(adaptV4Theme({
        palette: {
            secondary: {
                main: colors[1],
            },
        },
    }));


    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={outerTheme}>
                <Checkbox
                    classes={{
                        root: classes.root,
                    }}
                    checked={checked ? true : false}
                    value={value}
                    onChange={onChange}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
export default ModifierCheckBox;
