import makeStyles from '@mui/styles/makeStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
	container: (props: ColorProps) => ({
		color: props.colors[1],
		background: props.colors[0],
		minHeight: "calc(100vh - 55px)",
		paddingBottom: 55,
	}),
	footer: {
		width: "100vw",
		position: "fixed",
		bottom: 0,
	},
});
