import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({

   appBar: {
    [theme.breakpoints.up("md")]: {
        width: '70vw',
      },
   } 

}))