import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		conatiner: {
			marginTop: 20,
		},
		cancelContainerStyle: (props: ColorProps) => ({
			display: "flex",
			flex: 1,
			justifyContent: "flex-end",
		}),
		cancelStyle: (props: ColorProps) => ({ color: props.colors[1] }),
		imageContainerStyle: (props: ColorProps) => ({
			display: "flex",
			flexDirection: "row",
		}),
		modifiersStyle: (props: ColorProps) => ({
			textAlign: "start",
			marginLeft: 14,
			color: props.colors[1],
			opacity: 0.3,
			fontSize: 13,
		}),
		modifiersPriceStyle: (props: ColorProps) => ({
			textAlign: "end",
			marginRight: 50,
			color: props.colors[1],
			opacity: 0.3,
			fontSize: 13,
		}),
		imageStyle: {
			width: 60,
			height: 60,
			marginTop: 3,
			borderRadius: 5,
			objectFit: "cover",
			boxShadow:
				"0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
		},
		leftTextStyle: { textAlign: "start", marginLeft: 10 },
		rightTextStyle: { textAlign: "end", marginRight: 50 },
	})
);
