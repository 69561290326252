import * as React from "react";
import Slider from "react-slick";
import { Button, Grid, useTheme } from "@mui/material";
import placeHolder from "../../assets/img/placeholder.png";
import "./styles.scss";
import { PowredByTyme } from "..";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { config } from "../../config/config";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import CardMedia from "../CardMedia";
import miniLogo from "../../assets/img/tyme_mini.png";
import backgroundPlaceholder from "../../assets/img/background.png";
import withStyles from "@mui/styles/withStyles";
interface SliderProps {
	hideSlider: () => void;
}

export default function SimpleSlider(props: SliderProps) {
	const { t } = useTranslation();
	const { hideSlider } = props;
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const merchant = menuState?.merchant;

	const pictures: any = [
		{ image: merchant.photo1, title: "I-1" },
		{ image: merchant.photo2, title: "I-2" },
		{ image: merchant.photo3, title: "I-3" },
		{ image: merchant.photo1, title: "I-1" },
		{ image: merchant.photo2, title: "I-2" },
		{ image: merchant.photo3, title: "I-3" }
	];
		var settings = {
			dots: false,
			speed: 2000,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 4000
	};

	const StyledButton = withStyles({
		root: {
			backgroundColor: colors[1],
			color: colors[0],
			paddingLeft: "5%",
			paddingRight: "5%",
			fontSize: "24pt",
			"&:hover": {
				backgroundColor: colors[0],
				color: colors[1]
			}
		}
	})(Button);

	return (
		<Dialog
			open={true}
			onClose={() => {
				console.log("close");
			}}
			fullWidth
			id="slider-dialog"
		>
			<Slider {...settings}>
			{	pictures.map((picture: any) => (
						<div key={picture.title}>
							<CardMedia
								style={{
									height: "105vh",
									alignItems: "center",
									justifyContent: "center",
									display: "flex"
								}}
								image={
									picture.image ? picture.image : placeHolder
								}
								placeHolder={backgroundPlaceholder}
							/>
						</div>
					))}
			</Slider>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				style={{ height: "80%", position: "absolute", bottom: 50 }}
			>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					direction="column"
					style={{ marginBottom: "30vh" }}
				>
					<CardMedia
						style={{
							height: 130,
							width: 130,
							objectFit: "contain"
						}}
						image={
							merchant.logo
								? config.BASE_URL_API + merchant.logo
								: placeHolder
						}
						placeHolder={miniLogo}
					/>
				</Grid>
				<Grid
					container
					justifyContent="center"
					alignItems="center"
					direction="column"
				>
					<StyledButton onClick={() => hideSlider()}>
						{t("common.click_to_order")}
					</StyledButton>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				direction="column"
				style={{ position: "absolute", bottom: 50 }}
			>
				<PowredByTyme />
			</Grid>
		</Dialog>
	);
}
