import makeStyles from '@mui/styles/makeStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
	logo: {
		height: 25
	},
	typography: {
		fontfamily: "Roboto",
		fontWeight: 500,
		letterSpacing: 5,
		textTransform: "uppercase",
		paddingRight: 10,
		margin: 0,
		fontSize: 12,
		marginLeft: 5
	},
	copyrigth: {
		fontfamily: "Roboto",
		fontWeight: 500,
		letterSpacing: 5,
		textTransform: "uppercase",
		margin: 0,
		fontSize: 12
	},
	crsymbol: {
		marginLeft: 3,
		fontSize: 15
	},
	year: {
		fontfamily: "Roboto",
		fontWeight: 500,
		letterSpacing: 5,
		textTransform: "uppercase",
		paddingLeft: 10,
		margin: 0,
		fontSize: 12,
		marginLeft: -2
	},
	container: (props: ColorProps) => ({
		padding: "15px 10px",
		color: props.colors[1],
		background: props.colors[0],
		bottom: 0,
	})
});
