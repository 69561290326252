import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			paddingTop: 70,
			[theme.breakpoints.down('md')]: {
				paddingRight: 70
			}
		},
		loadingContainer: {
			height: "calc(100vh - 100px)"
		}
	})
);
