// storage keys
export const SQUARE_CHECKOUT_ID="squareCheckoutId";
export const SQUARE_UP_STATUS="squareUpStatus";
export const SIGHTCORP_STATUS = "sightcorp_status";
export const POP_ID_STATUS = "popid_status";
export const POP_ID = "pop_id";
export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_MODE = "modeId";
export const LOCAL_STORAGE_ORDER_ID = "orderId";
export const LOCAL_STORAGE_LOCATION_ID = "locationId";
export const LOCAL_STORAGE_SELECTED_MODE = "selectedMode";
export const LOCAL_STORAGE_SELECTED_ORDER_TYPE = "orderType";
export const LOCAL_STORAGE_PAYMENT_INTENT_ID = "paymentIntentId";
export const LOCAL_STORAGE_WAITING_TIME = "waitingTime";
export const LOCAL_STORAGE_SQUARE_WAITING_TIME = "waiting_time";
export const LOCAL_STORAGE_PRODUCT = "product";
export const LOCAL_STORAGE_SERIAL_NUMBER = "serialNumber";
export const LOCAL_STORAGE_CATEGORY = "category";
export const LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED = "kioskChoiceConfigured";
export const SEPARATED_MIN_MAX_PRODUCT = " - $";
export const DEFAULT_PRIMARY_COLOR = "#CFE2F3";
export const DEFAULT_SECONDARY_COLOR = "#C90076";
export const UNAUTHORIZED_STATUS = 423;
export const MODEL_NOT_FOUND = 404;
export const UNAUTHORIZED_TOKEN = 401;

export const AXIOS_TIMEOUT = 10000;
export const PHONE_MAX_CHARACTERS = 10;
export const MINIMUM_ORDER_TO_PAY = 1.00;
export const IS_FOCUSED_COLOR = "#363287";
export const KIOSK_MODES = {
	INACTIVE: 0,
	GLOBAL: 1,
	TABLE: 2,
	GLOBAL_AND_TABLE: 3
};

export const KIOSK_MODE_AVAILABILITY = {
	NOT_AVAILABILE: 0,
	AVAILABILE: 1
};
export const PAIR = {
	SEPARATED: 0,
	PAIRED: 1
};


export const KIOSK_MODE_STATUS = {
	INACTIVE: 0,
	ACTIVE: 1
};

export const POPID_STATUS = {
	PAY_WITH_POPID: 1,
	PAY_WITHOUT_POPID: 0
};
export const MIN_PASSWORD_LENGTH = 6;
export const PHONE_MASK = "999-999-9999";
export const LAST_FOUR_OF_PHONE_NUMBER_MASK = "9_9_9_9"
export const FOUR_PHONE_NUMBER = 4;
export const NUMBER_OF_SECONDS_OF_COUNTER = 5;
export const NUMBER_OF_SECONDS_TO_REDIRECT_TO_HOME = 10000;

export const LOCAL_STORAGE_SHOOPING_CARTS = "shooping";
export const SIGHT_CORP ="sight_corp";
export const ORDER_IN_Values = {
	IN: "1",
	OUT: "0"
};
export const SEND_MSG_POPID_TYPE_SEARCH = '{"type":"search"}';
export const USE_TAX_VALUE = 0;
export const NUMBER_OF_SECONDS_TO_SHOW_SLIDER = 120;
export const NUMBER_OF_SECONDS_TO_SHOW_SLIDER_IN_CHECKOUT_PAGE = 180;

export const NO_READER_STATUS = "no_established_connection";
export const NUMBER_OF_CLICKS_TO_FORCE_LOGOUT = 5;
export const NUMBER_OF_CLICKS_TO_RELOAD_PAGE = 5;
export const RETRY_READER_CONNECT_COUNT = 1440;
export const TIME_OUT_RECONNECT = 60000;

export const DEEP_SIGHT_DEFAULT_TIMER = "60000";

export const LOCAL_STORAGE_DEEP_SIGHT = "deep_sight";

export const DEEP_SIGHT_DEFAULT_DISTANCE = "1";

export const SUGGESTION_TAB_VALUE = 0;

export const MALE_GENDER = 1;
export const FEMALE_GENDER = 2;

export const DEEP_ENV_VALUE = "1";

export const DEFAULT_TIP_PERCENTAGE = 20;

export const INCREMENT_VALUE = 1;

export const POP_ID_HOST = "ws://127.0.0.1";
export const WEBSOCKET_CONNECTION_PORT: string = ":10244";
export const VIDEO_PREVIEW_ACCESS_PORT: string = ":10245";