import makeStyles from "@mui/styles/makeStyles";
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
	content: {
		margin: "auto",
		display: 'flex',
		flexDirection: 'column',
		rowGap:'30px'
	},
	button: (props: ColorProps) => ({
		border: "1px solid",
		borderColor: props.colors[0],
		color: props.colors[0]
	}),
	container: (props: ColorProps) => ({
		minHeight: "calc(100vh - 55px)",
		padding: 30,
		backgroundColor: props.colors[1]
	}),
	price: (props: ColorProps) => ({
		fontFamily: "Avenir",

		color: props.colors[0]
	}),
	description: (props: ColorProps) => ({
		fontFamily: "Avenir",
		paddingBottom: 30,
		whiteSpace: "pre-line",
		color: props.colors[0]
	}),
	loaderText: (props: ColorProps) => ({
		color: props.colors[0]
	}),
	img: {
		width: "300px",
		height: "300px",
		clipPath: "circle(50%)"
	}
});
