// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Avenir/Avenir-Black.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Avenir\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");font-style:normal;font-display:swap}.container{display:flex;align-items:center}#decrease-btn .MuiButton-label{transform:scale(2.1);-ms-transform:scale(2.1);-moz-transform:scale(2.1);-webkit-transform:scale(2.1);-o-transform:scale(2.1)}#increase-btn .MuiButton-label{transform:scale(2.1);-ms-transform:scale(2.1);-moz-transform:scale(2.1);-webkit-transform:scale(2.1);-o-transform:scale(2.1)}", "",{"version":3,"sources":["webpack://./src/assets/sass/main.scss","webpack://./src/assets/sass/fonts.scss","webpack://./src/assets/sass/quantityButtons.scss"],"names":[],"mappings":"AAGA,WACE,oBCFY,CDGZ,8DAAA,CACA,iBAAA,CACA,iBAAA,CELF,WACE,YAAA,CACA,kBAAA,CAGF,+BACE,oBAAA,CACA,wBAAA,CACA,yBAAA,CACA,4BAAA,CACA,uBAAA,CAGF,+BACE,oBAAA,CACA,wBAAA,CACA,yBAAA,CACA,4BAAA,CACA,uBAAA","sourcesContent":["@import 'colors.scss';\n@import 'fonts.scss';\n\n@font-face {\n  font-family: $font_avenir;\n  src: url($assets_root + $font_avenir_path) format(\"truetype\");\n  font-style: normal;\n  font-display: swap;\n}\n","$assets_root: \"../\";\n\n$font_avenir: \"Avenir\" !default;\n$font_avenir_path: \"fonts/Avenir/Avenir-Black.ttf\" !default;\n","@import \"main.scss\";\n\n.container {\n  display: flex;\n  align-items: center;\n}\n\n#decrease-btn .MuiButton-label {\n  transform: scale(2.1);\n  -ms-transform: scale(2.1); /* IE */\n  -moz-transform: scale(2.1); /* FF */\n  -webkit-transform: scale(2.1); /* Safari and Chrome */\n  -o-transform: scale(2.1);\n}\n\n#increase-btn .MuiButton-label {\n  transform: scale(2.1);\n  -ms-transform: scale(2.1); /* IE */\n  -moz-transform: scale(2.1); /* FF */\n  -webkit-transform: scale(2.1); /* Safari and Chrome */\n  -o-transform: scale(2.1);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
