import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid, IconButton, Typography } from "@mui/material";
import { useStyles } from "./styles";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { IS_FOCUSED_COLOR } from "../../utils/constants";
import Input from "../Input";
interface CommentDialogProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (text: string) => void;
}

const CommentDialog: React.FC<CommentDialogProps> = ({
  handleClose,
  open,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const menuState = useSelector((state: RootState) => state.menu);
  const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
  const [text, setText] = React.useState<string>(shoopingCart.comment);
  const colors = menuState?.colors;
  const [layout, setLayout] = useState("default");
  const keyboard = useRef<typeof Keyboard | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const classes = useStyles({ colors });
  const handleSubmitComment = (text: string) => {
    setShowKeyboard(true);
    handleSubmit(text);
  };
  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };
  const onKeyPress = (button: any) => {
    if (button === "{bksp}") {
      setText(text.slice(0, -1));
    }
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };
  const onChange = (input: any) => {
    console.log(keyboard.current);
    //@ts-ignore
    if (
      keyboard &&
      keyboard.current &&
      keyboard.current.input.default !== shoopingCart.comment
    ) {
      setText(shoopingCart.comment + input);
    } else setText(input);
  };
  const handleChangeText = (e: any) => {
    setText(e.target.value);
  };
  const onFocusComment = (e: any) => {
     e.target.blur();
    setIsFocused(true);
    if (keyboard.current !== null) {
      console.log("shoopingCart.comment", shoopingCart.comment);
      //@ts-ignore
      keyboard.current.setInput(
        text !== shoopingCart.comment && text !== ""
          ? text
          : shoopingCart.comment
      );
    }

    setShowKeyboard(true);
  };
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      const element = event.target as HTMLElement;
      if (
        ref.current &&
        element &&
        !ref.current.contains(element) &&
        element.tagName &&
        element.tagName.toUpperCase() !== "INPUT" &&
        element.tagName.toUpperCase() !== "SPAN"
      ) {
        console.log("Event target", element.tagName.toUpperCase());
        setShowKeyboard(false);
        setIsFocused(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        style={{ marginBottom: showKeyboard ? "30vh" : 0 }}
      >
        <DialogTitle
          className={classes.dialogTitleStyle}
          id="form-dialog-title"
        >
          <Grid container justifyContent="flex-end">
            <IconButton size="small" onClick={handleClose}>
              <CancelIcon className={classes.cancelColor} />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogTitleStyle}>
          <DialogContentText className={classes.cancelColor}>
            {t("order.additional_instruction")}
          </DialogContentText>
          <div style={{ marginBottom: 20 }}>
            <Input
              isFocus={isFocused}
              onFocus={(e: any) => onFocusComment(e)}
              onChange={handleChangeText}
              placeholder={""}
              type={"default"}
              value={text}
            />
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActionStyle}>
          <Button
            fullWidth
            className={classes.btnStyle}
            onClick={() => handleSubmitComment(text)}
            color="primary"
          >
            <Typography style={{ fontSize: 18 }}>
              {t("common.submit").toUpperCase()}
            </Typography>
          </Button>
        </DialogActions>
        {showKeyboard && (
          <div ref={ref} className={"keyboardContainer"}>
            <Keyboard
              keyboardRef={(r: any) => (keyboard.current = r)}
              layoutName={layout}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
};
export default React.memo(CommentDialog);
