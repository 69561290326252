import { ThunkDispatch } from "redux-thunk";
import {
	DEFAULT_PRIMARY_COLOR,
	DEFAULT_SECONDARY_COLOR,
	LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
	LOCAL_STORAGE_MODE
} from "../../utils/constants";
import { getErrorMessage } from "../../utils/helpers";
import { RootState } from "../rootReducer";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import {
	MenuActionTypes,
	FETCH_MENU,
	FETCH_MENU_ERROR,
	FETCH_MENU_LOADING,
	MerchantInterface,
	FETCH_SUGGESTION_MENU,
	SuggestionsTypes,
	FETCH_SUGGESTION_MENU_ERROR,
	FETCH_SUGGESTION_MENU_SUCCESS,
	REMOVE_SUGGESTION_MENU,
	GET_CATEGORY_VALUE,
} from "./types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";

/**
 * fetch list of poducts
 *
 * @returns
 */
export const fetchMenu = () => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			MenuActionTypes | SnackBarActionTypes
		>
	) => {
		const modelId = localStorage.getItem(
			LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED
		);

		try {
			methodsServices
				.get(
					ENDPOINTS.MENU +
						(modelId ? `?${LOCAL_STORAGE_MODE}=${modelId}` : ""),
					true
				)
				.then((res: any) => {
					dispatch({
						type: FETCH_MENU,
						payload: {
							merchant: res?.data?.data?.merchant,
							colors: res?.data?.data?.merchant?.colors,
							promotions: res?.data?.data?.merchant_promotions,
							loading: false
						}
					});
				});
		} catch (error: any) {
			dispatch({
				type: FETCH_MENU_ERROR,
				payload: {
					merchant: {} as MerchantInterface,
					promotions: [],
					colors: [DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR],
					loading: true
				}
			});
			dispatch({
				type: SHOW_SNACK_BAR,
				payload: {
					message: getErrorMessage(error),
					severity: "error"
				}
			});
		}
	};
};

/**
 * fetch list of poducts
 *
 * @returns
 */
export const getSuggestions = (gender: number, age: number) => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			SuggestionsTypes | SnackBarActionTypes
		>
	) => {
		const modelId = localStorage.getItem(
			LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED
		);

		methodsServices
			.get(
				`${ENDPOINTS.SUGGESTION}${
					modelId ? `?mode_id=${modelId}` : ""
				}&age=${age}&gender=${gender}`,
				true
			)
			.then((res: any) => {
				dispatch({
					type: FETCH_SUGGESTION_MENU_SUCCESS,
					payload: {
						suggestions: res?.data?.data?.products
					}
				});
			})
			.catch((error: any) => {
				dispatch({
					type: FETCH_SUGGESTION_MENU_ERROR,
					payload: { suggestions: [] }
				});
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
			});
	};
};

/**
 * fetch list of poducts
 *
 * @returns
 */
export const removeSuggestionList = () => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			SuggestionsTypes | SnackBarActionTypes
		>
	) => {
		dispatch({
			type: REMOVE_SUGGESTION_MENU
		});
	};
};

/**
 * return index category from Array Categories
 *
 * @returns
 */
export const getValue = (valueCategory: number): MenuActionTypes => ({
	type: GET_CATEGORY_VALUE,
	valueCategory: valueCategory
});