import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../rootReducer";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import {
	OrderSquareTypes,
	ORDER_SQUARE_READER,
	EMPTY_ORDER_SQUARE,
	EmptyOrderSquareTypes,
	SAVE_PAYMENT_INTENT,
	PaymentIntentTypes,
	ORDER_SQUARE_READER_SUCCESS,
	ORDER_SQUARE_READER_FAIL
} from "./types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import { getErrorMessage, getPersonGender } from "../../utils/helpers";
import {
	KIOSK_MODES,
	LOCAL_STORAGE_DEEP_SIGHT,
	LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
	LOCAL_STORAGE_ORDER_ID,
	LOCAL_STORAGE_SELECTED_MODE,
	LOCAL_STORAGE_SELECTED_ORDER_TYPE
} from "../../utils/constants";
import {
	ShoopingCartModel,
	ShoopingProductModel
} from "../shoopingCart/models";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { config } from "../../config/config";
import { PersonDTO } from "../deepSight/types";

export interface ShoopingCartModelSquare {
    firstName:string,
    lastName:string,
    phoneNumber:string
	shoopingProduct: ShoopingProductModel[];
	comment: string;
	priceToPay: number;
	totalTax: number;
	
  
}


/**
 * order for reader
 *
 * @returns
 */
export const passOrderSquare = (cart: ShoopingCartModelSquare) => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			OrderSquareTypes | SnackBarActionTypes | EmptyOrderSquareTypes
		>
	) => {
		dispatch({
			type: EMPTY_ORDER_SQUARE
		});
		dispatch({
			type: ORDER_SQUARE_READER
		});
		let formData = new FormData();
        formData.append("first_name",cart.firstName)
        formData.append("last_name",cart.lastName)
        formData.append("phone_number",cart.phoneNumber)
		cart.shoopingProduct.forEach(
			(shoopingProduct: ShoopingProductModel, index: number) => {
				formData.append(
					"products[" + index + "][id]",
					shoopingProduct.product.id.toString()
				);
				formData.append(
					"products[" + index + "][quantity]",
					shoopingProduct.quantity.toString()
				);
				shoopingProduct.modifiersSelected.forEach(
					(modifier: number, modifierIndex: number) => {
						formData.append(
							"products[" +
								index +
								"][modifiers][" +
								modifierIndex +
								"]",
							modifier.toString()
						);
					}
				);
			}
		);

		const selectedMode =
			localStorage.getItem(LOCAL_STORAGE_SELECTED_MODE) || "";

		formData.append("kiosk_mode", selectedMode);
		formData.append(
			"mode_id",
			localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || ""
		);
		formData.append("additional_notes", cart.comment);
		formData.append(
			"in",
			selectedMode === KIOSK_MODES.GLOBAL.toString()
				? localStorage.getItem(LOCAL_STORAGE_SELECTED_ORDER_TYPE) || ""
				: "1"
		);

		const detectedPerson: PersonDTO = JSON.parse(
			localStorage.getItem(LOCAL_STORAGE_DEEP_SIGHT) || "{}"
		);

		const age = detectedPerson?.age || null;
		const gender = detectedPerson?.gender
			? getPersonGender(detectedPerson.gender)
			: null;

		if (age) {
			formData.append("age", age.toString());
		}
		if (gender !== -1 && gender !== null) {
			formData.append("gender", gender.toString());
		}

		methodsServices
			.post(ENDPOINTS.ORDER_SQUARE, formData, true)
			.then((res: any) => {
				localStorage.setItem(
					LOCAL_STORAGE_ORDER_ID,
					res.data?.data?.order
				);
				dispatch({
					type: ORDER_SQUARE_READER_SUCCESS,
					payload: {
						clientSecret: res.data?.data?.client_secret,
						order: res.data?.data?.order,
						loading: false,
						paymentIntent: null
					}
				});
					history.push(PATHS.CONFIRM);
			})
			.catch((error: any) => {
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
				dispatch({
					type: ORDER_SQUARE_READER_FAIL
				});
				history.push(PATHS.Home);
			});
	};
};

/**
 * empty reducer
 *
 * @returns
 */
export const emptyOrderSquare = () => {
	return (dispatch: ThunkDispatch<RootState, undefined, EmptyOrderSquareTypes>) => {
		dispatch({
			type: EMPTY_ORDER_SQUARE
		});
	};
};

/**
 * save payment intent
 *
 * @returns
 */
export const savePaymentIntent = (paymentIntent: any) => {
	return (
		dispatch: ThunkDispatch<RootState, undefined, PaymentIntentTypes>
	) => {
		dispatch({
			type: SAVE_PAYMENT_INTENT,
			payload: {
				paymentIntent: paymentIntent
			}
		});
	};
};
