import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        height: '100%',
        paddingTop: 100,
        [theme.breakpoints.down('md')]: {
            paddingRight: 70,
        },
    },
    imgContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: "100%"
    },
    media: {
        height: 210,
        width: 210,
        borderRadius: 10,
        maxWidth: "100%"
    },
    titleContainer: {
        minHeight: 150,
        width: '100%'
    },
    titleAndPrice: {
        height: 60,
        width: '100%',
        display: "flex",
        alignItems: "center",
    },
    title: {
        textAlign: 'left',
        marginLeft: 8,
        height: '100%',
        fontWeight: 'bold',
    },
    price: {
        textAlign: 'right',
        marginRight: 8,
        height: '100%',
        fontWeight: 'bold'
    },
    descrption: {
        textAlign: 'left',
        paddingTop: 10,
        paddingLeft: 8,
    },
    titleMainContainer: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    qunatityContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        height: 60
    },
    qunatityButtonsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10
    },
    detailsContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',

    },
    details: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        height: 500,
    },
    modifierGroups: {
        height: 200,
        paddingLeft: '3%',
        width: '90%'
    },
    btnContainer: {
        height: 100,
        backgroundColor: 'white',
        width: '90%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex'
    },
    footer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: 'column'
    },
    addToOrderBtnContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        flexDirection: 'column',
        marginBottom: 20
    },
    modifierGroup: {
        fontSize: '18px',
        fontWeight: 'bold'
    },
    modContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'center'
    },
    span: {
        fontWeight: 'bold',
        marginBottom: '2%'
    },
    modifiers: {
    },
    modifier: {
        display: 'flex',
        width: '100%'
    },
    modifierGroupContainer: {},
    checkPrice: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        fontSize: '14px'
    },
    modifierName: {
        display: 'flex',
        alignItems: 'center'
    },
    category: {
        fontSize: 18,
        marginLeft: 15
    },
    appBar: {
        left: 0,
        height: 66,
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.up("md")]: {
            width: '70vw',
        },
        "& p": {
            padding: "10px 0px",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
        }
    }
}));
