import React, { useRef } from "react";
import { Typography, Button, Dialog, DialogTitle, Input } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";
import { ChangeEvent, useState } from "react";
import InputMask from "react-input-mask";
import { FOUR_PHONE_NUMBER, LAST_FOUR_OF_PHONE_NUMBER_MASK } from "../../utils/constants";
import Keyboard from "react-simple-keyboard";
import { IfNdigitsEquals } from "../../utils/helpers";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { useDispatch } from "react-redux";
import { getRealPerson } from "../../store/popID/action";
interface PhoneNumberDialog {
	open: boolean;
	setOpenDialog: any;
	pay: boolean;
	handleClose: () => void;
	handleRedirectStrip: () => void;
	detectedPerson: any;
	message: string;
}

const PhoneNumberDialog: React.FC<PhoneNumberDialog> = ({
	handleClose,
	handleRedirectStrip,
	detectedPerson,
	open,
	setOpenDialog,
	pay,
	message
}) => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const [layout, setLayout] = useState("default");
	const [showKeyboard, setShowKeyboard] = useState(false);
	const [phone, setPhone] = useState({ value: "", error: null });
	const ref = useRef<HTMLDivElement | null>(null);
	const keyboard = useRef();
	const dispatch = useDispatch();
	const handleOnPhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPhone({
			...phone,
			value: e.target.value,
			error: null
		});
	};
	const onFocusPhone = (e: any) => {
		//@ts-ignore

		e.target.blur();
		//@ts-ignore
		if (keyboard.current !== undefined) {
			//@ts-ignore
			keyboard.current.setInput(phone.value);
		}
		setShowKeyboard(true);
  };

	const handleShift = () => {
		const newLayoutName = layout === "default" ? "shift" : "default";
		setLayout(newLayoutName);
	};
	const valideNumber = () => {
		detectedPerson.find((element: any) => {
			if (IfNdigitsEquals(element.phone, 4, phone.value)) {
				dispatch(getRealPerson([element]));
				history.push(PATHS.CHECKOUT);
			} else {
				setOpenDialog(true);
			}
		});
	};
	const onChange = (input: any) => {
		let phoneNumbersOnly = input.replace(/\D/g, "");
		phoneNumbersOnly.length <= FOUR_PHONE_NUMBER &&
			setPhone({ ...phone, value: input });
	};
	const onKeyPress = (button: any) => {
		if (button === "{bksp}") {
			setPhone({ ...phone, value: phone.value.slice(0, -1) });
		}
		/**
		 * If you want to handle the shift and caps lock buttons
		 */
		if (button === "{shift}" || button === "{lock}") handleShift();
	};
	return (
		<div style={{ marginBottom: showKeyboard ? "30vh" : 0 }}>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				fullWidth
				style={{ maxWidth: "80% !important" }}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					{message}
				</DialogTitle>
				<div className={classes.containerStyle}>
					<div style={{ marginLeft: "33%" }}>
						<InputMask
							onFocus={onFocusPhone}
							mask={LAST_FOUR_OF_PHONE_NUMBER_MASK}
							value={phone.value}
							onChange={handleOnPhoneChange}
						>
							{() => (
								<Input
									onChange={handleOnPhoneChange}
									className={classes.input}
									disableUnderline={true}
								/>
							)}
						</InputMask>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							marginTop: "5%"
						}}
					>
						<Button
							onClick={valideNumber}
							size="large"
							fullWidth
							className={classes.secondBtnStyle}
						>
							<Typography className={classes.fontSizeStyle}>
								{t("payment.confirm_enter_number")}
							</Typography>
						</Button>
					</div>
				</div>
				<div>
					{showKeyboard && (
						<div ref={ref} className={"keyboardContainer"}>
							<Keyboard
								keyboardRef={(r: any) => (keyboard.current = r)}
								layoutName={layout}
								onChange={onChange}
								onKeyPress={onKeyPress}
							/>
						</div>
					)}
				</div>
			</Dialog>
		</div>
	);
};
export default React.memo(PhoneNumberDialog);
