/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Drawer, Button, Hidden, Fab, Badge } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useState } from "react";
import { ShoppingCart } from "@mui/icons-material";
interface DrawerProps {
	children: React.ReactElement | any;
}

export const FooterComponent = (props: DrawerProps) => {
	const { children } = props;

	const merchantState = useSelector((state: RootState) => state.menu);
	const classes = useStyles({ colors: merchantState.colors });

	const [drawer, setDrawer] = useState(false);
	const ShoppingCartState = useSelector(
		(state: RootState) => state.shoopingCart
	);

	const toggleDrawer = () => {
		setDrawer(!drawer);
	};
	const handleDrawerClose = () => {
		setDrawer(false);
	};

	const handleResize = () => {
		if (window.innerWidth >= 960) {
			handleDrawerClose();
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
        <div>
			<Hidden mdDown implementation="css">
				<Drawer
					variant="permanent"
					anchor="right"
					className={classes.drawer}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					{children}
				</Drawer>
			</Hidden>
			<Hidden mdUp implementation="css">
				<Drawer
					onClose={handleDrawerClose}
					anchor="right"
					className={classes.drawer}
					classes={{
						paper: classes.drawerPaper,
					}}
					open={drawer}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{children}
				</Drawer>
			</Hidden>
			<Hidden mdUp implementation="css">
				<Fab
					aria-label="cart"
					className={classes.fab}
					onClick={toggleDrawer}
					component={Button}
				>
					<Badge
						badgeContent={ShoppingCartState.shoopingProduct.length}
						className={classes.badge}
					>
						<ShoppingCart />
					</Badge>
				</Fab>
			</Hidden>
		</div>
    );
};
export default FooterComponent;
