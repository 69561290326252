import { ThunkDispatch } from "redux-thunk";
import {
  CONFIRM_OR_CANCEL,
  CONFIRM_OR_CANCEL_ERROR,
  CONFIRM_OR_CANCEL_SUCCESS,
  ConfirmCancelActions,
} from "./types";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import { getErrorMessage } from "../../utils/helpers";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import {
  LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
  LOCAL_STORAGE_SHOOPING_CARTS,
  LOCAL_STORAGE_WAITING_TIME,
} from "../../utils/constants";
import { EMPTY_ORDER, EmptyOrderTypes } from "../order/types";
import { ShoopingCartActions } from "../shoopingCart/models";
import { REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART } from "../shoopingCart/types";
import { RootState } from "../rootReducer";
import { config } from "../../config/config";

export const cancel = () => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      | ConfirmCancelActions
      | SnackBarActionTypes
      | EmptyOrderTypes
      | ShoopingCartActions
    >,
    getState: any
  ) => {
    dispatch({
      type: CONFIRM_OR_CANCEL,
    });
    const orderId = getState().order.order;
    const paymentIntentId = config.CARTE_ACTIVE
      ? getState().order.paymentIntent.id
      : null;
    const modeId =
      localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || "";
    const data = {
      mode_id: parseInt(modeId),
      payment_id: paymentIntentId,
    };
    methodsServices
      .post(ENDPOINTS.CANCEL.replace("orderId", orderId), data, true)
      .then(async (res) => {
        dispatch({
          type: CONFIRM_OR_CANCEL_SUCCESS,
        });
        dispatch({
          type: EMPTY_ORDER,
        });
        dispatch({
          type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
        });
        localStorage.removeItem(LOCAL_STORAGE_SHOOPING_CARTS);
        history.push(PATHS.Home);
      })
      .catch((error) => {
        dispatch({
          type: CONFIRM_OR_CANCEL_ERROR,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: "error",
          },
        });
		history.push(PATHS.Home);
      });
  };
};

export const confirm = (transactionId: any, orderWithStripOrPopId: number) => {
	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			ConfirmCancelActions | SnackBarActionTypes | ShoopingCartActions
		>,
		getState: any
	) => {
		dispatch({
			type: CONFIRM_OR_CANCEL
		});
		const orderId = getState().order.order;
		const paymentIntentId = config.CARTE_ACTIVE
			? getState().order?.paymentIntent?.id
			: null;
		const modeId =
			localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED) || "";
		const data = {
			mode_id: parseInt(modeId),
			payment_id:
				orderWithStripOrPopId === 1 ? transactionId : paymentIntentId
		};
		methodsServices
			.post(ENDPOINTS.CONFIRM.replace("orderId", orderId), data, true)
			.then(async res => {
				dispatch({
					type: CONFIRM_OR_CANCEL_SUCCESS
				});
				localStorage.setItem(
					LOCAL_STORAGE_WAITING_TIME,
					res?.data.data.estimated_time
				);
				dispatch({
					type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART
				});
				localStorage.removeItem(LOCAL_STORAGE_SHOOPING_CARTS);
				history.push(PATHS.ORDER_SUBMITTED);
			})
			.catch(error => {
				dispatch({
					type: CONFIRM_OR_CANCEL_ERROR
				});
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
				history.push(PATHS.Home);
			});
	};
};
