import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cartImage: (props: ColorProps) => ({
			width: "35%",
			minWidth: 100,
			opacity: 0.2,
			paddingBottom: 20,
			"& svg": {
				width: "100%",
				fill: props.colors[1]
			}
		}),
		title: (props: ColorProps) => ({
			fontSize: 37,
			fontFamily: "Avenir",
			textTransform: "uppercase",
			color: props.colors[1]
		}),
		createIconStyle: (props: ColorProps) => ({
			color: props.colors[1],
			opacity: 0.7
		}),
		gridContainerStyle: { marginTop: "50%" },
		gridStyle: (props: ColorProps) => ({
			display: "flex",
			justifyContent: "flex-end"
		}),
		productTitle: (props: ColorProps) => ({
			marginTop: 10,
			marginLeft: 8,
			fontFamily: "Avenir",
			color: props.colors[1]
		}),
		description: (props: ColorProps) => ({
			textAlign: "center",
			color: props.colors[1],
			opacity: 0.2
		}),
		addtionalStyle: (props: ColorProps) => ({
			marginTop: 8,
			textAlign: "start",
			color: props.colors[1],
			opacity: 0.7
		}),
		addtionalCommentStyle: (props: ColorProps) => ({
			marginTop: 2,
			marginLeft: 8,
			textAlign: "start",
			color: props.colors[1],
			opacity: 0.6,
			fontSize: 13
		}),
		addtionalPriceStyle: (props: ColorProps) => ({
			marginTop: 8,
			textAlign: "end",
			color: props.colors[1],
			opacity: 0.7
		}),
		cartContainer: {
			padding: "15% 20px",
			minHeight: "100vh",
			background: "#eeeeee",
			textAlign: "center",
			[theme.breakpoints.down('md')]: {
				padding: "20px"
			}
		},
		itemContainer: {
			marginBottom: 30,
			height: "calc(100vh - 450px)",
			overflowY: "auto",
			width: "27vw",
			[theme.breakpoints.down('md')]: { width: "70vw" },
			"&::-webkit-scrollbar": {
				width: 5
			},
			"&::-webkit-scrollbar-track": {
				boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
				webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundColor: "rgba(0,0,0,.1)",
				borderRadius: 20
			}
		},
		button: (props: ColorProps) => ({
			marginTop: 10,
			background: props.colors[1],
			color: props.colors[0],
			width: "80%",
			padding: "10px",
			"&.MuiButton-root.Mui-disabled": {
				color: props.colors[0],
				opacity: 0.2
			},
			"&:hover": {
				background: props.colors[0],
				color: props.colors[1],
				borderColor: props.colors[1]
			},
			fontWeight: "bold"
		}),
		editButtonStyle: (props: ColorProps) => ({
			opacity: 1
		}),
		tipStyle: (props: ColorProps) => ({
			marginTop: 8,
			textAlign: "end",
			color: props.colors[1],
			opacity: 1
		}),
		tipTextStyle: (props: ColorProps) => ({
			opacity: 0.7
		})
	})
);
