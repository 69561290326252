import * as React from 'react';
import '../../assets/sass/radioButton.scss';

interface RadioButtonProps {
    label: string;
    checked: boolean;
    disabled: boolean;
    value: number;
    onChange: () => void;
}

export const RadioButton = (props: RadioButtonProps) => {
    const {label, checked, disabled, value, onChange} = props;
    return (
        <div className="container">
            <input type="radio" value={value}  disabled={disabled} checked={checked}
                   onChange={onChange}/>
            <span className="label">{label}</span>
        </div>
    )
}
export default RadioButton;