import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./style";

interface ConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  message: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  handleClose,
  open ,
  message,

}) => {
  const { t } = useTranslation();
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors });
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'
        fullWidth
      >
        <DialogTitle
          className={classes.dialogTitleStyle}
          id='form-dialog-title'
        >
          {message}
        </DialogTitle>
        <div
         className={classes.containerStyle}
        >
          <div>
            <Button
              onClick={handleClose}
              size='large'
              fullWidth
              className={classes.secondBtnStyle}
            >
              <Typography className={classes.fontSizeStyle}>
                {t("common.ok")}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default React.memo(ConfirmDialog);
