import makeStyles from '@mui/styles/makeStyles';
import {ColorProps} from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
    background: (props: ColorProps) => ({
        width: "100vw",
        minHeight: "100vh",
        backgroundColor: props.colors[1],
        paddingTop: 100,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }),
    textContainer: {
        marginTop: '8%',
        textAlign: 'center',
        width: '60%'
    },
    text: (props: ColorProps) => ({
        color: props.colors[0],
        fontWeight: 400,
        lineHeight: '2rem'
    }),
    btn: (props: ColorProps) => ({
        color: props.colors[1],
        borderColor: props.colors[1],
        height: 100,
        width: '49.9%',
        borderRadius: 0,
        fontSize: 25,
        fontWeight: 'bold',
        borderLeftWidth: 3,
    }),
    btnsContainer: (props: ColorProps) => ({
        position: 'fixed',
        bottom: '0'
    })
});
