import { ShoopingCartActions, ShoopingCartModel } from "./models";
import {
  ADD_COMMENT_SHOP,
  ADD_PRODUCT_TO_SHOPPING_CART,
  ADD_TIP_SHOP,
  REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
  REMOVE_PRODUCT_FROM_SHOOPING_CART,
} from "./types";
import produce from "immer";
import {
  DEFAULT_TIP_PERCENTAGE,
  LOCAL_STORAGE_SHOOPING_CARTS,
  SQUARE_UP_STATUS,
} from "../../utils/constants";
import roundHalfEven from "round-half-even";

const squareStatus = parseInt(localStorage.getItem(SQUARE_UP_STATUS) as string);
const shoopingCart = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_SHOOPING_CARTS) ||
    '{ "shoopingProduct": [], "comment": "", "priceToPay":0, "totalTax": 0, "tip":20, "tipToPay":0 }'
);
if (shoopingCart.tip === undefined) {
  shoopingCart.tip = !squareStatus ? DEFAULT_TIP_PERCENTAGE : 0;
  if (shoopingCart.priceToPay) {
    shoopingCart.tipToPay = !squareStatus
      ? (shoopingCart.priceToPay / 100) * shoopingCart.tip
      : 0;
  }
  localStorage.setItem(
    LOCAL_STORAGE_SHOOPING_CARTS,
    JSON.stringify(shoopingCart)
  );
}
const INITIAL_STATE: ShoopingCartModel = shoopingCart;
function precisionRound(num: number, precision: number) {
  return Number(Math.round(Number(num + "e+" + precision)) + "e-" + precision);
}
export const shoopingCartReducer = (
  state = INITIAL_STATE,
  action: ShoopingCartActions
): ShoopingCartModel => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ADD_PRODUCT_TO_SHOPPING_CART:
        draft.shoopingProduct.push(action.payload);
        draft.priceToPay += precisionRound(action.payload.priceWithTax, 2);
        let tax =
			Math.round(
				(action.payload.priceWithTax - action.payload.price) *
					Math.pow(10, 6)
			) / Math.pow(10, 6);
        draft.totalTax += roundHalfEven(tax, 2);
        if (!state.tip) {
          draft.tip = !squareStatus ? DEFAULT_TIP_PERCENTAGE : 0;
          draft.tipToPay = !squareStatus
            ? parseFloat(
                ((draft.priceToPay / 100) * DEFAULT_TIP_PERCENTAGE).toFixed(2)
              )
            : 0;
        } else {
          draft.tipToPay = !squareStatus
            ? parseFloat(((draft.priceToPay / 100) * state.tip).toFixed(2))
            : 0;
        }
        break;
      case REMOVE_PRODUCT_FROM_SHOOPING_CART:
        const product = state.shoopingProduct.find(
          (_e, index) => index === action.payload
        );
        const newShoopingProduct = state.shoopingProduct.filter(
          (_e, index) => index !== action.payload
        );
        draft.shoopingProduct = newShoopingProduct;
        draft.comment = newShoopingProduct.length === 0 ? "" : state.comment;
        draft.priceToPay -= product?.priceWithTax ?? 0;
        let taxRemove =
          product?.priceWithTax && product?.price
            ? Math.round(
                (product.priceWithTax - product.price) * Math.pow(10, 3)
              ) / Math.pow(10, 3)
            : 0;
        draft.totalTax -= precisionRound(taxRemove, 2);
        draft.tipToPay = !squareStatus
          ? parseFloat(((draft.priceToPay / 100) * state.tip).toFixed(2))
          : 0;
        if (newShoopingProduct?.length <= 0) {
          draft.shoopingProduct = [];
          draft.priceToPay = 0;
          draft.totalTax = 0;
          draft.comment = "";
          draft.tip = !squareStatus ? DEFAULT_TIP_PERCENTAGE : 0;
          draft.tipToPay = 0;
        }
        break;
      case REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART:
        draft.shoopingProduct = [];
        draft.priceToPay = 0;
        draft.totalTax = 0;
        draft.comment = "";
        draft.tip = !squareStatus ? DEFAULT_TIP_PERCENTAGE : 0;
        draft.tipToPay = 0;
        break;
      case ADD_COMMENT_SHOP:
        draft.comment = action.payload;
        break;
      case ADD_TIP_SHOP:
        draft.tip = !squareStatus ? action.payload : 0;
        draft.tipToPay = !squareStatus
          ? (state.priceToPay / 100) * action.payload
          : 0;
        break;
    }
  });
};
