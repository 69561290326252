import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { ProductInterface, CategoryInterface } from "../../store/menu/types";
import { config } from "../../config/config";
import placeHolder from "../../assets/img/placeholder.png";
import { useStyles } from "./styles";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LOCAL_STORAGE_PRODUCT,
  LOCAL_STORAGE_CATEGORY,
  SEPARATED_MIN_MAX_PRODUCT,
} from "../../utils/constants";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";
import { getPriceFormat } from "../../utils/helpers";
import CardMedia from "../../components/CardMedia";

interface ProductProps {
  product: ProductInterface;
  category: CategoryInterface;
}

export const Product = (props: ProductProps) => {
  const { product, category } = props;
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;

  const classes = useStyles({ colors });
	const [variation, setVariation] = React.useState<boolean>(false);
	React.useEffect(() => {
		const variationProduct = product?.groups.filter(
			(grp: any) => grp.variation === 1
		);
		variationProduct.length !== 0 && setVariation(true);
	}, []);
  const showDetails = () => {
    console.log(product.image);
    localStorage.setItem(LOCAL_STORAGE_PRODUCT, JSON.stringify(product));
    localStorage.setItem(LOCAL_STORAGE_CATEGORY, JSON.stringify(category));
    history.push(PATHS.PRODUCT);
  };
  return (
		<Grid
			className={classes.product}
			container
			direction="column"
			alignItems="flex-end"
		>
			<Card className={classes.card} onClick={showDetails}>
				<CardActionArea>
					<CardMedia
						className={classes.media}
						image={
							product.image && !product.image.startsWith("https")
								? config.BASE_URL_API + product.image
								: product.image &&
								  product.image.startsWith("https")
								? product.image
								: placeHolder
						}
						title={product.title}
					/>
					<CardContent>
						<Grid
							container
							justifyContent="space-between"
							wrap="nowrap"
							className={classes.description}
						>
							<Typography className={classes.typography}>
								{product.title}
							</Typography>
							<Typography
								className={`${classes.typography} ${classes.price}`}
							>
								{variation ? (
									<>
										$
										{getPriceFormat(
											product.min_price / 100
										) +
											SEPARATED_MIN_MAX_PRODUCT +
											getPriceFormat(
												product.max_price / 100
											)}
									</>
								) : product.price ? (
									<> ${getPriceFormat(product.price / 100)}</>
								) : (
									"00.00"
								)}
							</Typography>
						</Grid>
					</CardContent>
				</CardActionArea>
			</Card>
			<Button
				onClick={showDetails}
				className={classes.button}
				aria-label="Product"
			>
				{t("order.add")}
			</Button>
		</Grid>
  );
};
export default Product;
