import axios from "axios";
import { config } from "../../config/config";
import {
	LOCAL_STORAGE_TOKEN,
	UNAUTHORIZED_STATUS,
	UNAUTHORIZED_TOKEN
} from "../../utils/constants";
import { history } from "../../routes/history";
import { ENDPOINTS } from "../../utils/endpoints";
import { store } from "../../index";
import { showSnackBar } from "../snackBar/actions";
import { errors } from "../../locales/en/errors";

export const methodsServices = {
	post,
	get,
	deleteDetail
};

const baseUrl = `${config.BASE_URL_API}api/merchant/`;
const baseUrlWithoutMarchent = `${config.BASE_URL_API}api/`;

/**
 * get method
 * @param apiEndpoint
 * @param headers
 * @returns
 */
async function get(
	apiEndpoint: string,
	headers: boolean = false,
	absoluteUrl: string = ""
): Promise<any> {
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

	let requestHeader = {};
	if (headers) {
		requestHeader = {
			Authorization: `Bearer ${token}`
		};
	}

	const url = absoluteUrl ? absoluteUrl : baseUrl + apiEndpoint;

	return await axios
		.get(url, {
			headers: requestHeader
		})
		.then(response => {
			return Promise.resolve(response);
		})
		.catch((err: any) => {
			const errorMsg = handelError(err);
			return Promise.reject(errorMsg);
		});
}

/**
 * post method
 *  @param payload: any
 *  @param headers:boolean
 * @param apiEndpoint:string
 */
async function post(
	apiEndpoint: string,
	payload: any,
	headers: boolean = false,
	isMarchent = false
) {
	let requestHeader = {};
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

	if (headers) {
		requestHeader = {
			Authorization: `Bearer ${token}`
		};
	}
	let url = "";
	if (!isMarchent) {
		url = baseUrl;
	} else {
		url = baseUrlWithoutMarchent;
	}
	return await axios
		.post(url + apiEndpoint, payload, {
			headers: requestHeader
		})
		.then((response: any) => {
			return response;
		})
		.catch((err: any) => {
			const errorMsg = handelError(err);
			return Promise.reject(errorMsg);
		});
}

/**
 * delete method
 *  @param payload: any
 *  @param headers:boolean
 * @param apiEndpoint:string
 */
async function deleteDetail(apiEndpoint: string, headers: boolean = false) {
	let authStr = "";
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);

	if (headers) {
		authStr = `Bearer ${token}`; //Prepare the authorization with the token
	}
	return await axios
		.delete(baseUrl + apiEndpoint, {
			headers: { Authorization: authStr }
		})
		.then((response: any) => {
			return response;
		})
		.catch((err: any) => {
			const errorMsg = handelError(err);
			return Promise.reject(errorMsg);
		});
}

function handelError(error: any) {
	if (
		error?.response?.status === UNAUTHORIZED_STATUS ||
		error?.response?.status === UNAUTHORIZED_TOKEN
	) {
		localStorage.clear();
		if (baseUrl + ENDPOINTS.LOGIN == error.response.config.url) {
			return "common.invalid_credentials";
		}
		history.push("/login");
		if (error?.response?.status === UNAUTHORIZED_TOKEN) {
				store.dispatch(showSnackBar(errors.unauthorized, "error"));

		}
		if (error?.response?.status === UNAUTHORIZED_STATUS) {
				store.dispatch(showSnackBar(errors.locked, "error"));
		}

		return "common.unauthorized";
	}
	return error?.response;
}
