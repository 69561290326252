export const CONFIRM_OR_CANCEL = 'CONFIRM_OR_CANCEL';
export const CONFIRM_OR_CANCEL_ERROR = 'CONFIRM_OR_CANCEL_ERROR';
export const CONFIRM_OR_CANCEL_SUCCESS = 'CONFIRM_OR_CANCEL_SUCCESS';

// REDUCER INTERFACE
export interface ConfirmCancelState {
    loading: boolean;
}

// CONFIRM OR CANCEL ACTION
interface ConfirmCancelAction {
    type: typeof CONFIRM_OR_CANCEL | typeof CONFIRM_OR_CANCEL_ERROR | typeof CONFIRM_OR_CANCEL_SUCCESS;
}

export type ConfirmCancelActions = ConfirmCancelAction;
