import { kioskChoice } from "./kioskChoice";
import { login } from "./login";
import { validations } from "./validations";
import { errors } from "./errors";
import { error } from "./error";
import { common } from "./common";
import { order } from "./order";
import { productDetail } from "./productDetails";
import { reader } from "./reader";
import { checkout } from "./checkout";
import { confirm } from "./confirm";
import { orderSubmitted } from "./orderSubmitted";
import { payment } from "./payment";
import { resetPassword } from "./resetPassword";

const fr = {
    login,
    validations,
    errors,
    error,
    common,
    kioskChoice,
    order,
    productDetail,
    reader,
    checkout,
    confirm,
    orderSubmitted,
    payment,
    resetPassword
};

export default fr;