import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";
import { Grid, Button } from "@mui/material";
import { PowredByTyme, Spinner } from "../../components";
import { useTranslation } from "react-i18next";
import {
  NUMBER_OF_SECONDS_OF_COUNTER,
  SQUARE_UP_STATUS,
} from "../../utils/constants";
import { confirm, cancel } from "../../store/confirm/actions";
import withStyles from '@mui/styles/withStyles';
import { emptyOrder } from "../../store/order/actions";
import { history } from "../../routes/history";
import { config } from "../../config/config";
import { PATHS } from "../../routes/paths";
import { cancelSquare, confirmSquare } from "../../store/confirmSquare/actions";

interface ConfirmProps {}

export const Confirm = (props: ConfirmProps) => {
  const ShoppingCartState = useSelector(
    (state: RootState) => state.shoopingCart
  );
  const squareStatus = parseInt(localStorage.getItem(SQUARE_UP_STATUS) as string);
  const menuState = useSelector((state: RootState) => state.menu);
  const colors = menuState?.colors;
  const classes = useStyles({ colors: colors });
  const [timer, setTimer] = React.useState(NUMBER_OF_SECONDS_OF_COUNTER);
  const [stopTimer, setStopTimer] = React.useState(false);
    const transactionId = useSelector(
		(state: RootState) => state.popId?.successPayment
	);
	const orderWithStripOrPopId = useSelector(
		(state: RootState) => state.order.popid
	);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer === 0) {
        if (squareStatus) {
          dispatch(confirmSquare());
        } else if (!squareStatus) {
          dispatch(confirm(transactionId, orderWithStripOrPopId));
        }
        clearInterval(intervalId);
      } else if (!stopTimer && timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const handleGoBack = (event: any) => {
      window.history.pushState(null, document.title, window.location.href);
    };
    if (timer === 0) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", handleGoBack);
    }
    return () => window.removeEventListener("popstate", handleGoBack);
  }, [timer]);
  const confirmState = useSelector((state: RootState) => state.confirm);
  const confirmStateSquare = useSelector(
    (state: RootState) => state.confirmSquare
  );
  const { loading: normalLoading } = confirmState;
  const { loading: squareLoading } = confirmStateSquare;
  const cardReaderState = useSelector((state: RootState) => state.cardreader);

  React.useEffect(() => {
    if (!squareStatus && normalLoading && config.CARTE_ACTIVE) {
      cancelPendingPayment();
    }
  }, [normalLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onConfirmClick = () => {
    setStopTimer(true);
    if (squareStatus) {
      dispatch(confirmSquare());
    } else {
      dispatch(confirm(transactionId, orderWithStripOrPopId));
    }
  };

  const onCancelClick = () => {
    setStopTimer(true);
    if (squareStatus) {
      dispatch(cancelSquare());
    } else {
      dispatch(cancel());
    }
  };

  const cancelPendingPayment = async () => {
    console.log("cancel collect payment...");
    dispatch(emptyOrder());
    //await cardReaderState.terminal.clearReaderDisplay();
  };

  const StyledButton = withStyles({
    root: {
      backgroundColor: colors[0],
      color: colors[1],
      "&:hover": {
        backgroundColor: colors[1],
        color: colors[0],
      },
    },
  })(Button);

  React.useEffect(() => {
    if (!menuState?.merchant || !menuState?.merchant?.id) {
      history.push(PATHS.Home);
    }
  }, [menuState.merchant]);


  return (
    <div className={classes.background}>
      <Grid container justifyContent="center">
        <h1 className={classes.text}>{timer}</h1>
      </Grid>
      <Grid container justifyContent="center" className={classes.textContainer}>
        <h4 className={classes.text}>
          {t("confirm.cancel_order").replace(
            "%number_of_seconds",
            timer.toString()
          )}
        </h4>
      </Grid>
      <Grid className={classes.btnsContainer} container direction="column">
        <Grid container justifyContent="space-between" style={{ marginBottom: 30 }}>
          <PowredByTyme />
        </Grid>
        <Grid container justifyContent="space-between">
          <StyledButton
            disabled={timer === 0}
            onClick={onCancelClick}
            variant="contained"
            classes={{ root: classes.btn }}
          >
            {t("confirm.cancel")}
          </StyledButton>
          <StyledButton
		    disabled={timer === 0}
            onClick={onConfirmClick}
            variant="contained"
            classes={{ root: classes.btn }}
          >
            {t("confirm.confirm")}
          </StyledButton>
        </Grid>
       <Spinner loading= {squareStatus ?squareLoading:normalLoading} />
      </Grid>
     
    </div>
  );
};
export default Confirm;
