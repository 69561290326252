import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";
import { passOrder, stripeOrPopId } from "../../store/order/actions";

export const PaymentPage = () => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const [canCancel, setCanCancel] = useState(true);
	const ShoppingCartState = useSelector(
		(state: RootState) => state.shoopingCart
	);
	const dispatch = useDispatch();
	const totalValue = () => {
		return ShoppingCartState.shoopingProduct.reduce(
			(somme, current) => somme + current.price,
			0
		);
	};
	const goBack = () => {
		if (canCancel) {
			history.push(PATHS.Home);
		}
	};
	const passPayment = () => {
		const popIdStatus: number = 0;
		dispatch(stripeOrPopId(popIdStatus));
		dispatch(passOrder(shoopingCart, popIdStatus));
		history.push(PATHS.PAYMENT);
	};
	const paymentWithPopId = () => {
		const popIdStatus: any = 1;
		dispatch(stripeOrPopId(popIdStatus));
		dispatch(passOrder(shoopingCart, popIdStatus));
		history.push(PATHS.POP_ID_MODE_PAYMENT);
	};
	return (
		<Grid container direction="column" className={classes.container}>
			<Grid container justifyContent="flex-start">
				<Button
					className={classes.button}
					onClick={() => goBack()}
					disabled={!canCancel}
				>
					{t("common.back")}
				</Button>
			</Grid>
			<Grid container direction="column" className={classes.content}>
				<Typography className={classes.loaderText}>
					{t("payment.total")}
				</Typography>
				<Typography variant="h3" className={classes.price}>
					$
					{(
						totalValue() +
						ShoppingCartState.totalTax +
						ShoppingCartState.tipToPay
					).toFixed(2) || 0}
				</Typography>
				<Typography style={{ color: colors[0], marginBottom: "20px" }}>
					{t("payment.choose_mode_payment_text")}
				</Typography>
				<Grid
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						columnGap: "20px"
					}}
				>
					<Button
						onClick={passPayment}
						style={{
							backgroundColor: colors[0],
							color: colors[1],
							height: 50,
							width: 200
						}}
					>
						{t("payment.pay_with_strip")}
					</Button>
					<Button
						onClick={paymentWithPopId}
						style={{
							backgroundColor: colors[0],
							color: colors[1],
							height: 50,
							width: 200
						}}
					>
						{t("payment.pay_with_popID")}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default PaymentPage;
