import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: (props: ColorProps) => ({
            color: props.colors[1],
            '&$checked': {
                color: 'red'
            }
        }),
        checked: {
            color: 'red'
        }
    })
);
