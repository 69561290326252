import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			width: "100%",
			background: "transparent",
			height: "calc(100% - 62px)",
		},
		media: {
			height: 200,
		},
		typography: (props: ColorProps) => ({
			color: props.colors[1],
			padding: 5,
			textAlign: "left",
		}),
		price: {
			fontWeight: "bold",
			textAlign: "right",
		},
		product: {
			width: 300,
			minWidth: 300,
			maxWidth: "100%",
			margin: "2.5%",
			[theme.breakpoints.down('sm')]: {
				minWidth: "90%",
			},
		},
		button: (props: ColorProps) => ({
			background: props.colors[1],
			color: props.colors[0],
			marginTop: 15,
			padding: "10px 20px",
			borderColor: props.colors[0],
			border: "1px solid",
			"&:hover": {
				background: props.colors[0],
				color: props.colors[1],
				borderColor: props.colors[1],
			},
		}),
		description: {
			justifyContent: "space-between",
		},
	})
);
