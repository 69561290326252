export const reader = {
    searching: 'Finding available card readers now...',
    available: "Available card readers :",
    connect: "Connect",
    connect_error: "Request failed, please try again later",
    connect_success: "Reader connected successfully",
    discover_error: "An error occurred while searching for readers",
    error: "An error occurred while searching for readers",
    disconnected: "Reader unexpectedly disconnected",
    empty: "No readers  found",
    offline: "It seems that the reader is offline! Please check your internet connection!"
}