/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import "../../assets/sass/common.scss";
import { CardMedia as CardMediaMui } from "@mui/material";
import placeHolderImg from "../../assets/img/placeholder.png";
import { config } from "../../config/config";

interface ProductDetailsProps {
	className?: any;
	image?: string;
	title?: string;
	placeHolder?: any;
	style?: React.CSSProperties;
}

export const CardMedia = (props: ProductDetailsProps) => {
	const {
		className,
		image,
		title,
		placeHolder = placeHolderImg,
		style = {}
	} = props;
	const [imageError, setImageError] = useState(false);

	return (
		<CardMediaMui
			component="img"
			className={className}
			image={image && !imageError ? image : placeHolder}
			onError={() => setImageError(true)}
			title={title}
			style={style}
		/>
	);
};
export default CardMedia;
