import React, { FunctionComponent } from "react";

export interface MainProps {}

export const CartIcon: FunctionComponent<MainProps> = () => {
	return (
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
		>
			<g>
				<g>
					<circle cx="181.333" cy="437.333" r="53.333" />
				</g>
			</g>
			<g>
				<g>
					<path
						d="M509.867,89.6c-2.133-2.133-4.267-4.267-8.533-4.267H96L85.333,29.867c-2.133-4.267-6.4-8.533-10.667-8.533h-64
			C4.267,21.333,0,25.6,0,32s4.267,10.667,10.667,10.667h55.467l51.2,260.267c8.533,34.133,38.4,59.733,74.667,59.733h245.333
			c6.4,0,10.667-4.267,10.667-10.667c0-6.4-4.267-10.667-10.667-10.667H192c-17.067,0-34.133-8.533-42.667-23.467L460.8,275.2
			c4.267,0,8.533-4.267,8.533-8.533L512,96C512,96,512,91.733,509.867,89.6z"
					/>
				</g>
			</g>
			<g>
				<g>
					<circle cx="394.667" cy="437.333" r="53.333" />
				</g>
			</g>
		</svg>
	);
};

export default React.memo(CartIcon);
