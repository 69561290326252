import { ThunkDispatch } from "redux-thunk";
import { AuthActionTypes, Login, Logout, ResetPassword } from "./models";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import {
  LOGIN_ATTEMPT,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_ATTEMPT,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_ATTEMPT,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from "./types";
import { DISCONNECT_READER } from "../../store/cardReader/types";
import { DisconnectReaderTypes } from "../cardReader/types";
import {
  KIOSK_MODES,
  LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
  LOCAL_STORAGE_MODE,
  LOCAL_STORAGE_TOKEN,
  MODEL_NOT_FOUND,
  LOCAL_STORAGE_LOCATION_ID,
  SQUARE_UP_STATUS,
  SIGHTCORP_STATUS,
  POP_ID_STATUS
} from "../../utils/constants";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { getErrorMessage } from "../../utils/helpers";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { strings } from "../../locales";
import { RESET_MENU, ResetMenuTypes } from "../menu/types";
import { EMPTY_ORDER, EmptyOrderTypes } from "../order/types";
import { REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART } from "../shoopingCart/types";
import { ShoopingCartActions } from "../shoopingCart/models";
import { RootState } from "../rootReducer";

export const login = (data: Login) => {

	return (
		dispatch: ThunkDispatch<
			RootState,
			undefined,
			AuthActionTypes | SnackBarActionTypes
		>
	) => {
		dispatch({
			type: LOGIN_ATTEMPT
		});
		methodsServices
			.post(ENDPOINTS.LOGIN, data, false)
			.then((res: any) => {
				if (res.data.kiosk_mode !== KIOSK_MODES.INACTIVE) {
					localStorage.setItem(LOCAL_STORAGE_TOKEN, res?.data.data);
					localStorage.setItem(
						LOCAL_STORAGE_MODE,
						res?.data.kiosk_mode
					);
					localStorage.setItem(
						LOCAL_STORAGE_LOCATION_ID,
						res?.data.location_id
					);
					localStorage.setItem(
						SQUARE_UP_STATUS,
						res?.data.squareup_status
					  );
					localStorage.setItem(
						SIGHTCORP_STATUS,
						res?.data.sightcorp_status
          );
          localStorage.setItem(POP_ID_STATUS, res?.data.popid_status);
					dispatch({
						type: LOGIN_SUCCESS
					});
					history.replace(PATHS.KIOSK_CHOICE_PATH);
				} else {
					dispatch({
						type: LOGIN_ERROR
					});
					dispatch({
						type: SHOW_SNACK_BAR,
						payload: {
							message: strings("common.kiosk_mode_disabled"),
							severity: "error"
						}
					});
				}
			})
			.catch((error: any) => {
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: getErrorMessage(error),
						severity: "error"
					}
				});
				dispatch({
					type: LOGIN_ERROR
				});
			});
	};
};

export const logout = (data: Logout) => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      | AuthActionTypes
      | SnackBarActionTypes
      | DisconnectReaderTypes
      | ResetMenuTypes
      | EmptyOrderTypes
      | ShoopingCartActions
    >
  ) => {
    dispatch({
      type: LOGOUT_ATTEMPT,
    });
    let mode_id = localStorage.getItem(LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED);
    const params = {
      email: data.email,
      password: data.password,
      mode_id: mode_id,
    };
    methodsServices
      .post(ENDPOINTS.LOGOUT, params, true)
      .then((res: any) => {
        dispatch({
          type: LOGOUT_SUCCESS,
        });
        dispatch({
          type: DISCONNECT_READER,
        });
        dispatch({
          type: RESET_MENU,
        });
        dispatch({
          type: EMPTY_ORDER,
        });
        dispatch({
          type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: { message: res.data.message, severity: "success" },
        });
        localStorage.clear();
        history.push(PATHS.LOGIN);
      })
      .catch((error: any) => {
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: {
            message: getErrorMessage(error),
            severity: "error",
          },
        });
        dispatch({
          type: LOGOUT_ERROR,
        });
      });
  };
};

export const resetPassword = (data: ResetPassword) => {
  return (
    dispatch: ThunkDispatch<
      RootState,
      undefined,
      AuthActionTypes | SnackBarActionTypes
    >
  ) => {
    dispatch({
      type: RESET_PASSWORD_ATTEMPT,
    });
    const params = {
      bb_email: data.email,
    };
    methodsServices
      .post(ENDPOINTS.RESET_PASSWORD, params, false)
      .then((res: any) => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
        });
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: { message: res.data.message, severity: "success" },
        });
      })
      .catch((error: any) => {
        var message = "";
        if (error?.status == MODEL_NOT_FOUND) {
          message = strings("errors.merchant_not_found");
        } else {
          message = getErrorMessage(error);
        }
        dispatch({
          type: SHOW_SNACK_BAR,
          payload: { message: message, severity: "error" },
        });
        dispatch({
          type: RESET_PASSWORD_ERROR,
        });
      });
  };
};
