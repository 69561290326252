import {
	DETECTED_REAL_PERSON,
	FAILED_PAYMENT_WITH_POP_ID,
	NO_REAL_PERSON_DETECTED,
	otherObjectDetectedState,
	PaymentState,
	PERSON_NOT_RECOGNIZED,
	PopIdPaymentActionTypes,
	PopIdSearchRealPersonActionTypes,
	RecognizedPersonState,
	SUCCESS_PAYMENT_WITH_POP_ID
} from "./types";

export const getRealPerson = (
	realPerson: any
): PopIdSearchRealPersonActionTypes => ({
	type: DETECTED_REAL_PERSON,
	payload: realPerson
});

export const getNotRecognizedPerson = (
	notRecognizedPerson: RecognizedPersonState
): PopIdSearchRealPersonActionTypes => ({
	type: PERSON_NOT_RECOGNIZED,
	payload: notRecognizedPerson
});

export const getOtherObject = (
	object: otherObjectDetectedState
): PopIdSearchRealPersonActionTypes => ({
	type: NO_REAL_PERSON_DETECTED,
	payload: object
});

/**
 * success payment
 *
 */
export const successPayment = (
	payment: PaymentState
): PopIdPaymentActionTypes => ({
	type: SUCCESS_PAYMENT_WITH_POP_ID,
	payload: payment
});

/**
 * failed payment
 *
 */
export const failedPayment = (payment: {}): PopIdPaymentActionTypes => ({
	type: FAILED_PAYMENT_WITH_POP_ID,
	payload: payment
});
