import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales";
import { Provider } from "react-redux";
import { rootReducer } from "./store/rootReducer";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { LOCAL_STORAGE_SHOOPING_CARTS } from "./utils/constants";

//Initialize the shooping cart state from localStorage
const shoopingCart = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_SHOOPING_CARTS) || '{ "shoopingProduct": [], "comment": "", "priceToPay":0, "totalTax": 0 }'
);
console.log('ShoopingCart', shoopingCart)
const shoopingCartId = shoopingCart.shoopingProduct.map((e: any) => e.product.id)
console.log('shoopingCartId', shoopingCartId)
export const store = createStore(
  rootReducer,
  { shoopingCart },
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
