import React from "react";
import { Typography, Button, Dialog, DialogTitle } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { useStyles } from "./styles";

interface PopIdDialogProps {
	open: boolean;
	pay: boolean;
	handleClose: () => void;
	handleRedirectStrip: () => void;
	handleTrayAgain?: () => void;
	message: string;
}

const PopIdDialog: React.FC<PopIdDialogProps> = ({
	handleClose,
	handleRedirectStrip,
	handleTrayAgain,
	open,
	pay,
	message
}) => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				fullWidth
				style={{ maxWidth: "80% !important" }}
			>
				<DialogTitle
					className={classes.dialogTitleStyle}
					id="form-dialog-title"
				>
					{message}
				</DialogTitle>
				<div className={classes.containerStyle}>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<Button
							onClick={handleRedirectStrip}
							size="large"
							fullWidth
							className={classes.secondBtnStyle}
						>
							<Typography className={classes.fontSizeStyle}>
								{t("payment.pay_with_strip")}
							</Typography>
						</Button>
						<Button
							onClick={handleClose}
							size="large"
							fullWidth
							className={classes.secondBtnStyle}
						>
							<Typography className={classes.fontSizeStyle}>
								{t("payment.cancel")}
							</Typography>
						</Button>
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
							marginTop: "5%"
						}}
					>
						{" "}
						{!pay && (
							<Button
								onClick={handleTrayAgain}
								size="large"
								fullWidth
								className={classes.secondBtnStyle}
							>
								<Typography className={classes.fontSizeStyle}>
									{t("payment.try_again")}
								</Typography>
							</Button>
						)}
					</div>
				</div>
			</Dialog>
		</div>
	);
};
export default React.memo(PopIdDialog);
