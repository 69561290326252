import {
    ConfirmCancelState,
    ConfirmCancelActions,
    CONFIRM_OR_CANCEL,
    CONFIRM_OR_CANCEL_ERROR,
    CONFIRM_OR_CANCEL_SUCCESS
} from "./types";

const INITIAL_STATE: ConfirmCancelState = {
    loading: false
};

export const confirmReducer = (
    state = INITIAL_STATE,
    action: ConfirmCancelActions
): ConfirmCancelState => {
    switch (action.type) {
        case CONFIRM_OR_CANCEL:
            return {...state, loading: true};
        case CONFIRM_OR_CANCEL_SUCCESS:
            return {...state, loading: false};
        case CONFIRM_OR_CANCEL_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};