export const CONFIRM_OR_CANCEL_SQUARE = 'CONFIRM_OR_CANCEL_SQUARE';
export const CONFIRM_OR_CANCEL_ERROR_SQUARE = 'CONFIRM_OR_CANCEL_ERROR_SQUARE';
export const CONFIRM_OR_CANCEL_SUCCESS_SQUARE = 'CONFIRM_OR_CANCEL_SUCCESS_SQUARE';

// REDUCER INTERFACE
export interface ConfirmSquareCancelState {
    loading: boolean;
}

// CONFIRM OR CANCEL ACTION
interface ConfirmSquareCancelAction {
    type: typeof CONFIRM_OR_CANCEL_SQUARE | typeof CONFIRM_OR_CANCEL_ERROR_SQUARE | typeof CONFIRM_OR_CANCEL_SUCCESS_SQUARE;
}

export type ConfirmSquareCancelActions = ConfirmSquareCancelAction;
