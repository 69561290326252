/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import MaterialButton from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
	generateToken,
	readerDisconnected
} from "../../store/cardReader/actions";
import { RootState } from "../../store/rootReducer";
import { ReaderInterface } from "../../interfaces/ReaderDetailInterface";
import "../../assets/sass/readers.scss";
import { Button, Loader, Spinner } from "../../components";
import { SHOW_SNACK_BAR } from "../../store/snackBar/types";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";
import { config } from "../../config/config";
import { CONNECT_READER, INIT_TERMINAL } from "../../store/cardReader/types";
import ReplayIcon from "@mui/icons-material/Replay";
import { disconnectReader } from "../../store/cardReader/actions";
import {
	LOCAL_STORAGE_LOCATION_ID,
	LOCAL_STORAGE_SERIAL_NUMBER,
	RETRY_READER_CONNECT_COUNT,
	TIME_OUT_RECONNECT
} from "../../utils/constants";

export const CradReader = () => {
	const { t } = useTranslation();
	const [discoveredReaders, setDiscoveredReaders] = useState([]);
	const [searching, setSearching] = useState(true);
	const [connectingInProcess, setConnectingInProcess] = useState(false);
	const [showPage, setShowPage] = useState(false);
	const [terminalError, setTerminalError] = useState(false);

	let readerConnected = false;
	let retryCount = RETRY_READER_CONNECT_COUNT;

	const simulaterReader = {
		id: "SIMULATOR",
		object: "cardReaderState.terminal.reader",
		device_type: "verifone_P400",
		ip_address: "0.0.0.0",
		label: "Reader Simulator",
		device_sw_version: "0.0.0",
		livemode: false,
		location: "st_simulated",
		metadata: {},
		serial_number: "SIMULATOR",
		status: "online"
	};

	const dispatch = useDispatch();
	const cardReaderState = useSelector((state: RootState) => state.cardreader);

	const reconnect = async (terminal: any, isRefreshed?: Boolean) => {
		if (terminal) {
			terminal.disconnectReader();
		}
		console.log("************* STOP STOP *************");
		await initializeTerminal().then((res: any) => {
			const serialNumber = localStorage.getItem(
				LOCAL_STORAGE_SERIAL_NUMBER
			);
			console.log("after init", res, serialNumber, terminal);

			if (serialNumber && terminal) {
				discoverReaders(terminal)
					.then((res: any) => {
						console.log("After discovering =>", res);

						if (res && res.readers.length > 0) {
							console.log("reconnecting...");
							const reader = res.readers.find(
								(x: any) => x.serial_number === serialNumber
							);

							if (!reader) {
								console.log("found not reader", reader);
								if (retryCount <= 1 || isRefreshed) {
									disconnectReaderFN(terminal);
								}
							} else {
								console.log("found reader =>", reader);
								connectToPeriousReader(
									reader,
									terminal,
									isRefreshed
								);
							}
						} else {
							console.log("No readers found", res);
							if (retryCount <= 1 || isRefreshed) {
								disconnectReaderFN(terminal);
							}
						}
					})
					.catch((error: any) => {
						console.log(
							"error while initializing terminal for retry"
						);
						if (retryCount <= 1 || isRefreshed) {
							disconnectReaderFN(terminal);
						}
					});
			} else {
				console.log("No terminal found");

				if (retryCount <= 1 || isRefreshed) {
					disconnectReaderFN(terminal);
				}
			}
		});
	};

	// 1. Stripe Terminal Initialization
	const initializeTerminal = () =>
		new Promise(async (resolve, reject) => {
			const _window: any = window;
			console.log("Initialize Terminal");

			// 1b. Initialize the StripeTerminal object
			if (_window.StripeTerminal) {
				const terminal = await _window.StripeTerminal.create({
					// 1c. Create a callback that retrieves a new ConnectionToken from the example backend
					onFetchConnectionToken: async () => {
						const token = await generateTokenFn().then(
							(res: any) => {
								console.log("res", res);
								if (
									!res ||
									!res.payload?.token ||
									res.payload?.error
								) {
									console.log("error while fetching token ");
									setSearching(false);
									if (retryCount <= 1) {
										disconnectReaderFN(terminal);
									}
									return reject("error");
								}

								return res.payload?.token;
							}
						);
						console.log("Callback onFetchConnectionToken=>", token);
						if (!token) {
							return null;
						}
						return token;
					},
					// 1c. (Optional) Create a callback that will be called if the reader unexpectedly disconnects.
					onUnexpectedReaderDisconnect: async (error: any) => {
						console.log(
							"callback => Reader unexpectedly disconnects ",
							error
						);

						if (terminal) {
							terminal.disconnectReader();
						}
						setShowPage(true);
						readerConnected = false;
						const serialNumber = localStorage.getItem(
							LOCAL_STORAGE_SERIAL_NUMBER
						);

						console.log("Retry Number : 1441");
						reconnect(terminal);

						let intervalID = setInterval(async () => {
							console.log(
								"interval & readerConnected: ",
								readerConnected
							);
							console.log("Retry Number : ", retryCount);
							await reconnect(terminal);
							retryCount--;
							if (retryCount <= 1 || readerConnected) {
								console.log(
									"Interval cleared at : ",
									retryCount
								);
								window.clearInterval(intervalID);
							}
						}, TIME_OUT_RECONNECT);

						dispatch(
							readerDisconnected(
								"Reader " +
									serialNumber +
									" deconnected. error= " +
									error?.error?.message
							)
						);
					},
					// 1c. (Optional) Create a callback that will be called when the reader's connection status changes.
					onConnectionStatusChange: () => {
						console.log(
							"callback => Reader's connection status changed",
							terminal
						);
						dispatch({
							type: INIT_TERMINAL,
							payload: {
								terminal: terminal
							}
						});
					}
				});
				console.log("Terminal initialized", terminal);
				dispatch({
					type: INIT_TERMINAL,
					payload: {
						terminal: terminal
					}
				});

				resolve(terminal);
			} else {
				setSearching(false);
				setShowPage(true);
				setTerminalError(true);
			}
		});

	// 2. Discover and connect to a reader.
	const discoverReaders = async (terminal: any) => {
		if (terminal) {
			setSearching(true);
			console.log("Discovering readers...");
			const locationId = localStorage.getItem(LOCAL_STORAGE_LOCATION_ID);
			// 2a. Discover registered readers to connect to.
			const stripeConfig = {
				simulator: false,
				location: locationId
			};
			const discoverResult = await terminal.discoverReaders(stripeConfig);

			console.log("Result from ws discover =>", discoverResult);

			if (discoverResult.error) {
				console.log("Failed to discover: ", discoverResult.error);
				setSearching(false);
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: "reader.discover_error",
						severity: "error"
					}
				});
				return Promise.reject({
					error: "reader.discover_error",
					readers: []
				});
			} else {
				setDiscoveredReaders(discoverResult.discoveredReaders);
				console.log(
					"Discovered readers list: ",
					discoverResult.discoveredReaders
				);
				setSearching(false);
				return Promise.resolve({
					error: null,
					readers: [
						...discoverResult.discoveredReaders,
						simulaterReader
					]
				});
			}
		}
	};

	const connectToPeriousReader = async (
		selectedReader: ReaderInterface,
		terminal: any,
		isRefreshed?: Boolean
	): Promise<any> => {
		setConnectingInProcess(true);
		console.log("starting connection ", selectedReader);
		if (terminal) {
			// 2b. Connect to a discovered reader.
			const connectResult = await terminal.connectReader(selectedReader, {
				fail_if_in_use: true
			});
			if (connectResult.error) {
				console.log("Failed to connect:", connectResult.error);
				setConnectingInProcess(false);
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: "reader.connect_error",
						severity: "error"
					}
				});
				if (retryCount <= 1 || isRefreshed) {
					disconnectReaderFN(terminal);
				}
			} else {
				console.log("connected reader: ", connectResult.reader);
				setConnectingInProcess(false);
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: "reader.connect_success",
						severity: "success"
					}
				});
				dispatch({
					type: CONNECT_READER,
					payload: {
						reader: connectResult.reader
					}
				});
				localStorage.setItem(
					LOCAL_STORAGE_SERIAL_NUMBER,
					connectResult.reader.serial_number
				);
				history.push(PATHS.Home);
				readerConnected = true;
				retryCount = RETRY_READER_CONNECT_COUNT;
				return connectResult;
			}
		} else {
			if (retryCount <= 1 || isRefreshed) {
				disconnectReaderFN(terminal);
			}
		}
	};

	const connectToReader = async (
		selectedReader: ReaderInterface
	): Promise<any> => {
		setConnectingInProcess(true);
		console.log("starting connection ", selectedReader);
		if (cardReaderState.terminal) {
			// 2b. Connect to a discovered reader.
			const connectResult = await cardReaderState.terminal.connectReader(
				selectedReader,
				{ fail_if_in_use: true }
			);
			if (connectResult.error) {
				console.log("Failed to connect:", connectResult.error);
				setConnectingInProcess(false);
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: { message: "reader.offline", severity: "error" }
				});
			} else {
				console.log("connected reader: ", connectResult.reader);
				setConnectingInProcess(false);
				dispatch({
					type: SHOW_SNACK_BAR,
					payload: {
						message: "reader.connect_success",
						severity: "success"
					}
				});
				dispatch({
					type: CONNECT_READER,
					payload: {
						reader: connectResult.reader
					}
				});
				localStorage.setItem(
					LOCAL_STORAGE_SERIAL_NUMBER,
					connectResult.reader.serial_number
				);
				history.push(PATHS.Home);
				return connectResult;
			}
		} else {
			setConnectingInProcess(false);
			initializeTerminal();
		}
	};

	const generateTokenFn = () =>
		new Promise(async (resolve, reject) => {
			// do anything here
			const result = dispatch(generateToken);
			resolve(result);
		});

	const startSearching = () => {
		initializeTerminal().then((terminal: any) => {
			console.log("initializeTerminal : start discovering", terminal);

			if (localStorage.getItem(LOCAL_STORAGE_SERIAL_NUMBER)) {
				reconnect(terminal, true);
			} else {
				discoverReaders(terminal);
			}
		});
	};
	const refreshPage = () => {
		if (terminalError) {
			window.location.reload();
		} else {
			startSearching();
		}
	};
	useEffect(() => {
		startSearching();
	}, []);

	const disconnectReaderFN = (terminal: any) => {
		console.log("disconnect Reader");
		readerConnected = false;
		if (terminal) {
			terminal.disconnectReader();
		}
		dispatch(disconnectReader());
		setShowPage(true);
		localStorage.removeItem(LOCAL_STORAGE_SERIAL_NUMBER);
		history.push(PATHS.CARD_READER);
		dispatch({
			type: SHOW_SNACK_BAR,
			payload: { message: "reader.disconnected", severity: "error" }
		});
	};

	return (
        <div>
			{localStorage.getItem(LOCAL_STORAGE_SERIAL_NUMBER) && !showPage ? (
				<Loader />
			) : (
				<Grid className="readers-container" container justifyContent="center">
					{cardReaderState.error || terminalError ? (
						<Grid
							container
							justifyContent="center"
							alignItems="center"
							direction="column"
						>
							<Typography>{t("reader.error")}</Typography>
							<MaterialButton
								onClick={refreshPage}
								className="retry-icon"
							>
								{t("common.retry")}
								<ReplayIcon />
							</MaterialButton>
						</Grid>
					) : cardReaderState.loading || searching ? (
						<Grid>{t("reader.searching")}</Grid>
					) : (
						<Grid
							className="readers-list"
							container
							direction="column"
						>
							<Grid className="readers-head">
								{t("reader.available")}
							</Grid>
							{discoveredReaders?.length > 0 ? (
								discoveredReaders.map(
									(item: ReaderInterface, index: number) => (
										<Grid
											key={"reader" + index}
											className="reader-detail"
											container
											justifyContent="space-between"
											alignItems="center"
										>
											{item.label}
											<Button
												disabled={connectingInProcess}
												title={t("reader.connect")}
												onClick={() =>
													connectToReader(item)
												}
											/>
										</Grid>
									)
								)
							) : (
								<Grid
									container
									justifyContent="center"
									alignItems="center"
									direction="column"
								>
									<Typography>{t("reader.empty")}</Typography>
									<MaterialButton
										onClick={startSearching}
										className="retry-icon"
									>
										{t("common.retry")}
										<ReplayIcon />
									</MaterialButton>
								</Grid>
							)}
							<Grid
								className="reader-detail"
								container
								justifyContent="space-between"
								alignItems="center"
							>
								{simulaterReader.label}
								<Button
									disabled={connectingInProcess}
									title={t("reader.connect")}
									onClick={() =>
										connectToReader(simulaterReader)
									}
								/>
							</Grid>
							<Spinner loading={connectingInProcess} />
						</Grid>
					)}
				</Grid>
			)}
		</div>
    );
};

export default CradReader;
