/*******
 * user who has an account popID
 */
export const DETECTED_REAL_PERSON = "real-person/detected";

/*******
 * user who does not have an account popID
 */
export const PERSON_NOT_RECOGNIZED = "real-person/detected/not-recognized";

/********
 * other object
 */
export const NO_REAL_PERSON_DETECTED = "real-person/not-detected";

/********
 * payment with popID success
 */
export const SUCCESS_PAYMENT_WITH_POP_ID = "payment-popID/success";

/*******
 * payment with PopID failed
 */
export const FAILED_PAYMENT_WITH_POP_ID = "payment-popID/failed";

export const GET_POPID_URL = "GET_POPID_URL";
export interface PaymentState {
	transactionId: number;
}
export interface RealPersonState {
	faceId: string;
	firstName: string;
	lastName: string;
	phone: string;
	pin: string;
}
export interface RecognizedPersonState {
	error: string;
}
export interface otherObjectDetectedState {
	error: string;
	image: string;
}

export interface dataState {
	person: RealPersonState;
	recognizedPerson: RecognizedPersonState;
	object: otherObjectDetectedState;
	successPayment: PaymentState;
	failedPayment: {};
	url: string;
}

interface DetectedRealPersonAction {
	type: typeof DETECTED_REAL_PERSON;
	payload: RealPersonState;
}
interface DetectedRecognizedRealPersonAction {
	type: typeof PERSON_NOT_RECOGNIZED;
	payload: RecognizedPersonState;
}
interface DetectedOtherObjectAction {
	type: typeof NO_REAL_PERSON_DETECTED;
	payload: otherObjectDetectedState;
}
interface SuccessPopIdPaymentAction {
	type: typeof SUCCESS_PAYMENT_WITH_POP_ID;
	payload: PaymentState;
}
interface FailedPopIdPaymentAction {
	type: typeof FAILED_PAYMENT_WITH_POP_ID;
	payload: {};
}

export type PopIdSearchRealPersonActionTypes =
	| DetectedRealPersonAction
	| DetectedRecognizedRealPersonAction
	| DetectedOtherObjectAction;

export type PopIdPaymentActionTypes =
	| SuccessPopIdPaymentAction
	| FailedPopIdPaymentAction;
