import produce from "immer";
import {
	ORDER_READER,
	OrderTypes,
	OrderState,
	EMPTY_ORDER,
	EmptyOrderTypes,
	PaymentIntentTypes,
	SAVE_PAYMENT_INTENT,
	ORDER_READER_SUCCESS,
	ORDER_READER_FAIL,
	STRIPE_OR_POPID_ORDER
} from "./types";

const INITIAL_STATE: OrderState = {
	clientSecret: "",
	order: null,
	loading: false,
	paymentIntent: null,
	popid: 0
};

export const orderReducer = (
	state = INITIAL_STATE,
	action: OrderTypes | EmptyOrderTypes | PaymentIntentTypes
): OrderState =>
	produce(state, draft => {
		switch (action.type) {
			case ORDER_READER:
				draft.loading = true;
				break;
			case ORDER_READER_SUCCESS:
				draft.clientSecret = action.payload.clientSecret;
				draft.order = action.payload.order;
				draft.loading = false;
				break;
			case ORDER_READER_FAIL:
				draft.loading = false;
				break;
			case EMPTY_ORDER:
				draft.clientSecret = "";
				draft.order = null;
				draft.loading = false;
				draft.paymentIntent = null;
				break;
			case SAVE_PAYMENT_INTENT:
				draft.paymentIntent = action.payload.paymentIntent;
				break;
			case STRIPE_OR_POPID_ORDER:
				draft.popid = action.popid;
				break;
		}
	});
