import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ColorProps } from "../../interfaces/ColorsProps";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogTitleStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[1]
		}),
		cancelColor: (props: ColorProps) => ({
			color: props.colors[0]
		}),
		dialogContentStyle: (props: ColorProps) => ({
			color: props.colors[0],
			backgroundColor: props.colors[1],
			"& svg": {
				color: props.colors[0]
			}
		}),
		dialogActionStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[0]
		}),
		btnStyle: (props: ColorProps) => ({
			color: props.colors[1],
			backgroundColor: props.colors[0]
		}),
		radioGroupeStyle: (props: ColorProps) => ({
			flexDirection: "row",
			justifyContent: "center"
		}),
		titleStyle: (props: ColorProps) => ({
			color: props.colors[0],
			fontWeight: "bold"
		}),
		titleTextStyle: (props: ColorProps) => ({
			padding: "0 24px",
			color: props.colors[0]
		}),
		cancelButton: (props: ColorProps) => ({
			position: "absolute",
			top: 16,
			right: 24
		})
	})
);
