import * as React from "react";
import "./styles.css";

interface ButtonProps {
	title: string;
	onClick?: () => void;
	disabled?: boolean;
	color?: string;
}

export const Button = (props: ButtonProps) => {
	const { title, onClick, disabled,color } = props;
	return (
		<button
			type="submit"
			className="btn"
			onClick={onClick}
			disabled={disabled}
			style={{ minWidth: '100px', backgroundColor: color }}
		>
			{title}
		</button>
	);
};
export default Button;
