import * as React from 'react';
import '../../assets/sass/quantityButtons.scss';
import Button from '@mui/material/Button';
import {useStyles} from "./styles";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


interface QunatityButtonsProps {
    colors: Array<string>;
    quantity: number;
    onIncreaseBtnClick: () => void;
    onDecreaseBtnClick: () => void;
}


export const QunatityButtons = (props: QunatityButtonsProps) => {
    const {colors, quantity, onIncreaseBtnClick, onDecreaseBtnClick} = props;
    const classes = useStyles();

    const theme = createTheme(adaptV4Theme({
        overrides: {
        }
    }));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.container}>
                    <Button id="decrease-btn" onClick={onDecreaseBtnClick}
                            style={{minWidth: 35, backgroundColor: colors[1], color: colors[0]}} className={classes.btn}>
                        -
                    </Button>
                    <div className={classes.quantityContainer}>
                    <span style={{color: colors[1]}} className={classes.span}>{quantity}</span>
                    </div>
                    <Button id="increase-btn" onClick={onIncreaseBtnClick}
                            style={{minWidth: 35, backgroundColor: colors[1], color: colors[0]}} className={classes.btn}>
                        +
                    </Button>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
export default QunatityButtons;