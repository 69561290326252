import {
	DEEP_SIGHT_DEFAULT_TIMER,
	DEEP_SIGHT_DEFAULT_DISTANCE,
	DEEP_ENV_VALUE
} from "../utils/constants";

export const config = {
	BASE_URL_API: process.env.REACT_APP_API_BASE_URL,
	STRIPE_LOCAYION_ID: process.env.REACT_APP_STRIPE_LOCATION_ID,
	DEEP_SIGHT_URL: process.env.REACT_APP_DEEP_SIGHT_URL,
	POP_ID_URL: process.env.REACT_APP_POP_ID_URL,
	DEEP_SIGHT_TIMER: parseInt(
		process.env.REACT_APP_DEEP_SIGHT_TIMER || DEEP_SIGHT_DEFAULT_TIMER
	),
	DEEP_SIGHT_DISTANCE: parseInt(
		process.env.REACT_APP_DEEP_SIGHT_DISTANCE || DEEP_SIGHT_DEFAULT_DISTANCE
	),
	SMS_ACTIVE: parseInt(process.env.REACT_APP_SMS_ACTIVE || DEEP_ENV_VALUE),
	CARTE_ACTIVE: parseInt(process.env.REACT_APP_CARTE_ACTIVE || DEEP_ENV_VALUE)
};
