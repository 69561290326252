export const ENDPOINTS = {
	MENU: "menu",
	LOGIN: "login",
	KIOSK_CHOICE: "kiosk/modes/makeAvailable",
	MODES: "kiosk/modes",
	GENERATE_TOKEN: "createReaderToken",
	CHECKOUT: "kiosk/checkout",
	CONFIRM: "kiosk/orderId/confirmOrderForReader",
	CANCEL: "kiosk/orderId/cancelOrderForReader",
	ORDER: "kiosk/orderForReader",
	ORDER_SQUARE: "kiosk/orderForSquareup",
	SQUARE_CONFIRM:"kiosk/SquareupCheckout",
	LOGOUT: "logout",
	RESET_PASSWORD: "kiosk/password/email",
	SEND_LOG_MAIL: "logging",
	SUGGESTION: "recommended",
	PAYEMENT:"kiosk/SquareupCheckout/",
	CANCELSQUARE: "kiosk/cancelOrderSquare",
	CANCEL_SQUARE_CHECKOUT_ID:"kiosk/cancelSquareCheckout"


};
