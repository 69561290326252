import React from "react";
import { Redirect, Switch } from "react-router-dom";
import {
	LoginPage,
	KioskChoicePage,
	HomePage,
	ProductDetailsPage,
	CheckoutPage,
	ConfirmPage,
	OrderSubmittedPage,
	ResetPasswordPage
} from "../pages";
import PrivateRoute from "./PrivateRoute";
import GuestRoute from "./GuestRoute";
import { PATHS } from "./paths";
import LayoutRoute from "../layouts/RouteLayout";
import ProtectedRoute from "./ProtectedRoute";
import CradReader from "../pages/CradReader";
import ConnectedRoute from "./ConnectedRoute";
import Background from "../layouts/BackgroundLayout";
import PaymentPage from "../pages/Payment";
import CheckOutLayout from "../layouts/CheckOutLayout";
import { config } from "../config/config";
import { SQUARE_UP_STATUS } from "../utils/constants";
import ModePaymentPage from "../pages/ModePayment";
import PopIdPaymentPage from "../pages/PopIdPayment";
export const Routes = () => {
	const squareStatus = parseInt(localStorage.getItem(SQUARE_UP_STATUS) as string);

	return (
		<Switch>
			<ProtectedRoute
				path={PATHS.Home}
				exact
				component={() => <LayoutRoute component={HomePage} />}
			/>
			<ProtectedRoute
				path={PATHS.PRODUCT}
				exact
				component={() => <LayoutRoute component={ProductDetailsPage} />}
			/>
			<ProtectedRoute
				path={PATHS.CHOICE_MODE_PAYMENT}
				exact
				component={() => (
					<CheckOutLayout>
						<ModePaymentPage />
					</CheckOutLayout>
				)}
			/>
			<ProtectedRoute
				path={PATHS.POP_ID_MODE_PAYMENT}
				exact
				component={() => (
					<CheckOutLayout>
						<PopIdPaymentPage />
					</CheckOutLayout>
				)}
			/>
			{config.CARTE_ACTIVE && (
				<ProtectedRoute
					path={PATHS.PAYMENT}
					exact
					component={() => (
						<CheckOutLayout>
							<PaymentPage />
						</CheckOutLayout>
					)}
				/>
			)}
			{config.CARTE_ACTIVE && !squareStatus && (
				<ConnectedRoute
					path={PATHS.CARD_READER}
					exact
					component={() => (
						<Background>
							<CradReader />
						</Background>
					)}
				/>
			)}
			<GuestRoute path={PATHS.LOGIN} exact component={LoginPage} />
			<GuestRoute
				path={PATHS.RESET_PASSWORD}
				exact
				component={ResetPasswordPage}
			/>
			<PrivateRoute
				path={PATHS.KIOSK_CHOICE_PATH}
				exact
				component={KioskChoicePage}
			/>
			<ProtectedRoute
				path={PATHS.CHECKOUT}
				exact
				component={() => (
					<CheckOutLayout>
						<CheckoutPage />
					</CheckOutLayout>
				)}
			/>
			<ProtectedRoute
				path={PATHS.CONFIRM}
				exact
				component={() => <ConfirmPage />}
			/>
			<ProtectedRoute
				path={PATHS.ORDER_SUBMITTED}
				exact
				component={() => (
					<CheckOutLayout>
						<OrderSubmittedPage />
					</CheckOutLayout>
				)}
			/>
			<Redirect from="*" to={PATHS.Home} />
		</Switch>
	);
};
export default Routes;
