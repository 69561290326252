import React, { useEffect } from "react";
import { AppBar, TabPanel } from "..";
import { MenuInterface, MenuActionTypes } from "../../store/menu/types";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { getValue } from "../../store/menu/actions";
import { RootState } from "../../store/rootReducer";
interface MenuDetailsProps {
	menu: MenuInterface[];
	colors: string[];
}

export const MenuDetails = (props: MenuDetailsProps) => {
	const { menu, colors } = props;
	const dispatch =
		useDispatch<ThunkDispatch<RootState, undefined, MenuActionTypes>>();
	const { valueCategory } = useSelector((state: RootState) => state.menu);
	const [value, setValue] = React.useState<number>(valueCategory);
	const handleChange = (newValue: number) => {
		setValue(newValue);
		
	};

	useEffect(() => {
		dispatch(getValue(value));
	}, [value]);

	useEffect(() => {
		setValue(valueCategory);
	}, [valueCategory]);
	
	return (
		<>
			<AppBar
				menu={menu}
				changeTab={handleChange}
				value={value}
				colors={colors}
			/>
			<TabPanel menu={menu} value={value} colors={colors} />
		</>
	);
};
export default MenuDetails;
