import React, { useCallback, useEffect, useRef, useState } from "react";
import BackgroundLayout from "../../layouts/BackgroundLayout";
import "./styles.css";
import { useTranslation } from "react-i18next";
import { Input, Button, Spinner, RadioButton } from "../../components";
import { ChangeEvent } from "react";
import { changeStatus } from "../../store/kioskChoice/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import {
	LOCAL_STORAGE_MODE,
	SIGHTCORP_STATUS,
	SIGHT_CORP,
	POP_ID,
	POP_ID_HOST,
	POP_ID_STATUS
} from "../../utils/constants";
import { KIOSK_MODES } from "../../utils/constants";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { config } from "../../config/config";
interface InputProps<T> {
  value: T;
  error: string | null;
}

export const KioskChoice = () => {
  const tableInputRef = useRef<HTMLInputElement | null>(null);
  const globalInputRef = useRef<HTMLInputElement | null>(null);
  const sightInputRef = useRef<HTMLInputElement | null>(null);

  const popIdStatus = parseInt(localStorage.getItem(POP_ID_STATUS) as string);
  const sightCorpStatus = parseInt(
    localStorage.getItem(SIGHTCORP_STATUS) as string
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [generalCode, setGeneralCode] = React.useState<InputProps<string>>({
    value: "",
    error: null,
  });

  const [kioskMode, setKioskMode] = React.useState<any>({
    value: 0,
    error: null,
  });

  const [checkedkioskMode, setCheckedkioskMode] = React.useState<any>(0);
  const [layout, setLayout] = useState("default");
  const keyboard = useRef<typeof Keyboard | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const wishInput = useRef<number>(-1);
  const [checkedkioskModeError, setCheckedkioskModeError] =
    React.useState<any>("");

  const [tableCode, setTableCode] = React.useState<InputProps<string>>({
    value: "",
    error: null,
  });

  const [sightCorp, setSightCorp] = React.useState<InputProps<string>>({
    value: "",
    error: null,
  });
  const [popId, setPopId] = React.useState<string>(POP_ID_HOST);
  const handleOnSightCorpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSightCorp({
      ...sightCorp,
      value: e.target.value,
      error: null,
    });
  };
  const handleOnPopUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
		setPopId(e.target.value);
  };
  const handleOnGeneralCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGeneralCode({
      ...generalCode,
      value: e.target.value,
      error: null,
    });
  };

  const handleOnTableCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTableCode({
      ...tableCode,
      value: e.target.value,
      error: null,
    });
  };

  React.useEffect(() => {
    const kioskMode = localStorage.getItem(LOCAL_STORAGE_MODE);
    if (kioskMode) setKioskMode(parseInt(kioskMode));
  
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (
      kioskModeSupportted &&
      parseInt(kioskModeSupportted) != KIOSK_MODES.GLOBAL_AND_TABLE &&
      parseInt(kioskModeSupportted) != KIOSK_MODES.INACTIVE
    ) {
      setCheckedkioskMode(parseInt(kioskModeSupportted));
    }
  }, []);

  const kioskChoice = useSelector((state: RootState) => state.kioskChoice);
  const { modes: modesState, changeStatus: changeStatusState } = kioskChoice;
  const { loader: modesLoader, tables, globals } = modesState;
  const { loader: changeStatusLoader, error: changeStatusError } =
    changeStatusState;

  const changeGlobalModeStatus = () => {
    if (generalCode.value === "")
      setGeneralCode({ ...generalCode, error: t("validations.required") });
    if (generalCode.value !== "" ) {
      if (sightCorp.value !== "") {
        localStorage.setItem(SIGHT_CORP, sightCorp.value);
      } else {
        localStorage.setItem(SIGHT_CORP, config.DEEP_SIGHT_URL as string);
      }
    if (popId !== "") {
			localStorage.setItem(POP_ID, popId);
		} else {
			localStorage.setItem(POP_ID, config.POP_ID_URL as string);
		}
      dispatch(changeStatus(generalCode.value, KIOSK_MODES.GLOBAL));
    }
  };

  const changeTableModeStatus = () => {
    if (tableCode.value === "")
      setTableCode({ ...tableCode, error: t("validations.required") });
    if (tableCode.value !== "") {
      if (sightCorp.value !== "") {
        localStorage.setItem(SIGHT_CORP, sightCorp.value);
      } else {
        localStorage.setItem(SIGHT_CORP, config.DEEP_SIGHT_URL as string);
      }
      if (popId !== "") {
			localStorage.setItem(POP_ID, popId);
		} else {
			localStorage.setItem(POP_ID, config.POP_ID_URL as string);
		}
      dispatch(changeStatus(tableCode.value, KIOSK_MODES.TABLE));
    }
  };

  const clearError = () => {
    setGeneralCode({
      ...generalCode,
      error: null,
    });
    setTableCode({
      ...tableCode,
      error: null,
    });
  };

  const changeMode = () => {
    switch (checkedkioskMode) {
      case KIOSK_MODES.GLOBAL:
        changeGlobalModeStatus();
        if (sightCorp.value !== "") {
          localStorage.setItem(SIGHT_CORP, sightCorp.value);
        } else {
          localStorage.setItem(SIGHT_CORP, config.DEEP_SIGHT_URL as string);
        }
         if (popId !== "") {
				localStorage.setItem(POP_ID, popId);
			} else {
				localStorage.setItem(POP_ID, config.POP_ID_URL as string);
			}
        break;
      case KIOSK_MODES.TABLE:
        changeTableModeStatus();
        if (sightCorp.value !== "") {
          localStorage.setItem(SIGHT_CORP, sightCorp.value);
        } else {
          localStorage.setItem(SIGHT_CORP, config.DEEP_SIGHT_URL as string);
        }
         if (popId !== "") {
				localStorage.setItem(POP_ID, popId);
			} else {
				localStorage.setItem(POP_ID, config.POP_ID_URL as string);
			}
        break;
      default:
        break;
    }
  };
  const onChange = (input: any) => {
    console.log(input);
    switch (wishInput.current) {
		case 0:
			setGeneralCode({
				...generalCode,
				value: input,
				error: null
			});
			break;
		case 1:
			setTableCode({
				...tableCode,
				value: input,
				error: null
			});
			break;
		case 2:
			setSightCorp({
				...sightCorp,
				value: input,
				error: null
			});
			break;
		case 3:
			setPopId(input);
			break;
		default:
			break;
	}
  };

  const onClick = () => {
    const kioskMode = localStorage.getItem(LOCAL_STORAGE_MODE);
    if (checkedkioskMode === 0) {
      setCheckedkioskModeError(t("errors.choose_kiosk_mode"));
    } else {
      setCheckedkioskModeError("");
    }
    clearError();
    if (kioskMode) {
      switch (parseInt(kioskMode)) {
        case KIOSK_MODES.GLOBAL:
          changeGlobalModeStatus();
          break;
        case KIOSK_MODES.TABLE:
          changeTableModeStatus();
          break;
        case KIOSK_MODES.GLOBAL_AND_TABLE:
          changeMode();
          break;
        default:
          break;
      }
    }
  };
  const onFocusGlobal = 
    (e: React.FocusEvent<HTMLElement>) => {
      e.target.blur();
      setCheckedkioskMode(KIOSK_MODES.GLOBAL);
      setTableCode({
        ...tableCode,
        value: "",
        error: null,
      });
      
      if (keyboard.current !== null) {
        //@ts-ignore
        keyboard.current.setInput(generalCode.value);
      }
      wishInput.current = 0;
      setShowKeyboard(true);
      // console.log("wishGloba0", wishInput.current);
    };
  const onFocusTable = 
    (e: React.FocusEvent<HTMLElement>) => {
       e.target.blur();
      setCheckedkioskMode(KIOSK_MODES.TABLE);
      setGeneralCode({
        ...generalCode,
        value: "",
        error: null,
      });
     
      if (keyboard.current !== null) {
        //@ts-ignore
        keyboard.current.setInput(tableCode.value);
      }
      wishInput.current = 1;
      setShowKeyboard(true);
      // console.log("wishTable1", wishInput.current);
    };
  const onFocusSightCorp = (e: React.FocusEvent<HTMLElement>) => {
    e.target.blur();
    setSightCorp({
      ...sightCorp,
    });
    if (keyboard.current !== null) {
      //@ts-ignore
      keyboard.current.setInput(sightCorp.value);
    }
    wishInput.current = 2;
    setShowKeyboard(true);
    // console.log("sightcorp2", wishInput.current);
  };
 const onFocusPopUrl = (e: any) => {
		e.target.blur();

		if (keyboard.current !== null) {
			//@ts-ignore
			keyboard.current.setInput(popId);
		}
		wishInput.current = 3;
		setShowKeyboard(true);
 };
  const kioskModeSupportted = localStorage.getItem(LOCAL_STORAGE_MODE) || "";

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick();
  };
  const onKeyPress = (button: any) => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      // console.log("event target", event.target);
      //   console.log("input ref",inputRef.current)
      //@ts-ignore

      if (
        ref.current &&
        event.target.tagName.toUpperCase() !== "INPUT" &&
        !ref.current.contains(event.target)
      ) {
        wishInput.current = -1;
        setShowKeyboard(false);
        // console.log("outwishinput-1", wishInput.current);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
 
  return (
		<div>
			<div style={{ marginBottom: showKeyboard ? "30vh" : 0 }}>
				<BackgroundLayout>
					<form
						noValidate
						onSubmit={(e: React.SyntheticEvent) => {
							submitForm(e);
						}}
						style={{ marginTop: "-3%" }}
					>
						<p className="title">{t("kioskChoice.title")}</p>
						<p className="sub-title">
							{t("kioskChoice.sub_title")}
						</p>
						<div className="mode-container">
							<div className="radio-buttons">
								{(parseInt(kioskModeSupportted) ===
									KIOSK_MODES.GLOBAL ||
									parseInt(kioskModeSupportted) ===
										KIOSK_MODES.GLOBAL_AND_TABLE) && (
									<div className="col">
										<RadioButton
											value={KIOSK_MODES.GLOBAL}
											onChange={() => {
												setCheckedkioskMode(
													KIOSK_MODES.GLOBAL
												);
												wishInput.current = 0;
												setTableCode({
													...tableCode,
													value: "",
													error: null
												});
											}}
											disabled={
												kioskMode &&
												KIOSK_MODES.GLOBAL !==
													kioskMode &&
												KIOSK_MODES.GLOBAL_AND_TABLE !==
													kioskMode
											}
											label={t(
												"kioskChoice.general_kiosk"
											)}
											checked={
												checkedkioskMode &&
												KIOSK_MODES.GLOBAL ===
													checkedkioskMode
											}
										/>
										<Input
											ref={globalInputRef}
											isFocus={
												wishInput.current === 0 &&
												checkedkioskMode ===
													KIOSK_MODES.GLOBAL
											}
											onFocus={(
												e: React.FocusEvent<HTMLElement>
											) => onFocusGlobal(e)}
											placeholder={t(
												"kioskChoice.placeholder"
											)}
											disabled={
												kioskMode &&
												KIOSK_MODES.GLOBAL === kioskMode
											}
											value={generalCode.value}
											onChange={handleOnGeneralCodeChange}
											type="default"
										/>
									</div>
								)}
								<div className="col">
									<div className="error-container"></div>
									<div className="error-container">
										<span className="error">
											{generalCode.error}
										</span>
									</div>
								</div>
								{(parseInt(kioskModeSupportted) ===
									KIOSK_MODES.TABLE ||
									parseInt(kioskModeSupportted) ===
										KIOSK_MODES.GLOBAL_AND_TABLE) && (
									<div className="col">
										<RadioButton
											value={KIOSK_MODES.TABLE}
											onChange={() => {
												setCheckedkioskMode(
													KIOSK_MODES.TABLE
												);
												wishInput.current = 1;
												setGeneralCode({
													...generalCode,
													value: "",
													error: null
												});
											}}
											label={t("kioskChoice.table_kiosk")}
											disabled={
												kioskMode &&
												KIOSK_MODES.TABLE !==
													kioskMode &&
												KIOSK_MODES.GLOBAL_AND_TABLE !==
													kioskMode
											}
											checked={
												checkedkioskMode &&
												KIOSK_MODES.TABLE ===
													checkedkioskMode
											}
										/>
										<Input
											ref={tableInputRef}
											isFocus={
												wishInput.current === 1 &&
												checkedkioskMode ===
													KIOSK_MODES.TABLE
											}
											onFocus={(
												e: React.FocusEvent<HTMLElement>
											) => onFocusTable(e)}
											placeholder={t(
												"kioskChoice.placeholder"
											)}
											disabled={
												kioskMode &&
												KIOSK_MODES.TABLE === kioskMode
											}
											value={tableCode.value}
											onChange={handleOnTableCodeChange}
											type="default"
										/>
									</div>
								)}

								<div className="col">
									<div className="error-container"></div>
									<div className="error-container">
										<span className="error">
											{tableCode.error}
										</span>
									</div>
								</div>
							</div>
						</div>
						{sightCorpStatus ? (
							<div className="row" style={{ marginTop: "-8%" }}>
								<p className="sub-title">
									{t("kioskChoice.sight_corp")}
								</p>
								<Input
									ref={sightInputRef}
									isFocus={wishInput.current === 2}
									onFocus={(
										e: React.FocusEvent<HTMLElement>
									) => onFocusSightCorp(e)}
									placeholder={
										config.DEEP_SIGHT_URL as string
									}
									value={sightCorp.value}
									onChange={handleOnSightCorpChange}
									type="default"
								/>
							</div>
						) : null}
						{popIdStatus ? (
							<div className="row">
								<p className="sub-title">
									{t("kioskChoice.pop_id")}
								</p>
								<Input
									isFocus={wishInput.current === 3}
									onFocus={(
										e: React.FocusEvent<HTMLElement>
									) => onFocusPopUrl(e)}
									placeholder={config.POP_ID_URL as string}
									value={popId}
									onChange={handleOnPopUrlChange}
									type="default"
								/>
							</div>
						) : null}
						<p className="error">{t(changeStatusError)}</p>
						<p className="error">{checkedkioskModeError}</p>
						<div className="btn-container">
							<Button
								title={t("kioskChoice.activate_kiosk")}
								onClick={() => submitForm}
							/>
						</div>
						<Spinner loading={modesLoader || changeStatusLoader} />
					</form>
				</BackgroundLayout>
			</div>

			{showKeyboard && (
				<div ref={ref} className={"keyboardContainer"}>
					<Keyboard
						keyboardRef={(r: any) => (keyboard.current = r)}
						layoutName={layout}
						onChange={onChange}
						onKeyPress={onKeyPress}
					/>
				</div>
			)}
		</div>
  );
};
export default KioskChoice;
