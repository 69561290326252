export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_ERROR = 'CHECKOUT_ERROR';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';

// REDUCER INTERFACE
export interface CheckoutState {
    loading: boolean;
}

// CHECKOUT ACTION
interface CheckoutAction {
    type: typeof CHECKOUT | typeof CHECKOUT_SUCCESS | typeof CHECKOUT_ERROR;
}

export type CheckoutActions = CheckoutAction;
