import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Theme } from "@mui/material/styles";
import { ColorProps } from "../../interfaces/ColorsProps";
const hoverStyle = (props: ColorProps) => {
	return {
		"&:hover": {
			color: props.colors[0],
			backgroundColor: props.colors[1],
			borderWidth: 1,
			borderColor: props.colors[0],
			borderStyle: "solid"
		}
	};
};
export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogTitleStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[1],
			textAlign: "center",
			color: props.colors[0]
		}),
		containerStyle: (props: ColorProps) => ({
			backgroundColor: props.colors[1],
			alignItems: "center",
			width: "100%",
			paddingRight: "9%",
			paddingLeft: "9%",
			paddingTop: "3%",
			paddingBottom: "9%"
		}),
		input: (props: ColorProps) => ({
			backgroundColor: props.colors[0],
			color: props.colors[1],
			borderRadius: 3,
			width: 150,
			height: 55,
			paddingLeft: 40,
			fontSize: 16,
			fontWeight: "bold"
		}),
		firstBtnStyle: (props: ColorProps) => ({
			color: props.colors[1],
			backgroundColor: props.colors[0],
			marginBottom: "4%",
			borderWidth: 1,
			borderColor: props.colors[0],
			borderStyle: "solid",
			...hoverStyle(props)
		}),
		secondBtnStyle: (props: ColorProps) => ({
			borderWidth: 1,
			borderColor: props.colors[0],
			borderStyle: "solid",
			color: props.colors[1],
			backgroundColor: props.colors[0],
			width: "200px",
			...hoverStyle(props)
		}),
		fontSizeStyle: (props: ColorProps) => ({ fontSize: 18 })
	})
);
