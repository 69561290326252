import {CheckoutState, CHECKOUT_ERROR, CHECKOUT, CHECKOUT_SUCCESS} from "./types";

const INITIAL_STATE: CheckoutState = {
    loading: false
};

export const checkoutReducer = (
    state = INITIAL_STATE,
    action: any
): CheckoutState => {
    switch (action.type) {
        case CHECKOUT:
            return {...state, loading: true};
        case CHECKOUT_SUCCESS:
            return {...state, loading: false};
        case CHECKOUT_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};