import { ThunkDispatch } from "redux-thunk";
import {
	LOCAL_STORAGE_KIOSK_CHOICE_CONFIGURED,
	LOCAL_STORAGE_SELECTED_MODE,
	LOCAL_STORAGE_SELECTED_ORDER_TYPE,
	USE_TAX_VALUE,
	ORDER_IN_Values
} from "../../utils/constants";
import { CheckIsGlobal, hasForcedTax } from "../../utils/helpers";
import { RootState } from "../rootReducer";
import { ProductModal, ShoopingCartActions } from "./models";
import {
	ADD_COMMENT_SHOP,
	ADD_PRODUCT_TO_SHOPPING_CART,
	REMOVE_PRODUCT_FROM_SHOOPING_CART,
	REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART,
	ADD_TIP_SHOP
} from "./types";

export const AddProductToShoopingCart = (
	product: ProductModal,
	modifiersSelected: number[],
	price: number,
	quantity: number
) => {
	return (
		dispatch: ThunkDispatch<RootState, undefined, ShoopingCartActions>,
		getState: () => RootState
	) => {
		let priceWithTax = price;
		const taxPrice = (price * getState().menu.merchant.tax) / 100;
		const orderType =
			localStorage.getItem(LOCAL_STORAGE_SELECTED_ORDER_TYPE) || "";
		const applyTax = CheckIsGlobal()
			? orderType === ORDER_IN_Values.IN
			: true;
		console.log("orderType " + applyTax);
		if (
			applyTax ||
			product.tax_exempt === USE_TAX_VALUE ||
			hasForcedTax(product, modifiersSelected)
		) {
			priceWithTax = price + taxPrice;
		}
		dispatch({
			type: ADD_PRODUCT_TO_SHOPPING_CART,
			payload: {
				product,
				modifiersSelected,
				price,
				quantity,
				priceWithTax
			}
		});
	};
};

export const RemoveAllProductsFromShoopingCart = (): ShoopingCartActions => {
	return {
		type: REMOVE_ALL_PRODUCTS_FROM_SHOOPING_CART
	};
};

export const RemoveProductFromShoopingCart = (
	index: number
): ShoopingCartActions => {
	return {
		type: REMOVE_PRODUCT_FROM_SHOOPING_CART,
		payload: index
	};
};

export const AddCommentToShoopingCart = (text: string): ShoopingCartActions => {
	return {
		type: ADD_COMMENT_SHOP,
		payload: text
	};
};

export const AddTipToShoopingCart = (tip: number): ShoopingCartActions => {
	return {
		type: ADD_TIP_SHOP,
		payload: tip
	};
};
