import produce from "immer";
import {
	DETECTED_REAL_PERSON,
	PERSON_NOT_RECOGNIZED,
	NO_REAL_PERSON_DETECTED,
	SUCCESS_PAYMENT_WITH_POP_ID,
	FAILED_PAYMENT_WITH_POP_ID,
	PopIdPaymentActionTypes,
	PopIdSearchRealPersonActionTypes,
	RealPersonState,
	dataState,
	PaymentState,
	RecognizedPersonState,
	otherObjectDetectedState,
} from "./types";

const INITIAL_STATE: dataState = {
	person: {} as RealPersonState,
	recognizedPerson: {} as RecognizedPersonState,
	object: {} as otherObjectDetectedState,
	successPayment: {} as PaymentState,
	failedPayment: {},
	url: ""
};

export const popIdReducer = (
	state = INITIAL_STATE,
	action: PopIdSearchRealPersonActionTypes | PopIdPaymentActionTypes
): dataState =>
	produce(state, draft => {
		switch (action.type) {
			case DETECTED_REAL_PERSON:
				draft.person = action.payload;
				break;
			case PERSON_NOT_RECOGNIZED:
				draft.recognizedPerson = action.payload;
				break;
			case NO_REAL_PERSON_DETECTED:
				draft.object = action.payload;
				break;
			case SUCCESS_PAYMENT_WITH_POP_ID:
				draft.successPayment = action.payload;
				break;
			case FAILED_PAYMENT_WITH_POP_ID:
				draft.failedPayment = action.payload;
				break;
		}
	});
