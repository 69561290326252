import makeStyles from '@mui/styles/makeStyles';
import {ColorProps} from "../../interfaces/ColorsProps";

export const useStyles = makeStyles({
    background: (props: ColorProps) => ({
        width: "100%",
        minHeight: "100vh",
        backgroundColor: props.colors[1],
        paddingTop: 100,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    }),
    titleContainer: {
        marginTop: 50,
        padding: '20px',
    },
    title: (props: ColorProps) => ({
        fontFamily: "Avenir",
        whiteSpace: "pre-line",
        color: props.colors[0],
        fontSize: 24
    }),
    input: (props: ColorProps) => ({
        backgroundColor: props.colors[0],
        color: props.colors[1],
        borderRadius: 3,
        width: 300,
        height: 55,
        paddingLeft: 10,
        fontSize: 16,
        fontWeight: 'bold',
        

    }),
    btnContainer: {
        width: '80%'
    },
    outlinedBtn: (props: ColorProps) => ({
        color: props.colors[0],
        borderColor: props.colors[0],
        height: 50
    }),
    formContainer: {
        marginTop: 15
    },
    label: (props: ColorProps) => ({
        color: props.colors[0],
        textAlign: 'right',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: "24px"
    }),
    inputContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        alignItems: "flex-start"
    },
    labelContainer: {
        justifyContent: 'flex-end',
        display: 'flex'
    },
    formLigne: {
        marginTop: 5,
    },
    submitBtnContainer: {
        marginRight: 10,
        justifyContent: 'flex-start',
        display: 'flex',
    },
   /* submitBtn: (props: ColorProps) => ({
        backgroundColor: props.colors[0],
        color: props.colors[1],
        borderRadius: 3,
        fontWeight: 'bold',
        marginTop: 20,
        width: 300,
        height: 55,
        marginLeft: 10
    }),*/
    error: (props: ColorProps) => ({
        color: props.colors[0]
    }),
    content: {
        margin: "auto",

    },
    button: (props: ColorProps) => ({
        border: "1px solid",
        borderColor: props.colors[0],
        color: props.colors[0],
    }),
    container: (props: ColorProps) => ({
        minHeight: "calc(100vh - 55px)",
        padding: 30,
        backgroundColor: props.colors[1],
    }),
    submitBtn: (props: ColorProps) => ({
        marginTop: 10,
        background: props.colors[0],
        color: props.colors[1],
        width: 300,
        padding: "10px",
        "&.MuiButton-root.Mui-disabled": {
            color: props.colors[0],
            opacity: 0.2,
        },
        "&:hover": {
            background: props.colors[0],
            color: props.colors[1]
        },
        marginLeft: 10,
        height: '100%'
    }),
    root: (props: ColorProps) => ({
        color: props.colors[0]
    }),
    vipText:(props: ColorProps) => ({
        fontFamily: "Avenir",
        whiteSpace: "pre-line",
        color: props.colors[0],
        fontSize: 12,
        textAlign:'center',
        marginTop: '2%'
    })
});