import React from "react";
import { Grid } from "@mui/material";
import {
	MenuInterface,
	ProductInterface,
	SubCategoryInterface
} from "../../store/menu/types";
import { Product, SubCategory } from "..";
import { useStyles } from "./styles";

interface TabPanelComponentProps {
	menu: Array<MenuInterface>;
	value: number;
	colors: Array<string>;
}

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: number;
}
function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
        <div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Grid container justifyContent="space-around" alignItems="stretch">
					{children}
				</Grid>
			)}
		</div>
    );
}

export const TabPanelComponent = (props: TabPanelComponentProps) => {
	const { menu, value } = props;
	const classes = useStyles();

	return (
		<Grid className={classes.container}>
			{menu &&
				menu?.length > 0 &&
				menu.map((item: MenuInterface, index: number) => (
					<TabPanel
						key={"menu-panel-" + index + "-" + item.id}
						value={value}
						index={index}
					>
						{item?.products &&
							item?.products?.length > 0 &&
							item?.products.map(
								(
									product: ProductInterface,
									productIndex: number
								) => (
									<Product
										category={item}
										product={product}
										key={
											"product-" +
											productIndex +
											"-" +
											product.title +
											"-" +
											product.id
										}
									/>
								)
							)}
						{item.sub_categories &&
							item.sub_categories?.length > 0 &&
							item.sub_categories.map(
								(
									subCategory: SubCategoryInterface,
									subCategoryIndex: number
								) =>
									subCategory.products?.length > 0 && (
										<SubCategory
											category={subCategory}
											key={
												"categort-" +
												subCategoryIndex +
												"-" +
												subCategory.name +
												"-" +
												subCategory.id
											}
										/>
									)
							)}
					</TabPanel>
				))}
		</Grid>
	);
};
export default TabPanelComponent;
