import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "../rootReducer";
import { SHOW_SNACK_BAR, SnackBarActionTypes } from "../snackBar/types";
import { CardReaderErrorTypes, GenerateTokenAction, CardReaderTypes, GENERATE_TOKEN, GENERATE_TOKEN_ERROR, CardReaderLoqdingTypes, START_LOADING, DisconnectReaderTypes, DISCONNECT_READER } from "./types";
import { methodsServices } from "../services/methodsService";
import { ENDPOINTS } from "../../utils/endpoints";
import { getErrorMessage } from "../../utils/helpers";
import { Action } from "redux";


interface response {
    token: string | null,
    error: string,
}
// normal action type
interface generateTokenAction extends Action {
    payload: response

}

// normal action creator
function sendToken(response: response): generateTokenAction {
    return { type: 'SEND_NUMBER', payload: response };
}

/**
 * generate token
 * 
 * @returns 
 */
export const generateToken: ThunkAction<Promise<generateTokenAction>, any, any, generateTokenAction>
    = (dispatch: ThunkDispatch<
        RootState,
        undefined,
        CardReaderTypes | CardReaderErrorTypes | SnackBarActionTypes | CardReaderLoqdingTypes>)
        : Promise<generateTokenAction> => {
        return methodsServices
            .post(ENDPOINTS.GENERATE_TOKEN, null, true, true)
            .then((res: any) => {
                dispatch({
                    type: GENERATE_TOKEN,
                    payload: {
                        token: res.data?.token,
                        terminal: {},
                        reader: {},
                        error: '',
                        loading: false
                    },
                });

                return {
                    token: res.data?.token,
                    error: '',
                };
            }).catch((error: any) => {
                dispatch({
                    type: GENERATE_TOKEN_ERROR,
                    payload: { error: getErrorMessage(error) },
                });
                dispatch({
                    type: SHOW_SNACK_BAR,
                    payload: { message: getErrorMessage(error), severity: "error" },
                });
                return {
                    token: null,
                    error: getErrorMessage(error),
                };
            })
            .then(response => dispatch(sendToken(response)));
    }

/**
 * empty reducer
 * 
 * @returns 
 */
export const disconnectReader = () => {
    return (dispatch: ThunkDispatch<
        RootState,
        undefined,
        DisconnectReaderTypes>) => {
        dispatch({
            type: DISCONNECT_READER,
        });
    }
};

/**
 * 
 */
export const readerDisconnected = (msg: string) => {
    return (dispatch: ThunkDispatch<
        RootState,
        undefined,
        SnackBarActionTypes>) => {
        console.log("readerDisconnected", msg)
        return methodsServices
            .post(ENDPOINTS.SEND_LOG_MAIL, { message: msg }, true, true)
            .then((res: any) => {
                console.log('log has been sent: ', res)
            }).catch((error: any) => {
                console.log('error sending log: ', error)
            })
    }
}