import produce from "immer";
import { GENERATE_TOKEN, CardReaderTypes, CardReaderState, INIT_TERMINAL, CONNECT_READER, GENERATE_TOKEN_ERROR, CardReaderErrorTypes, CardReaderLoqdingTypes, START_LOADING, DISCONNECT_READER, DisconnectReaderTypes } from "./types";

const INITIAL_STATE: CardReaderState = {
    token: '',
    terminal: null,
    reader: null,
    error: '',
    loading: true
};

export const cardreaderReducer = (
    state = INITIAL_STATE,
    action: CardReaderTypes | CardReaderErrorTypes | CardReaderLoqdingTypes | DisconnectReaderTypes
): CardReaderState =>
    produce(state, (draft) => {
        switch (action.type) {
            case GENERATE_TOKEN:
                draft.token = action.payload.token;
                draft.loading = false;
                draft.error = '';
                break;
            case INIT_TERMINAL:
                draft.terminal = action.payload.terminal;
                draft.error = '';
                break;
            case CONNECT_READER:
                draft.reader = action.payload.reader;
                draft.error = '';
                break;
            case GENERATE_TOKEN_ERROR:
                draft.error = action.payload.error;
                draft.loading = false;
                break;
            case START_LOADING:
                draft.loading = true;
                draft.error = '';
                break;
            case DISCONNECT_READER:
                draft.error = '';
                draft.terminal = null;
                draft.reader = null;
                break;
        }
    });
