export const order = {
	title: "Your order",
	description: "Once you add items to your cart, they will show here",
	button: "Finish & pay",
	add: "Add to order",
	quantity: "Quantity",
	additional_instruction: "Additional instructions",
	sub_total: "SubTotal",
	tax: "Tax",
	tip: "Tip",
	select_tip: "Select Your Tip"
};
