/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Grid, IconButton } from "@mui/material";
import { useStyles } from "../Cart/styles";
import { useStyles as shoopingItemUseStyles } from "./styles";
import { config } from "../../config/config";
import placeHolder from "../../assets/img/placeholder.png";
import CancelIcon from "@mui/icons-material/Cancel";
import { ShoopingProductModel } from "../../store/shoopingCart/models";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";
import { getPriceFormat } from "../../utils/helpers";

interface ShoopingCartItemProps {
	product: ShoopingProductModel;
	index: number;
	setOpenDeleteDialog: (index: number, x: boolean) => void;
}
export const ShoopingCartItem: React.FC<ShoopingCartItemProps> = ({
	product,
	index,
	setOpenDeleteDialog
}) => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const shoopingClasses = shoopingItemUseStyles({ colors });
	const [imageError, setImageError] = useState(false);
	const [variation, setVariation] = React.useState<boolean>(false);
	const modifiersVariation : number[]= [];
	React.useEffect(() => {
		const variationProduct = product.product.groups.filter((grp: any) => grp.variation === 1);
		variationProduct.length !== 0 && setVariation(true);
	}, []);

	const getModifierSelected =(product: ShoopingProductModel, modifier: any) =>{
										product.modifiersSelected.includes(modifier.id) &&
										modifiersVariation?.push(modifier.pivot.price / 100)
}
	const handleRemoveProduct = () => {
		setOpenDeleteDialog(index, true);
	};
	return (
		<div
			key={product.product.id + Math.random().toString()}
			className={shoopingClasses.conatiner}
		>
			<div className={shoopingClasses.cancelContainerStyle}>
				<IconButton
					size={"small"}
					onClick={() => handleRemoveProduct()}
				>
					<CancelIcon className={shoopingClasses.cancelStyle} />
				</IconButton>
			</div>
			<div className={shoopingClasses.imageContainerStyle}>
				<img
					alt={product.product.title}
					className={shoopingClasses.imageStyle}
					src={
						product.product.image &&
						!product.product.image.startsWith("https") &&
						!imageError
							? config.BASE_URL_API + product.product.image
							: product.product.image &&
							  product.product.image.startsWith("https")
							? product.product.image
							: placeHolder
					}
					onError={() => setImageError(true)}
				/>
				<Grid container>
					<Grid item xs={6}>
						<Typography
							className={`${classes.productTitle} ${shoopingClasses.leftTextStyle}`}
						>
							{product.product.title}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							className={`${shoopingClasses.rightTextStyle} ${classes.productTitle}`}
						>
							$
							{variation
								? 
								  product.product.groups
										.filter(grp => grp.variation == 1)
										.map(group => {
											return group.modifiers.map(
												(modifier, index) =>
													product.modifiersSelected.includes(
														modifier.id
													) && (
															<
															>
																{getPriceFormat(
																	modifier
																		.pivot
																		.price /
																		100
																)}
															</>
													)
											);
										})
								: product.product.price
								? getPriceFormat(product.product.price / 100)
								: "00.00"}
						</Typography>
					</Grid>
					{product.product.groups
						.filter(grp => grp.variation == 1)
						.map(group => {
							return group.modifiers.map(
								(modifier, index) =>
									product.modifiersSelected.includes(
										modifier.id
									) && (
										<Grid
											container
											key={
												modifier.id +
												index +
												Math.random().toString()
											}
										>
											<Grid item xs={6}>
												<Typography
													className={
														shoopingClasses.modifiersStyle
													}
												>
													{modifier.name}
												</Typography>
											</Grid>
										</Grid>
									)
							);
						})}
					{product.product.groups
						.filter(grp => grp.variation == 0)
						.map(group => {
							return group.modifiers.map(
								(modifier, index) =>
									product.modifiersSelected.includes(
										modifier.id
									) && (
										<Grid
											container
											key={
												modifier.id +
												index +
												Math.random().toString()
											}
										>
											<Grid item xs={6}>
												<Typography
													className={
														shoopingClasses.modifiersStyle
													}
												>
													+ {modifier.name}
												</Typography>
											</Grid>
											<Grid item xs={6}>
												<Typography
													className={
														shoopingClasses.modifiersPriceStyle
													}
												>
													$
													{getPriceFormat(
														modifier.pivot.price /
															100
													)}
												</Typography>
											</Grid>
										</Grid>
									)
							);
						})}
					<Grid item xs={6}>
						<Typography
							className={`${classes.productTitle} ${shoopingClasses.leftTextStyle}`}
						>
							{t("order.quantity")}
						</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography
							className={`${shoopingClasses.rightTextStyle} ${classes.productTitle}`}
						>
							{product.quantity}
						</Typography>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};
export default ShoopingCartItem;
