import { combineReducers } from "redux";
import { authReducer } from "./authentification/reducer";
import { menuReducer } from "./menu/reducer";
import { snackBarReducer } from "./snackBar/reducer";
import { kioskChoiceReducer } from "./kioskChoice/reducer";
import { cardreaderReducer } from "./cardReader/reducer";
import { confirmReducer } from "./confirm/reducer";
import { orderReducer } from "./order/reducer";
import { checkoutReducer } from "./checkout/reducer";
import { shoopingCartReducer } from "./shoopingCart/reducer";
import { deepSightReducer } from "./deepSight/reducer";
import { orderSquareReducer } from "./squareOrder/reducer";
import { confirmSquareReducer } from "./confirmSquare/reducer";
import { popIdReducer } from "./popID/reducer";
export const rootReducer = combineReducers({
	auth: authReducer,
	menu: menuReducer,
	snackbar: snackBarReducer,
	kioskChoice: kioskChoiceReducer,
	cardreader: cardreaderReducer,
	checkout: checkoutReducer,
	confirm: confirmReducer,
	order: orderReducer,
	shoopingCart: shoopingCartReducer,
	deepSight: deepSightReducer,
	square: orderSquareReducer,
	confirmSquare: confirmSquareReducer,
	popId: popIdReducer
});

export type RootState = ReturnType<typeof rootReducer>;
